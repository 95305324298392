import React, { Fragment, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import 'orca/components/AlertsTab/AlertsTab.css';
import { DefaultText } from 'orca/components/Miscellaneous/DefaultText';
import { CircularProgressWithLabel } from 'mdp/components/Miscellaneous/CircularProgressWithLabel';


export const DiagnosticsResults = (props) => {
  const {
    columns,
    tableData,
    error,
    isLoading,
  } = props;

  const theme = useTheme();
  const styles = {
    tableHeader: {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      fontWeight: 'bold',
    },
    spinnerAndText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '89%',
    },
    container: {
      height: '94%',
      scrollbarWidth: 'thin',
      backgroundColor: 'white',
    },
    paginationContainer: {
      height: "6%",
      display: "flex",
      alignItems: 'center',
      justifyContent: 'right',
      textAlign: "right",
      padding: "5px 15px 10px 0"
    },
    tableRow: {
      cursor: 'pointer',
      backgroundColor: "white",
      '&:hover': {
        backgroundColor: 'rgba(90, 129, 171, 0.14)',
      },
    },
    disabled: {
      color: 'rgba(205, 205, 205, 0.8)',
      cursor: 'notAllowed',
      pointerEvents: 'none',
    },
    selected: {
      backgroundColor: 'rgba(90, 129, 171, 0.24)',
    },
    tableSubRow: {
      padding: '3px 24px 3px 50px',
    },
    headerCell: {
      padding: '6px 8px 6px 8px',
      textAlign: 'center',
      fontWeight: 'bold',
      borderBottom: '2px solid lightgrey',
    },
    tableCell: {
      fontSize: 11,
      padding: '6px 8px 6px 6px',
      textAlign: 'center',
    },
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (row, rowIndex) => {
    setSelectedRow(rowIndex);
  }

  const handleDoubleClick = (row) => {
    // Currently, no plans are being made to display an expanded popup of solution. Uncomment if otherwise.
    // setSelectedRow(null); // for visual
    // setSolutionSelectedRow(row); // will activate expanded popup
    // setIsSolutionPanelExpand(true);
  }

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {error && (
        <div style={styles.spinnerAndText}>
          <DefaultText text={error.toString()} />
        </div>
      )}

      {/* default message to show if the user hasnt made a query */}
      {!error && (!tableData || tableData.data === null || (tableData?.searchKeyword && tableData?.searchKeyword === '[]')) && !isLoading && (
        <div style={styles.spinnerAndText}>
          <DefaultText text="Select an option to retrieve solutions." />
        </div>
      )}
      {/* if the query is complete or query in progress show the table */}
      {!error && (tableData !== null || isLoading) && (
        <Fragment>
          <TableContainer sx={styles.container}>
            {/* if query in progress show loading spinner */}
            {isLoading && (
              <div style={styles.spinnerAndText}>
                <CircularProgress color="primary" />
              </div>
            )}
            {/* if the query completed but the table is empty */}
            {!isLoading && Array.isArray(tableData?.data) && tableData.data.length === 0 && (
              <div style={styles.spinnerAndText}>
                <DefaultText text="Can not find solution." />
              </div>
            )}
            {/* if query completed and table is not empty */}
            {!isLoading && Array.isArray(tableData?.data) && tableData.data.length > 0 && (
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead sx={styles.headerCell} key={'tableHeadSol'}>
                  <TableRow key={'headerRowSol'}>
                    {columns.map((column) => (
                      <TableCell
                        sx={styles.headerCell}
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody key={'tableBodySol'}>
                  {tableData.data.map((row, rowIndex) => {
                      return (
                      <Fragment key={row.caseID}>
                          <TableRow
                          key={`row_${rowIndex}`}
                          onClick={() => handleRowClick(row, rowIndex)}
                          onDoubleClick={() => handleDoubleClick(row)}
                          sx={selectedRow === rowIndex ? styles.selected : styles.tableRow}
                          >
                            <TableCell
                              sx={{
                                border: 'none',
                                borderBottom: '1px solid lightgrey',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                              key={`solution_id_${rowIndex}`}
                            >
                              {`#${row.externalID}`}
                            </TableCell>
                            <TableCell
                                sx={{
                                  border: 'none',
                                  padding: '8px',
                                  borderBottom: '1px solid lightgrey',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}
                                key={`solution_${rowIndex}`}
                                >
                                {row.caseName}
                                <CircularProgressWithLabel value={row.relevance} />
                            </TableCell>
                          </TableRow>


                      </Fragment>
                      );
                  })}
                </TableBody>

              </Table>)}
          </TableContainer>

        </Fragment>)
      }
    </Fragment>
  );
};
