/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from 'react';
import { FleetTab } from 'mdp/components/FleetTab/FleetTab';
import { MessagesTab } from 'mdp/components/MessagesTab/MessagesTab';
import { RawDataTab } from 'mdp/components/RawDataTab/RawDataTab';
import { EqIDLookupTab } from 'mdp/components/EqIDLookupTab/EqIDLookupTab';
import { TabNav } from 'mdp/components/TabNav';
import { FlightDeckEffectsTab } from 'mdp/components/FlightDeckEffectsTab/FlightDeckEffectsTab';
import { DiagnosticsTab } from 'mdp/components/DiagnosticsTab/DiagnosticsTab';
import { DiagnosticsFiltersContext } from 'mdp/contexts/DiagnosticsFiltersContext';
import { ErrorBoundary } from 'dna/dnaErrorCaughtPage';
import { ContextConsumer } from 'dna/ContextConsumer';
import { apiFetcher } from 'utils/apiUtils';
import { config } from 'config/config';
import { CircularProgress } from '@mui/material';
import 'mdp/TabNavigator.css';
import 'mdp/ThreePanelTab.css';
import {
  Redirect,
  Route,
  useRouteMatch,
  Switch,
} from 'react-router-dom/cjs/react-router-dom.min';
/* eslint-enable max-len */

export const TabNavigator = () => {
  const { path } = useRouteMatch();
  // const {path} = useRouteMatch();
  // stores the current tab the user is on
  const [curTab, setCurTab] = useState('Fleet');
  const [specialistEditMode, setSpecialistEditMode] = useState(false);
  const [isAccessLoading, setIsAccessLoading] = useState(false);

  const { viewTS, setViewTS, accessTS, setAccessTS } = useContext(DiagnosticsFiltersContext);

  // only run once in initial render. Used to determine if operator has crj200 acmodel, and is subscribed to crj200
  useEffect(() => {
    const getViewTS = async () => {
      setIsAccessLoading(true);
      await apiFetcher(`${config.apiURL}/mdp/hasCRJFleet`, 'GET').then(
        async (response) => {
          if (response.data === true) {
            setViewTS(true);
          } else {
            setViewTS(false);
          }
          setIsAccessLoading(false);
        },
      );
    }

    if (viewTS == null) {
      getViewTS();
    }
  }, []);


  useEffect(() => {
    const getAccessTS = async () => {
      setIsAccessLoading(true);
      await apiFetcher(`${config.apiURL}/mdp/checkAccess`, 'GET').then(
          (response) => {
            const componentData = response.data.map((item) =>
              item.COMPONENT.toLowerCase(),
            );
            if (componentData.includes('crj_ts')) {
              setAccessTS(true);
            } else {
              setAccessTS(false);
            }
            setIsAccessLoading(false);
          },
      );
    };

    if (viewTS === true && accessTS == null) {
      getAccessTS();
    }
  }, [viewTS, accessTS]);

  if (isAccessLoading) {
    return <div style={{ width: '100%', height: '100vh' }}>
      <CircularProgress style={{ marginTop: '50vh', marginLeft: '50vw' }}/>
    </div>;
  }

  return (
    <ContextConsumer>
      {componentStates => (
        <ErrorBoundary returnPath = {"/mdp/Fleet"} returnName = {"MDP"} componentStates={componentStates}>
          <div className="main">
            <div className="tabNav">
              <TabNav
                curTab={curTab}
                setCurTab={setCurTab}
                specialistEditMode={specialistEditMode}
              />
            </div>
            <div className="tabContent">
              {/* Routes */}
              <Switch>
                <Route path={`${path}/Fleet`} component={FleetTab} />
                <Route path={`${path}/Messages`}>
                  <MessagesTab
                    specialistEditMode={specialistEditMode}
                    setSpecialistEditMode={setSpecialistEditMode}
                  />
                </Route>
                <Route path={`${path}/Rawdata`} component={RawDataTab} />
                <Route path={`${path}/EqIDLookup`}>
                  <EqIDLookupTab
                    specialistEditMode={specialistEditMode}
                    setSpecialistEditMode={setSpecialistEditMode}
                  />
                  ;
                </Route>
                <Route
                  path={`${path}/FlightDeckEffects`}
                  component={FlightDeckEffectsTab}
                />
                {viewTS && (
                  <Route path={`${path}/Diagnostics`} component={DiagnosticsTab}/>
                )}
                {/* Redirect to fleet for an unknown tab */}
                <Route path="*"><Redirect to={`${path}/Fleet`} /></Route>
              </Switch>
            </div>
          </div>
        </ErrorBoundary>
      )}
    </ContextConsumer>
  );
};
