import React, { useContext } from "react";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { TimelineFiltersContext } from 'orca/contexts/TimelineFiltersContext';
import { SpltFiltersContext } from 'orca/contexts/SpltFiltersContext';
import { AlertsFiltersContext } from 'orca/contexts/AlertsFiltersContext';
import { ReviewFiltersContext } from "orca/contexts/ReviewFiltersContext";

const styles = ({
  formControl: {
    margin: "5px 0 0 0",
    minWidth: "100%",
    maxWidth: "100%",
  },
  listText: {
    fontSize: 12,
  },
  menu: {
    width: "250px",
    height: "35px",
  },
  selectBox: {
    fontSize: 12,
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "400px",
      minWidth: "200px",
      maxWidth: "250px",
    },
  },
};

export const CheckBoxSelect = (props) => {
  const {
    filterState,
    label,
    multiple,
    filterName,
    shouldValidate,
    options,
  } = props;

  // change all tab's acmodel simultaneously
  const {
    timelineFilterState,
    setTimelineFilterState,
  } = useContext(TimelineFiltersContext);

  const {
    spltFilterState,
    setSpltFilterState,
  } = useContext(SpltFiltersContext);

  const {
    alertsFilterState,
    setAlertsFilterState,
  } = useContext(AlertsFiltersContext);

  const {
    reviewFilterState,
    setReviewFilterState,
  } = useContext(ReviewFiltersContext);

  const handleChange = (event) => {
    setTimelineFilterState({
      ...timelineFilterState,
      [filterName]: event.target.value,
    });
    setSpltFilterState({
      ...spltFilterState,
      [filterName]: event.target.value,
    });
    setAlertsFilterState({
      ...alertsFilterState,
      [filterName]: event.target.value,
    });
    setReviewFilterState({
      ...reviewFilterState,
      [filterName]: event.target.value,
    });
  };

  return (
    <FormControl variant="standard" sx={styles.formControl}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        variant="standard"
        error={!filterState[filterName] && shouldValidate}
        sx={styles.selectBox}
        labelId={label}
        value={filterState[filterName]}
        onChange={(event) => {
          handleChange(event);
        }}
        input={<Input />}
        MenuProps={MenuProps}>
        {options.map((item) => (
          <MenuItem sx={styles.menu} key={item} value={item}>
            {multiple && (
              <Checkbox
                color="primary"
                checked={filterState[filterName].indexOf(item) > -1}
              />
            )}
            <ListItemText
              primaryTypographyProps={{ sx: styles.listText }}
              primary={item}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
