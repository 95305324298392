import React, { Fragment, useLayoutEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Tooltip,
  Checkbox,
  Button,
  Snackbar,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import TablePagination from "@mui/material/TablePagination";
import { LoadingIcon } from "orca/components/Miscellaneous/LoadingIcon";
import { TimelineGraph } from "orca/components/TimelineTab/TimelineGraph";
import { DefaultText } from "orca/components/Miscellaneous/DefaultText";
import { PdfExportButton } from "orca/components/Miscellaneous/PdfExportButton";
import html3pdf from 'html3pdf';
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";
import { formatFiltersState } from "orca/components/TimelineTab/TimelineFiltersPanel";
import { EntryCloseButton } from "orca/components/Miscellaneous/EntryCloseButton";

export const TimelineTable = (props) => {
  const {
    rowKey,
    columns,
    tableData,
    setTimelineTableState,
    isLoading,
    setIsLoading,
    error,
    setError,
    timelineFilterState,
    isPrevState,
    startDate,
    endDate,
    rowsPerPage,
    setRowsPerPage,
    curTablePage,
    setCurTablePage,
    selectedRows,
    setSelectedRows,
    selectedRowIndex,
    setSelectedRowIndex,
    sortDirection,
    setSortDirection,
    sortCol,
    setSortCol,
    onClickRow,
  } = props;

  const [displayedTableData, setDisplayedTableData] = useState([]);

  useLayoutEffect(() => {
    // checks if current table page is out of range
    const curTotalPage = Math.ceil(tableData.data?.length / rowsPerPage);
    if (curTablePage < curTotalPage) {
      setDisplayedTableData(
        tableData.data.slice(
          curTablePage * rowsPerPage,
          curTablePage * rowsPerPage + rowsPerPage
        )
      );
    } else {
      // set the page to the first page whenever it is out of range
      setCurTablePage(0);
      setDisplayedTableData(tableData.data.slice(0, rowsPerPage));
    }
  }, [tableData.data, rowsPerPage, curTablePage, setCurTablePage]);

  const theme = useTheme();
  const styles = {
    tableHeader: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      fontWeight: "bold",
    },
    spinnerAndText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "76%",
    },
    container: {
      height: "87%",
      scrollbarWidth: "thin",
      backgroundColor: isPrevState ? "rgba(202, 204, 206, 0.38)" : "white",
    },
    tableRow: {
      backgroundColor: "white",
      '&:hover': {
        backgroundColor: 'rgba(90, 129, 171, 0.14)',
      },
    },
    selected: {
      backgroundColor: 'rgba(90, 129, 171, 0.24)',
    },
    headerCell: {
      padding: "6px 8px 6px 8px",
      textAlign: "center",
      fontWeight: "bold",
    },
    tableCell: {
      fontSize: 11,
      padding: "6px 8px 6px 6px",
      textAlign: "center",
    },
    pagination: {
      backgroundColor: "white",
    },
    paginationContainer: {
      height: "13%",
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
    },
    buttonContainer: {
      marginLeft: "15px",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    tableIconButton: {
      padding: "3 10 3 10",
      margin: "0 0 0 0",
      backgroundColor: "rgba(202, 204, 206, 0.38)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgba(202, 204, 206)",
      },
    },
    buttonIcons: {
      width: "20px",
      height: "20px",
      marginRight: "8px",
      fill: theme.palette.primary.dark,
    },
    snackBar: {
      vertical: "bottom",
      horizontal: "center",
    },
  };

  const intensityColorMap = {
    1: "green",
    2: "yellow",
    3: "red",
    // etc
  };

  const intensityDefinitionMap = {
    1: `1 occurence in 15 days`,
    2: `2 occurences in 15 days`,
    3: `3 or more occurences in 15 days`,
  };

  const exportToPDF = async () => {
    const table = document.getElementById('table-container');
    const page = {
        margin: 0.5,
        filename: 'OrcaTimelineExport.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        pagebreak: { avoid: 'tr' },
        // pagebreak: { avoid: 'tr', elementType: 'thead', className: table.children[0].className }, Currently cannot display element after pagebreak. Fix.
        jsPDF: { unit: 'in', format: [20, 8.5], orientation: 'landscape' } // Note: this format is foreign, image size will change when printing it out.
    };
    html3pdf().from(table).set(page).save();
  };

  const sortTableData = (col) => {
    const sortOrder = sortDirection === "asc" ? "desc" : "asc";
    const sortedData = tableData.data.sort((a, b) => {
      if (col === "timeline") {
        col = "max_reported_date";
      }

      const isString = (typeof a[col] === 'string' && typeof b[col] === 'string');

      return sortOrder === "asc"
      ? (isString ? a[col].localeCompare(b[col]) : a[col] - b[col])
      : (isString ? b[col].localeCompare(a[col]) : b[col] - a[col]);
    });
    setDisplayedTableData(sortedData.slice(0, rowsPerPage));
    setSortDirection(sortOrder);
    setCurTablePage(0);
    setSelectedRowIndex(null);
  };

  const handleChangePage = (event, newPage) => {
    setDisplayedTableData(
      tableData.data.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      )
    );
    setCurTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurTablePage(0);
  };

  const handleCheckBoxClick = (row) => {
    // if selected row is already checked then uncheck it
    if (selectedRows.map((item) => item[rowKey]).includes(row[rowKey])) {
      setSelectedRows(
        selectedRows.filter((item) => item[rowKey] !== row[rowKey])
      );
    } else {
      // if selected row is already unchecked then check it
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAllRows = (event) => {
    // if "select all" is unchecked then check all rows
    if (event.target.checked) {
      setSelectedRows(tableData.data);
    } else {
      // if "select all" is checked then uncheck all rows
      setSelectedRows([]);
    }
  };

  const reFetchTableData = () => {
    setIsLoading(true);
    setError(null);

    const payload = formatFiltersState(timelineFilterState);
    const applyCallback = () => {
      setCurTablePage(curTablePage)
      setSelectedRows([]);
      setSelectedRowIndex(null);
      setSortDirection("asc");
      setSortCol(null);
    };

    apiFetcher(
      `${config.apiURL}/orca/orca`,
      "POST",
      payload,
      { "Content-Type": "application/json" }
    )
      .then((response) => {
        applyCallback();
        setTimelineTableState(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  }

  const formatWatchlistItems = () => {
    return selectedRows.map(item => {
      return {
        operator_code: item.operator_code,
        ac_sn: item.ac_sn,
        ac_tn: item.ac_tn,
        ata: item.ata
      }
    })
  }

  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [messageInfo, setMessageInfo] = useState({
    message: "",
    severity: null,
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertPopup(false);
  };

  const handleAddWatchlist = (event) => {
    if (tableData.data.length === 0) {
      setMessageInfo({
        message: "A valid search must be performed first",
        severity: "error",
      });
      setShowAlertPopup(true);
    } else if (selectedRows.length === 0) {
      setMessageInfo({
        message: "No data is selected",
        severity: "error",
      });
      setShowAlertPopup(true);
    } else {
      apiFetcher(
        `${config.apiURL}/orca/orca/watchlist/add`,
        "POST",
        { items: formatWatchlistItems() },
        { "Content-Type": "application/json" }
      )
        .then((response) => {
          setMessageInfo({
            message: "Watchlist is updated successfully",
            severity: "info",
          })
          setShowAlertPopup(true);
          // re-render the updated data
          reFetchTableData();
        })
        .catch((e) => {
          setMessageInfo({
            message: "Error",
            severity: "error",
          })
          setShowAlertPopup(true);
        });
    }
  }

  const handleRemoveWatchlist = (event) => {
    if (tableData.data.length === 0) {
      setMessageInfo({
        message: "A valid search must be performed first",
        severity: "error",
      });
      setShowAlertPopup(true);
    } else if (selectedRows.length === 0) {
      setMessageInfo({
        message: "No data is selected",
        severity: "error",
      })
      setShowAlertPopup(true);
    } else {
      apiFetcher(
        `${config.apiURL}/orca/orca/watchlist/remove`,
        "POST",
        { items: formatWatchlistItems() },
        { "Content-Type": "application/json" }
      )
        .then((response) => {
          setMessageInfo({
            message: "Watchlist is updated successfully",
            severity: "info",
          })
          setShowAlertPopup(true);
          // re-render the updated data
          reFetchTableData();
        })
        .catch((e) => {
          setMessageInfo({
            message: "Error",
            severity: "error",
          })
          setShowAlertPopup(true);
        });
    }
  }

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {error && (
        <div style={styles.spinnerAndText}>
          <DefaultText text={error.toString()} />
        </div>
      )}

      {/* default message to show if the user hasnt made a query */}
      {!error && !tableData && !isLoading && (
        <div style={styles.spinnerAndText}>
          <DefaultText text="Apply some filters" />
        </div>
      )}

      {/* if the query is complete or query in progress show the table */}
      {!error && (tableData || isLoading) && (
        <Fragment>
          <TableContainer sx={styles.container}>
            {/* if query in progress show loading spinner */}
            {isLoading && (
              <div style={styles.spinnerAndText}>
                <LoadingIcon maxWidth={`100px`}></LoadingIcon>
              </div>
            )}
            {/* if the query completed but the table is empty */}
            {!isLoading && (displayedTableData[0] === null) && (
              <div style={styles.spinnerAndText}>
                <DefaultText text="Data Not Available" />
              </div>
            )}
            {/* if query completed and table is not empty */}
            {!isLoading && (displayedTableData[0] !== null) && (
              <Table stickyHeader
                aria-label="sticky table"
                size="small"
                id='table-container'
                style={{ width: '100%', maxWidth: '100%' }}
                >
                <TableHead sx={styles.headerCell}>
                  <TableRow>
                  {displayedTableData?.length !== 0 && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < displayedTableData?.length
                        }
                        checked={
                          tableData.data?.length > 0 &&
                          selectedRows.length === displayedTableData?.length
                        }
                        onChange={handleSelectAllRows}
                      />
                    </TableCell>
                  )}
                    {columns.map((column) => (
                      <TableCell
                        sx={styles.headerCell}
                        key={`column_${column.key}`}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.sortable ? (
                          <TableSortLabel
                            active={sortCol === column.key}
                            direction={sortDirection}
                            onClick={() => {
                              sortTableData(column.key);
                              setSortCol(column.key);
                            }}
                          >
                            {column.label}
                          </TableSortLabel>
                        ) : (
                          <div>{column.label}</div>
                        )}

                        {column.key === "timeline" && (
                          <TimelineGraph
                            startDate={startDate}
                            endDate={endDate}
                            dates={[]}
                            displayXAxis={true}
                            canvasHeight={"50px"}
                          />
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {displayedTableData?.map((row, rowIndex) => {
                    return (
                      <TableRow
                        sx={selectedRowIndex === row[rowKey]
                          ? styles.selected : styles.tableRow}
                        key={`row_${rowIndex}`}
                        tabIndex={-1}
                        onClick={() => {
                          onClickRow(row, row[rowKey]);
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedRows
                              .map((item) => item[rowKey])
                              .includes(row[rowKey])}
                            onChange={() => {
                              handleCheckBoxClick(row);
                            }}
                          />
                        </TableCell>
                        {columns.map((column, colIndex) => {
                          if (column.key === "timeline") {
                            const reportedDates = row.chronics.map(
                              (chronic) => chronic.reported_date
                            );
                            return (
                              <TableCell
                                key={`row_${rowIndex}_col_${colIndex}`}
                                align={column.align}
                                sx={styles.tableCell}
                              >
                                <TimelineGraph
                                  startDate={startDate}
                                  endDate={endDate}
                                  dates={reportedDates}
                                  displayXAxis={false}
                                  canvasHeight={"20px"}
                                  watchValue = {row.watch_list}
                                  splitValue = {
                                    row.chronics.length > 0 && row.chronics.every(
                                      chronic => chronic.split_sub_group === "Y"
                                    )
                                  }
                                ></TimelineGraph>
                              </TableCell>
                            );
                          }

                          const value = row[column.key];

                          return (
                            <Tooltip
                              key={`tooltip_${row[rowKey]}_${colIndex}`}
                              title={
                                column.key === "intensity"
                                  ? intensityDefinitionMap[value]
                                  : ""
                              }
                            >
                              <TableCell
                                align={column.align}
                                sx={styles.tableCell}
                                style={{
                                  backgroundColor:
                                    column.key === "intensity"
                                      ? intensityColorMap[value]
                                      : "",
                                }}
                                key={`row_${rowIndex}_col_${colIndex}`}
                              >
                                {column.key !== "intensity"
                                  ? value : row.watch_list === "Y" ? "WATCH" : ""}
                              </TableCell>
                            </Tooltip>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <div style={styles.paginationContainer}>
            <div style={styles.buttonContainer}>
              <PdfExportButton
                exportToPDF={exportToPDF}
                hasTableData={displayedTableData[0] !== null && tableData.data?.length !== 0} />

              <Button
                sx={styles.tableIconButton}
                variant="contained"
                disableElevation
                size="small"
                onClick={handleAddWatchlist}>
                Add Watchlist
              </Button>

              <Button
                sx={styles.tableIconButton}
                variant="contained"
                disableElevation
                size="small"
                onClick={handleRemoveWatchlist}>
                Remove Watchlist
              </Button>

              <EntryCloseButton
                hasTableData={displayedTableData.length !== 0}
                selectedRows={selectedRows}
                setShowAlertPopup={setShowAlertPopup}
                setMessageInfo={setMessageInfo}
                reFetchTableData={reFetchTableData}
              />

            </div>

            <TablePagination
              sx={styles.pagination}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={tableData.data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={curTablePage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          <Snackbar
            anchorOrigin={styles.snackBar}
            open={showAlertPopup}
            autoHideDuration={3000}
            onClose={(event, reason) => handleClose(event, reason)}
          >
            <MuiAlert
              onClose={(event, reason) => handleClose(event, reason)}
              severity={messageInfo.severity}
              elevation={6}
              variant="filled"
            >
              {messageInfo.message}
            </MuiAlert>
          </Snackbar>
        </Fragment>
      )}
    </Fragment>
  );
};
