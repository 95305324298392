import React, { Fragment, useContext } from 'react';
import { SpltFiltersContext } from 'orca/contexts/SpltFiltersContext';
import { SpltDataContext } from 'orca/contexts/SpltDataContext';
import { SpltTable } from 'orca/components/SpltTab/SpltTable';


const columns = [
  {
    key: 'message_type',
    label: 'Report Type',
    minWidth: 60,
    sortable: true,
  },
  {
    key: 'ac_sn',
    label: 'Serial',
    minWidth: 60,
    sortable: true,
  },
  {
    key: 'ac_tn',
    label: 'Tail',
    minWidth: 60,
    sortable: true,
  },
  {
    key: 'ata',
    label: 'ATA',
    minWidth: 60,
    sortable: true,
  },
  {
    key: 'reported_date',
    label: 'Reported Date',
    minWidth: 100,
    sortable: true,
  },
  {
    key: 'resolved_date',
    label: 'Resolved Date',
    minWidth: 100,
    sortable: true,
  },
  {
    key: 'discrepancy',
    label: 'Discrepancy',
    minWidth: 150,
    sortable: false,
  },
  {
    key: 'corrective_action',
    label: 'Corrective Action',
    minWidth: 150,
    sortable: false,
  },
  {
    key: 'repetitive_snags',
    label: 'Repetitive Snags',
    minWidth: 60,
    sortable: true,
  },
  {
    key: 'mdc_linkage',
    label: 'MDC Linkage',
    minWidth: 100,
    sortable: false,
  },
  {
    key: 'watch_list',
    label: 'Watchlist Items',
    minWidth: 60,
    sortable: false,
  },
];

export const SpltTablePanel = (props) => {
  const {
    spltTableState,
    isLoading,
    setIsLoading,
    error,
    setError,
  } = useContext(SpltDataContext);

  const {
    rowsPerPage,
    setRowsPerPage,
    curTablePage,
    setCurTablePage,
    selectedRows,
    setSelectedRows,
    selectedRowIndex,
    setSelectedRowIndex,
    sortDirection,
    setSortDirection,
    sortCol,
    setSortCol,
  } = useContext(SpltFiltersContext);

  const chronicsUnestedData = spltTableState?.data ?? [];


  return (
    <Fragment>
      <SpltTable
        rowKey="id"
        columns={columns}
        tableData={{
          ...spltTableState,
          data: chronicsUnestedData,
        }}
        error={error}
        setError={setError}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        selectedRowIndex={selectedRowIndex}
        setSelectedRowIndex={setSelectedRowIndex}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        sortCol={sortCol}
        setSortCol={setSortCol}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        curTablePage={curTablePage}
        setCurTablePage={setCurTablePage}
      >
      </SpltTable>
    </Fragment>
  );
};
