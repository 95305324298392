import React, { Fragment, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Box,
  TextField,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { DefaultText } from "orca/components/Miscellaneous/DefaultText";
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";
import MinimizeRoundedIcon from '@mui/icons-material/MinimizeRounded';


export const NotesPanel = (props) => {
  const {
    // selectedChronicDefectID,
    selectedAtaAcsn,
    setIsNotesOpen,
    enableNotes,
  } = props;

  const theme = useTheme();
  const styles = ({
      notesPanel: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderLeft: "2px solid lightgrey",
      },
      tableTitleBar: {
        color: theme.palette.text.title2,
        fontSize: 18,
        borderBottom: "1px solid " + theme.palette.default.main,
        backgroundColor: theme.palette.secondary.main,
        height: "30px",
        padding: "0 0 0 0",
        display: 'flex',
        alignItems: 'center',
      },
      tableTitleBarWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      },
      tableTitleBarTitle: {
      color: theme.palette.text.title2,
      paddingTop: "1px",
      textAlign: "center",
      width: "100%",
      flex: 1,
      },
      spinnerAndText: {
        marginTop: "30%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      notesTextBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "0 0 0 0"
      },
      notesText: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        margin: 0,
        padding: "0px 0",
      },
      inputTextPanel: {
          display: "flex"
      },
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isNotesLoading, setIsNotesLoading] = useState(null);
  const [notesData, setNotesData] = useState(null);
  const [notesError, setNotesError] = useState(null);
  const [name, setName] = useState("");
  const [userInputText, setUserInputText] = useState("");

  const getNotesData = () => {
    setIsNotesLoading(true);
    setNotesError(null);
    apiFetcher(`${config.apiURL}/orca/orca/${selectedAtaAcsn.ata}/${selectedAtaAcsn.ac_sn}/notes`, "GET")
      .then((response) => {
        setNotesData(response.data);
        setIsNotesLoading(false);
      })
      .catch((e) => {
        setIsNotesLoading(false);
        setNotesError(e);
      });
  };

  useEffect(() => {
    if (selectedAtaAcsn && selectedAtaAcsn.ata && selectedAtaAcsn.ac_sn) {
      getNotesData();
    } else {
      setNotesData([]);
    }
  }, [selectedAtaAcsn]);

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleSave = () => {
    setIsNotesLoading(true);
    setIsPopupOpen(false);
    apiFetcher(
      `${config.apiURL}/orca/orca/${selectedAtaAcsn.ata}/${selectedAtaAcsn.ac_sn}/notes`,
      "POST",
      { noteText: userInputText, username: name },
      { "Content-Type": "application/json" }
    )
      .then((response) => {
        setUserInputText("");
        getNotesData();
      })
      .catch((e) => {
        setIsNotesLoading(false);
        setUserInputText("");
        setNotesError(e);
      });
  };

  const deleteNote = (noteID) => {
    setIsNotesLoading(true);
    apiFetcher(
      `${config.apiURL}/orca/orca/${selectedAtaAcsn.ata}/${selectedAtaAcsn.ac_sn}/notes`,
      "DELETE",
      { noteid: noteID },
      { "Content-Type": "application/json" }
    )
      .then((response) => {
        getNotesData();
      })
      .catch((e) => {
        setIsNotesLoading(false);
        setNotesError(e);
      });
  };

  return (
    <Fragment>
      <Box sx={styles.notesPanel}>
        <CardContent sx={styles.tableTitleBar}>
          <div style={styles.tableTitleBarWrapper}>
            <div style={styles.tableTitleBarTitle}>Notes</div>
            <IconButton aria-label="close_notes" style={styles.iconButton}>
              <Tooltip title="Close Notes">
                <MinimizeRoundedIcon onClick={() => setIsNotesOpen(false)} />
              </Tooltip>
            </IconButton>
          </div>
        </CardContent>

        <div style={{ flex: 1, overflowY: "auto" }}>
          {/* Panel Content */}
          {/* If there's an error fetching the data */}
          {notesError && (
            <div style={styles.spinnerAndText}>
              <DefaultText text={notesError.toString()} />
            </div>
          )}
          {/* If data is loading */}
          {isNotesLoading && (
            <div style={styles.spinnerAndText}>
              <CircularProgress color="primary" />
            </div>
          )}
          {/* once data has loaded and no errors */}
          {!isNotesLoading && !notesError && notesData &&
            <List>
              {notesData.map((note, index) => (
                <ListItem
                  sx={{ ...styles.notesTextBox, padding: '2px 8px' }}
                  divider
                  key={`${note}: ${index}`}
                >
                  <div style={{ ...styles.notesText, fontSize: '0.8rem' }}>
                    <ListItemText
                      primaryTypographyProps={{ style: { fontSize: '0.9rem' } }}
                      secondaryTypographyProps={{ style: { fontSize: '0.8rem' } }}
                      primary={note.EDITTED_BY_NAME}
                      secondary={note.EDIT_DATE}
                    />
                    <IconButton aria-label="delete" size="small">
                      <Tooltip title="Delete note">
                        <DeleteIcon fontSize="small" onClick={() => deleteNote(note.NOTE_ID)} />
                      </Tooltip>
                    </IconButton>
                  </div>
                  <TextField
                    multiline
                    disabled
                    variant="outlined"
                    fullWidth={true}
                    value={note.NOTES}
                    size="small"
                    inputProps={{ style: { fontSize: '0.8rem' } }}
                    sx={{ marginTop: '1px' }}
                  />
                </ListItem>
              ))}
            </List>
          }

        </div>

        <div style={styles.inputTextPanel}>
          <TextField
            placeholder= {!enableNotes ? "Select a defect group." : "Add a new note."}
            multiline
            disabled={!enableNotes}
            variant="outlined"
            fullWidth={true}
            maxRows={1}
            value={userInputText}
            onChange={(e) => setUserInputText(e.target.value)}
          />

          <Button
            variant="contained"
            endIcon={<SaveIcon />}
            disabled={userInputText === "" || !enableNotes}
            onClick={() => setIsPopupOpen(true)}
            >
            Save
          </Button>
        </div>
      </Box>

      <Dialog
        open={isPopupOpen && (userInputText !== "")}
        onClose={handleClose}
        >
        <DialogTitle>Enter Your Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            variant="standard"
            id="name"
            type="text"
            placeholder="e.g. John Smith"
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
              style: { textAlign: 'center' }
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
