import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Checkbox,
  Button,
  Tooltip,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import { useFetch, apiFetcher } from "utils/apiUtils";
import { config } from "config/config";
import { CsvExportButton } from "orca/components/Miscellaneous/CsvExportButton";
import { MdpNavButton } from "orca/components/Miscellaneous/MdpNavButton";
import { EntryRemoveButton } from "orca/components/Miscellaneous/EntryRemoveButton";
import { EntryReassignButton } from "orca/components/Miscellaneous/EntryReassignButton";
import { NotesButton } from "orca/components/Miscellaneous/NotesButton";
import { ReassignPanel } from "orca/components/Miscellaneous/ReassignPanel";
import { TimelineFiltersContext } from "orca/contexts/TimelineFiltersContext";
import { TimelineDataContext } from "orca/contexts/TimelineDataContext";
import { formatFiltersState } from "orca/components/TimelineTab/TimelineFiltersPanel";

export const ChronicTable = (props) => {
  const {
    rowKey,
    tableData,
    columns,
    selectedRows,
    selectedRowIndex,
    setSelectedRows,
    setSelectedRowIndex,
  } = props;

  const [displayedTableData, setDisplayedTableData] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortCol, setSortCol] = useState(null);
  // is the ata reassign panel expanded popup open
  const [isReassignPanelOpen, setIsReassignPanelOpen] = useState(false);

  useEffect(() => {
    setDisplayedTableData(tableData.data);
  }, [tableData.data]);

  const {
    setTimelineTableState,
    setIsLoading,
    setError
  } = useContext(TimelineDataContext);

  const {
    timelineFilterState,
    curTablePage,
    setCurTablePage,
    timelineSelectedRowIndex,
    setTimelineSelectedRowIndex,
    setDataSelected,
    setSortDirectionTop,
    setSortColTop,
    isNotesOpen,
    setIsNotesOpen,
  } = useContext(TimelineFiltersContext);

  const styles = ({
    container: {
      height: "87%",
      scrollbarWidth: "thin",
      backgroundColor: "white",
    },
    paginationContainer: {
      height: "13%",
      display: "flex",
      alignItems: 'center',
      justifyContent: 'right',
      flexDirection: "row",
      gap: "10px",
      textAlign: "right",
      padding: "5px 15px 10px 0",
      overflow: 'hidden',
    },
    tableRow: {
      backgroundColor: "white",
      '&:hover': {
        backgroundColor: 'rgba(90, 129, 171, 0.14)',
      },
    },
    selected: {
      backgroundColor: 'rgba(90, 129, 171, 0.24)',
    },
    headerCell: {
      padding: "6px 8px 6px 8px",
      textAlign: "center",
      fontWeight: "bold",
    },
    tableCell: {
      fontSize: 11,
      padding: "6px 8px 6px 6px",
      textAlign: "center",
    },
    splitIconButton: {
      padding: "3 10 3 10",
      margin: "0 0 0 0",
      backgroundColor: "rgba(202, 204, 206, 0.38)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgba(202, 204, 206)",
      },
    },
    revertIconButton: {
      padding: "3 10 3 10",
      margin: "0 0 0 0",
      backgroundColor: "rgba(197,180,227, 0.38)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgba(197,180,227)",
      },
    },
    snackBar: {
      vertical: "bottom",
      horizontal: "center",
    },
  });

  // check if user has mdp access
  const [hasMdpAccess, setHasMdpAccess] = useState(null);
  useEffect(() => {
    const getAccess = async () => {
      await apiFetcher(`${config.apiURL}/mdp/checkAccess`, 'GET').then(
          (response) => {
            const access = response.data.map((item) =>
              item.COMPONENT).includes('MDP');
            setHasMdpAccess(access);
          },
      );
    };

    if (hasMdpAccess == null) {
      getAccess();
    }
  }, []);

  // fetch ata list used in mdp message filter
  const { data: ataList } = useFetch(
    `${config.apiURL}/mdp/filteroptions/ata`,
    "GET"
  );

  const { data: fleetList } = useFetch(
    `${config.apiURL}/orca/filteroptions/oprname`,
    "GET"
  );

  const sortTableData = (col) => {
    const sortOrder = sortDirection === "asc" ? "desc" : "asc";
    const sortedData = tableData.data.sort((a, b) => {
      return sortOrder === "asc"
        ? a[col].localeCompare(b[col])
        : b[col].localeCompare(a[col]);
    });
    setDisplayedTableData(sortedData);
    setSortDirection(sortOrder);
  };

  const handleCheckBoxClick = (row) => {
    // if selected row is already checked then uncheck it
    if (selectedRows.map((item) => item[rowKey]).includes(row[rowKey])) {
      setSelectedRows(
        selectedRows.filter((item) => item[rowKey] !== row[rowKey])
      );
    } else {
      // if selected row is already unchecked then check it
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAllRows = (event) => {
    // if "select all" is unchecked then check all rows
    if (event.target.checked) {
      setSelectedRows(tableData.data);
    } else {
      // if "select all" is checked then uncheck all rows
      setSelectedRows([]);
    }
  };

  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [messageInfo, setMessageInfo] = useState(
    {
      message: "",
      severity: null,
    }
  )

  const handleAlertClosePopup = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertPopup(false);
  };

  const reFetchTableData = () => {
    setIsLoading(true);
    setError(null);

    const payload = formatFiltersState(timelineFilterState);
    const applyCallback = () => {
      setCurTablePage(curTablePage)
      setTimelineSelectedRowIndex(timelineSelectedRowIndex);
      setSelectedRows([]);
      setSelectedRowIndex(null);
      setSortDirectionTop("asc");
      setSortColTop(null);
    };

    apiFetcher(
      `${config.apiURL}/orca/orca`,
      "POST",
      payload,
      { "Content-Type": "application/json" }
    )
      .then((response) => {
        applyCallback();
        setTimelineTableState(response);
        setDataSelected(response.data.find(obj => obj.id === timelineSelectedRowIndex))
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  }

  const handleSplitGroup = () => {
    if (displayedTableData.length === 0) {
      setMessageInfo({
        message: "A valid search must be performed first",
        severity: "error",
      });
      setShowAlertPopup(true);
    } else if (selectedRows.length === 0) {
      setMessageInfo({
        message: "No data is selected",
        severity: "error",
      });
      setShowAlertPopup(true);
    } else {
      const defectIdList = selectedRows.map(row => row.defect_id.toString());
      console.log(defectIdList)
      apiFetcher(
        `${config.apiURL}/orca/orca/split/update`,
        "POST",
        { defectid: defectIdList },
        { "Content-Type": "application/json" }
      )
        .then((response) => {
          setMessageInfo({
            message: "The selected entry(ies) is updated successfully",
            severity: "info",
          });
          setShowAlertPopup(true);
          reFetchTableData();
        })
        .catch((e) => {
          setMessageInfo({
            message: "Error",
            severity: "error",
          });
          setShowAlertPopup(true);
        });
    }
  }

  const handleRemoveSplit = () => {
    if (displayedTableData.length === 0) {
      setMessageInfo({
        message: "A valid search must be performed first",
        severity: "error",
      });
      setShowAlertPopup(true);
    } else if (selectedRows.length === 0) {
      setMessageInfo({
        message: "No data is selected",
        severity: "error",
      });
      setShowAlertPopup(true);
    } else {
      const defectIdList = selectedRows.map(row => row.defect_id.toString());
      console.log(defectIdList)
      apiFetcher(
        `${config.apiURL}/orca/orca/split/remove`,
        "POST",
        { defectid: defectIdList },
        { "Content-Type": "application/json" }
      )
        .then((response) => {
          setMessageInfo({
            message: "The selected entry(ies) is updated successfully",
            severity: "info",
          });
          setShowAlertPopup(true);
          reFetchTableData();
        })
        .catch((e) => {
          setMessageInfo({
            message: "Error",
            severity: "error",
          });
          setShowAlertPopup(true);
        });
    }
  }

  // check if the current selected group is the user customized group
  const splitGroup = displayedTableData.length > 0 && displayedTableData.every(
    item => item.split_sub_group === "Y"
  );

  return (
    <Fragment>
      <TableContainer sx={styles.container}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead sx={styles.headerCell}>
            <TableRow>
              {displayedTableData?.length !== 0 && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < displayedTableData?.length
                    }
                    checked={
                      tableData.data?.length > 0 &&
                      selectedRows.length === displayedTableData?.length
                    }
                    onChange={handleSelectAllRows}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  sx={styles.headerCell}
                  key={column.key}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.sortable && column.key !== "mdc_linkage" ? (
                    <TableSortLabel
                      active={sortCol === column.key}
                      direction={sortDirection}
                      onClick={() => {
                        sortTableData(column.key);
                        setSortCol(column.key);
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    <div>{column.label}</div>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {displayedTableData?.map((row, index) => {
              return (
                <TableRow
                  sx={selectedRowIndex === row[rowKey]
                    ? styles.selected : styles.tableRow}
                  key={index}
                  tabIndex={-1}
                  onClick={() => {
                    setSelectedRowIndex(row[rowKey]);
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedRows
                        .map((item) => item[rowKey])
                        .includes(row[rowKey])}
                      onChange={() => {
                        handleCheckBoxClick(row);
                      }}
                    />
                  </TableCell>
                  {columns.map((column) => {
                    if (column.key === "mdc_linkage") {
                      return (
                        <TableCell
                          key={column.key}
                          align={column.align}
                          sx={styles.tableCell}
                        >
                          <MdpNavButton
                            hasMdpAccess={hasMdpAccess}
                            ataList={ataList}
                            fleetList={fleetList}
                            route={"/orca/Timeline"}
                            data={row}
                          />
                        </TableCell>
                      );
                    }

                    return (
                      <TableCell
                        align={column.align}
                        sx={styles.tableCell}
                        key={column.key}
                      >
                        {row[column.key]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={styles.paginationContainer}>
        <Tooltip
          title={splitGroup ? "Remove entries from the customized group" : "Add entries in a new customized group"}>
          <Button
            sx={splitGroup ? styles.revertIconButton : styles.splitIconButton}
            variant="contained"
            disableElevation
            size="small"
            onClick={splitGroup ? handleRemoveSplit : handleSplitGroup}>
            {splitGroup ? "Revert" : "Split"}
          </Button>
        </Tooltip>

        <EntryRemoveButton
          hasTableData={displayedTableData.length !== 0}
          selectedRows={selectedRows}
          setShowAlertPopup={setShowAlertPopup}
          setMessageInfo={setMessageInfo}
          reFetchTableData={reFetchTableData}
        />

        <EntryReassignButton
          hasTableData={displayedTableData.length !== 0}
          selectedRows={selectedRows}
          setShowAlertPopup={setShowAlertPopup}
          setMessageInfo={setMessageInfo}
          setIsPopupOpen={setIsReassignPanelOpen}
        />

        <CsvExportButton
          tableData={selectedRows.map(
              ({ operator_code, defect_id, ...rest }) => rest)}
          hasTableData={displayedTableData.length !== 0}
          fileName={"OrcaDataExport"}
          setMessageInfo={setMessageInfo}
          setShowAlertPopup={setShowAlertPopup}
        />

        <NotesButton
          isNotesOpen={isNotesOpen}
          setIsNotesOpen={setIsNotesOpen}
        />
      </div>

      <ReassignPanel
        isPopupOpen={isReassignPanelOpen}
        setIsPopupOpen={setIsReassignPanelOpen}
        selectedId={selectedRows.map(row => row.defect_id)}
        setShowAlertPopup={setShowAlertPopup}
        setMessageInfo={setMessageInfo}
        reFetchTableData={reFetchTableData}
        ataList={ataList}/>

      <Snackbar
        anchorOrigin={styles.snackBar}
        open={showAlertPopup}
        autoHideDuration={3000}
        onClose={(event, reason) => handleAlertClosePopup(event, reason)}
      >
        <MuiAlert
          onClose={(event, reason) => handleAlertClosePopup(event, reason)}
          severity={messageInfo.severity}
          elevation={6}
          variant="filled"
        >
          {messageInfo.message}
        </MuiAlert>
      </Snackbar>
    </Fragment>
  );
};
