import React, { Fragment, useContext } from 'react';
import { DetailsPanel } from 'mdp/components/Miscellaneous/DetailsPanel';
import { DiagnosticsResults } from 'mdp/components/DiagnosticsTab/DiagnosticsResults';
import { DiagnosticsDataContext } from 'mdp/contexts/DiagnosticsDataContext'
import { TableContainer } from '@mui/material';
import { DefaultText } from 'orca/components/Miscellaneous/DefaultText';
import { useTheme } from '@mui/material/styles';
import { DiagnosticsFiltersContext } from 'mdp/contexts/DiagnosticsFiltersContext';
import { DiagnosticsResultsExpanded } from './DiagnosticsResultsExpanded';

export const DiagnosticsResultsPanel = (props) => {
  const {
    isSolutionPanelExpand,
    setIsSolutionPanelExpand,
  } = props;

  const {
    displayKeywordsList
  } = useContext(DiagnosticsFiltersContext);

  const {
    diagnosticsTableState,
    diagnosticsResultsTableState,
    isResultsLoading,
    errorResults,
    solutionSelectedRow,
  } = useContext(DiagnosticsDataContext);

  const theme = useTheme();
  const styles = {
    tableHeader: {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      fontWeight: 'bold',
    },
    spinnerAndText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '89%',
    },
    container: {
      height: '94%',
      scrollbarWidth: 'thin',
      backgroundColor: 'white',
    },
  };

  const columns = [
    {
      key: 'solution_id_col',
      id: 'solution_id',
      label: 'Solution ID',
      minWidth: 90,
    },
    {
      key: 'solutions',
      id: "id_title",
      label: 'Title',
      minWidth: 150,
    }
  ];

  return (
    <Fragment>
      <DetailsPanel
        title="Solutions"
        setIsDetailsPanelExpand={setIsSolutionPanelExpand}
        specialistEditMode={false}
        isDetailsPanelExpand={isSolutionPanelExpand}
        bodyFillsTable
        hideIcon

      >
        <Fragment>
        { /* Check if troubleshootOption has been selected */ }
        {diagnosticsTableState ? (
          /* Check if user is not in keywordsList page */
          !displayKeywordsList ? (
            <DiagnosticsResults
                columns={columns}
                tableData={ diagnosticsResultsTableState }
                error={errorResults}
                isLoading={isResultsLoading}
                setIsSolutionPanelExpand={setIsSolutionPanelExpand}
            ></DiagnosticsResults>
          ) : ( // user is an keywordsList page
            <TableContainer sx={styles.container}>
              <div style={styles.spinnerAndText}>
                <DefaultText text="Select a keyword."/>
              </div>
            </TableContainer>
          )
        ) : (
          <TableContainer sx={styles.container}>
            <div style={styles.spinnerAndText}>
              <DefaultText text="Select a troubleshoot option." />
            </div>
          </TableContainer>
      )}
        </Fragment>
      </DetailsPanel>
      <DiagnosticsResultsExpanded
        isSolutionPanelExpand={isSolutionPanelExpand}
        setIsSolutionPanelExpand={setIsSolutionPanelExpand}
        selectedRow={solutionSelectedRow}
      />

    </Fragment>
  );
};
