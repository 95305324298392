import React, { useState, createContext } from 'react';

export const RawDataFiltersContext = createContext();

export const RawDataFiltersContextProvider = (props) => {
  const getDateString = (offset) => {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + offset);
    const tzOffset = newDate.getTimezoneOffset();
    const newDateWithOffset = new Date(
        newDate.getTime() - tzOffset * 60 * 1000);
    return newDateWithOffset.toISOString().split('T')[0];
  };

  const defaultRawDataFilterState = {
    dateStatusOption: 'Query By Message Date',
    name: 'rawDataFilterState',
    startDate: getDateString(-10),
    endDate: getDateString(0),
    endDateOffset: 10,
    fleets: [],
    fileTypes: [],
    acModel: [],
    actn: [],
    acsn: [],
    excludeFleets: false,
    excludeFileTypes: false,
    excludeActn: false,
    excludeAcsn: false,
    orderbyCol: 'FILE_TIMESTAMP',
    orderbyDir: 'desc',
  };
  // state of the filters panel in the raw data tab
  const [rawDataFilterState, setRawDataFilterState] = useState(
      defaultRawDataFilterState,
  );
  // copy of the filters that were applied in the current search
  const [appliedRawDataFilters, setAppliedRawDataFilters] = useState(null);
  // number of rows to display per page in the raw data table
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // page number the user is on in the raw data table
  const [curTablePage, setCurTablePage] = useState(0);
  // rows that the user has selected in the raw data table
  const [selectedRows, setSelectedRows] = useState([]);
  // bool that dictates whether should simulate a user clickin on the
  // apply button to trigger a search
  const [mimicApplyClick, setMimicApplyClick] = useState(false);

  return (
    <RawDataFiltersContext.Provider
      value={{
        rawDataFilterState,
        setRawDataFilterState,
        appliedRawDataFilters,
        setAppliedRawDataFilters,
        defaultRawDataFilterState,
        rowsPerPage,
        setRowsPerPage,
        curTablePage,
        setCurTablePage,
        selectedRows,
        setSelectedRows,
        mimicApplyClick,
        setMimicApplyClick,
      }}
    >
      {props.children}
    </RawDataFiltersContext.Provider>
  );
};

export default RawDataFiltersContextProvider;
