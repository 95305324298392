import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Checkbox,
  TablePagination,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles'
import { useFetch, apiFetcher } from 'utils/apiUtils';
import { config } from "config/config";
import { DefaultText } from "orca/components/Miscellaneous/DefaultText";
import { CsvExportButton } from "orca/components/Miscellaneous/CsvExportButton";
import { MdpNavButton } from "orca/components/Miscellaneous/MdpNavButton";
import { EntryReassignButton } from "orca/components/Miscellaneous/EntryReassignButton";
import { EntryRemoveButton } from "orca/components/Miscellaneous/EntryRemoveButton";
import { EntryRevertButton } from "orca/components/Miscellaneous/EntryRevertButton";
import { ReassignPanel } from "orca/components/Miscellaneous/ReassignPanel";
import { ReviewDataContext } from "orca/contexts/ReviewDataContext";
import { TimelineFiltersContext } from "orca/contexts/TimelineFiltersContext";
import { TimelineDataContext } from "orca/contexts/TimelineDataContext";
import { formatFiltersState } from "orca/components/TimelineTab/TimelineFiltersPanel";
import CircularProgress from "@mui/material/CircularProgress";
import FilterableSelectBox from "../Miscellaneous/FilterableSelectBox";

export const ReviewTable = (props) => {
  const {
    rowKey,
    tableName,
    columns,
    tableData,
    setTableData,
    error,
    curTablePage,
    setCurTablePage,
    rowsPerPage,
    setRowsPerPage,
    selectedRowIndex,
    setSelectedRowIndex,
    selectedRows,
    setSelectedRows,
    sortDirection,
    setSortDirection,
    sortCol,
    setSortCol,
    renameRevertButton,
    searchTerm,
    setSearchTerm,
    searchFilter,
    setSearchFilter,
  } = props;

  const theme = useTheme();

  const styles = ({
    tableHeader: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      fontWeight: "bold",
    },
    spinnerAndText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    container: {
      height: "81%",
      scrollbarWidth: "thin",
    },
    tableRow: {
      backgroundColor: "white",
      '&:hover': {
        backgroundColor: 'rgba(90, 129, 171, 0.14)',
      },
    },
    selected: {
      backgroundColor: 'rgba(90, 129, 171, 0.24)',
    },
    headerCell: {
      padding: "6px 8px 6px 8px",
      textAlign: "center",
      fontWeight: "bold",
    },
    tableCell: {
      fontSize: 11,
      padding: "6px 8px 6px 6px",
      textAlign: "center",
    },
    pagination: {
      backgroundColor: 'white',
    },
    paginationContainer: {
      height: "13%",
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '5px 0 10px 0',
      overflow: 'hidden',
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      marginLeft: "15px",
      flexDirection: "row",
      gap: "10px",
    },
    snackBar: {
      vertical: "bottom",
      horizontal: "center",
    },
  });

  const options = [
    { value: 'ata', label: 'ATA' },
    { value: 'ac_sn', label: 'Serial Number' },
    { value: 'ac_tn', label: 'Tail Number' },
    { value: 'message_type', label: 'Report Type' },
    { value: 'discrepancy', label: 'Discrepancy' },
    { value: 'corrective_action', label: 'Corrective Action' },
    { value: 'reported_date', label: 'Reported Date' },
    { value: 'resolved_date', label: 'Resolved Date' },
    { value: 'remove_closed_date_user', label: 'Removed/Closed Date' },
  ]

  const {
    setReviewTableState,
    setIsLoading: setIsReviewLoading,
    isLoading: isReviewLoading,
    setError: setReviewError,
  } = useContext(ReviewDataContext);

  const {
    setTimelineTableState,
    setIsLoading: setIsTimelineLoading,
    setError: setTimelineError
  } = useContext(TimelineDataContext);

  const {
    timelineFilterState,
    curTablePage: timelineCurTablePage,
    setCurTablePage: setTimelineCurTablePage,
    timelineSelectedRowIndex,
    setTimelineSelectedRowIndex,
    setChronicSelectedRows,
    setChronicSelectedRowIndex,
    setDataSelected: setTimelineDataSelected,
    setSortDirectionTop: setTimelineSortDirectionTop,
    setSortColTop: setTimelineSortColTop,
  } = useContext(TimelineFiltersContext);

  // check if user has mdp access
  const [hasMdpAccess, setHasMdpAccess] = useState(null);
  useEffect(() => {
    const getAccess = async () => {
      await apiFetcher(`${config.apiURL}/mdp/checkAccess`, 'GET').then(
          (response) => {
            const access = response.data.map((item) =>
              item.COMPONENT).includes('MDP');
            setHasMdpAccess(access);
          },
      );
    };

    if (hasMdpAccess == null) {
      getAccess();
    }
  }, []);

  // fetch ata list used in mdp message filter
  const { data: ataList } = useFetch(
    `${config.apiURL}/mdp/filteroptions/ata`,
    "GET"
  );

  const { data: fleetList } = useFetch(
    `${config.apiURL}/orca/filteroptions/oprname`,
    "GET"
  );


  const sortTableData = (col) => {
    const sortOrder = sortDirection === 'asc' ? 'desc' : 'asc';
    const sortedData = tableData.data.sort((a, b) => {
      const isString = (typeof a[col] === 'string' && typeof b[col] === 'string');

      return sortOrder === "asc"
      ? (isString ? a[col].localeCompare(b[col]) : a[col] - b[col])
      : (isString ? b[col].localeCompare(a[col]) : b[col] - a[col]);
    });

    setTableData(sortedData.slice(0, rowsPerPage));
    setSortDirection(sortOrder);
    setCurTablePage(0);
    setSelectedRowIndex(null);
  };


  const handleCheckBoxClick = (row) => {
    // if selected row is already checked then uncheck it
    if (selectedRows.map((item) => item[rowKey]).includes(row[rowKey])) {
      setSelectedRows(
        selectedRows.filter((item) => item[rowKey] !== row[rowKey])
      );
    } else {
      // if selected row is already unchecked then check it
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAllRows = (event) => {
    // if "select all" is unchecked then check all rows
    if (event.target.checked) {
      setSelectedRows(tableData.data);
    } else {
      // if "select all" is checked then uncheck all rows
      setSelectedRows([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setTableData(
      tableData.data.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      )
    );
    setCurTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurTablePage(0);
  };

  // is the ata reassign panel popup open
  const [isReassignPanelOpen, setIsReassignPanelOpen] = useState(false);

  const reFetchTableData = () => {
    setIsReviewLoading(true);
    setReviewError(null);
    apiFetcher(
      `${config.apiURL}/orca/orca/entryupdates/retrieve`,
      "GET",
    )
    .then((response) => {
        setReviewTableState(response);
        setSelectedRows([]);
        setSelectedRowIndex(null);
        setIsReviewLoading(false);
    })
    .catch((e) => {
      setReviewError(e);
      setIsReviewLoading(false);
    });

    // re-render timeline tab at the same time
    // only re-render if there is data displayed in Timeline tab
    if (timelineFilterState.acModel) {
      console.log(timelineFilterState.acModel)
      setIsTimelineLoading(true);
      setTimelineError(null);

      const payload = formatFiltersState(timelineFilterState);
      const applyCallback = () => {
        setTimelineCurTablePage(timelineCurTablePage)
        setTimelineSelectedRowIndex(timelineSelectedRowIndex);
        setChronicSelectedRows([]);
        setChronicSelectedRowIndex(null);
        setTimelineSortDirectionTop("asc");
        setTimelineSortColTop(null);
      };

      apiFetcher(
        `${config.apiURL}/orca/orca`,
        "POST",
        payload,
        { "Content-Type": "application/json" }
      )
        .then((response) => {
          applyCallback();
          setTimelineTableState(response);
          setTimelineDataSelected(response.data.find(obj => obj.id === timelineSelectedRowIndex))
          setIsTimelineLoading(false);
        })
        .catch((e) => {
          setTimelineError(e);
          setIsTimelineLoading(false);
        });
    }
  }

  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [messageInfo, setMessageInfo] = useState(
    {
      message: "",
      severity: null,
    }
  )

  const handleAlertClosePopup = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertPopup(false);
  };


  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {error && (
        <div style={styles.spinnerAndText}>
          <DefaultText text={error.toString()} />
        </div>
      )}

      {!error && (tableData.data || isReviewLoading) && (
        <Fragment>
          <TableContainer sx={styles.container}>
            {/* if the query completed but the table is empty */}
            {isReviewLoading && (
              <div style={styles.spinnerAndText}>
                <CircularProgress color="primary" />
              </div>
            )}
            {(!isReviewLoading && tableData.data?.length === 0) && (
              <div style={styles.spinnerAndText}>
                <DefaultText text="No Data available" />
              </div>
            )}
            {/* if query completed and table is not empty */}
            {(!isReviewLoading && tableData.data?.length !== 0) && (
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead sx={styles.headerCell}>
                  <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={
                              selectedRows.length > 0 &&
                              selectedRows.length < tableData.data.length
                            }
                            checked={
                              tableData.data.length > 0 &&
                              selectedRows.length === tableData.data.length
                            }
                            onChange={handleSelectAllRows}
                        />
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell
                        sx={styles.headerCell}
                        key={column.key}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.sortable ? (
                          <TableSortLabel
                            active={sortCol === column.key}
                            direction={sortDirection}
                            onClick={() => {
                              sortTableData(column.key);
                              setSortCol(column.key);
                            }}
                          >
                            {column.label}
                          </TableSortLabel>
                        ) : (
                          <div>{column.label}</div>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableData.data?.map((row) => {
                    return (
                      <TableRow
                        sx={selectedRowIndex === row[rowKey]
                          ? styles.selected : styles.tableRow}
                        key={row[rowKey]}
                        tabIndex={-1}
                        onClick={(event) => {
                          setSelectedRowIndex(row[rowKey]);
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedRows
                              .map((item) => item[rowKey])
                              .includes(row[rowKey])}
                            onChange={() => {
                              handleCheckBoxClick(row);
                            }}
                          />
                        </TableCell>

                        {columns.map((column) => {
                          if (column.key === "mdc_linkage") {
                            return (
                              <TableCell
                                key={column.key}
                                align={column.align}
                                sx={styles.tableCell}
                              >
                                <MdpNavButton
                                  hasMdpAccess={hasMdpAccess}
                                  ataList={ataList}
                                  fleetList={fleetList}
                                  route={"/orca/Review"}
                                  data={row}
                                />
                              </TableCell>
                            );
                          }

                          const value = row[column.key];

                          return (
                            <TableCell
                              align={column.align}
                              sx={styles.tableCell}
                              key={column.key}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <div style={styles.paginationContainer}>
            <div style={styles.buttonContainer}>
              <CsvExportButton
                tableData={selectedRows.map(
                    ({ defect_id, operator_code, orca_status, ...rest }) => rest)}
                hasTableData={tableData.data.length !== 0}
                fileName={"ReviewDataExport"}
                setShowAlertPopup={setShowAlertPopup}
                setMessageInfo={setMessageInfo}
              />

              <EntryRevertButton
                hasTableData={tableData.data.length !== 0}
                selectedRows={selectedRows}
                setShowAlertPopup={setShowAlertPopup}
                setMessageInfo={setMessageInfo}
                reFetchTableData={reFetchTableData}
                renameRevertButton={renameRevertButton}
              />

              {tableName === "reassign" && (
                <EntryRemoveButton
                  hasTableData={tableData.data.length !== 0}
                  selectedRows={selectedRows}
                  setShowAlertPopup={setShowAlertPopup}
                  setMessageInfo={setMessageInfo}
                  reFetchTableData={reFetchTableData}
                />
              )}

              <EntryReassignButton
                hasTableData={tableData.data.length !== 0}
                selectedRows={selectedRows}
                setIsPopupOpen={setIsReassignPanelOpen}
                setShowAlertPopup={setShowAlertPopup}
                setMessageInfo={setMessageInfo}
              />

              <FilterableSelectBox
              options={options}
              labelName={"Filter by..."}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              />
            </div>

            <TablePagination
              sx={styles.pagination}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={tableData.data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={curTablePage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          <ReassignPanel
            isPopupOpen={isReassignPanelOpen}
            setIsPopupOpen={setIsReassignPanelOpen}
            selectedId={selectedRows.map(row => row.defect_id)}
            setShowAlertPopup={setShowAlertPopup}
            setMessageInfo={setMessageInfo}
            reFetchTableData={reFetchTableData}
            ataList={ataList}/>

          <Snackbar
            anchorOrigin={styles.snackBar}
            open={showAlertPopup}
            autoHideDuration={3000}
            onClose={(event, reason) => handleAlertClosePopup(event, reason)}
          >
            <MuiAlert
              onClose={(event, reason) => handleAlertClosePopup(event, reason)}
              severity={messageInfo.severity}
              elevation={6}
              variant="filled"
            >
              {messageInfo.message}
            </MuiAlert>
          </Snackbar>
        </Fragment>
      )}
    </Fragment>
  );
};
