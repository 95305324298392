import React, { Fragment, useState, useEffect, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { config } from 'config/config';
import { useFetch } from 'utils/apiUtils';
import { AlertsFiltersContext } from 'orca/contexts/AlertsFiltersContext';
import { AlertsDataContext } from 'orca/contexts/AlertsDataContext';
import { FiltersPanel } from 'orca/components/Miscellaneous/FiltersPanel';
import { DateTimeSelect } from 'orca/components/Miscellaneous/DateTimeSelect';
import { SelectBox } from 'orca/components/Miscellaneous/SelectBox';
import { SelectBoxATA } from 'orca/components/Miscellaneous/SelectBoxATA';
import { CheckBoxSelect } from 'orca/components/Miscellaneous/CheckBoxSelect';
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";

const formatFiltersState = (filterState) => {
  // apply these formatting before the search is sent to backend
  if (filterState.acModel.length) {
    return {
      ...filterState,
      acModel: filterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700',
      acsn: filterState.acsn.map((i) => i.Value),
      actn: filterState.actn.map((i) => {
        // for SkyWest, add 'N' as prefix to tail numm
        if (i.OPERATOR_CODE === "SKW" && /^[0-9]{3}[A-Z]{2}$/.test(i.Value)) {
          return "N" + i.Value;
        }
        return i.Value;
      }),
      messageType: filterState.messageType.map((i) => i.Value),
      keywords: filterState.keywords.map((i) => i.Value),
      fleets: filterState.fleets.map(item => item.Value),
    };
  } else {
    return filterState;
  }
};

export const AlertsFiltersPanel = (props) => {
  const styles = ({
    accordianRoot: {
      boxShadow: 'none',
    },
    accordianLabel: {
      fontSize: 16,
    },
    searchBar: {
      marginTop: '30px',
      width: '100%',
    },
    searchBarText: {
      fontSize: 12,
    },
  });

  const [shouldValidateFilters, setShouldValidateFilters] = useState(false);

  const {
    alertsFilterState,
    setAlertsFilterState,
    setAppliedAlertsFilters,
    defaultAlertsFilterState,
    setCurTablePage,
    mimicApplyClick,
    setMimicApplyClick,
    setKeywordsSelected,
    setAcsnSelected,
    setSelectedRows,
    setRowSelected,
  } = useContext(AlertsFiltersContext);

  const {
    setAlertsTableState,
    isLoading,
    setIsLoading,
    setError,
  } = useContext(AlertsDataContext);

  const filterOptionsByACModel = (options) => {
    /*
    @props <options:list>: list of dropdown options
    [{value:"",AC_MODEL:""},{value:"",AC_MODEL:""},...]

    @return <list>: returns the options list filtered on the
    acmodel that the user currently has selected. Returns empty list
    if no ac model is selected
    */
    if (alertsFilterState.acModel.length && options.data) {
      // our database uses CRJ and CRJ700 to identify CRJ200 and
      // CRJ700/900/1000 respectively, when displying models to the
      // user, make sure to use CRJ200 and CRJ700/900/1000

      return options.data.reduce(
        (i, j) => {
          if (j.AC_MODEL === (
            alertsFilterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700'
          )) {
            i.push({ ...j, AC_MODEL: alertsFilterState.acModel });
          }
          return i
        }, []);
    } else {
      return [];
    }
  };

  const filterOptionsByFleet = (options, exclude) => {
    /*
    @props <options:list>: list of dropdown options
    [{ACSN/ACTN:"",AC_MODEL:"", OPERATOR_CODE:""},
    {ACSN/ACTN:"",AC_MODEL:"", OPERATOR_CODE:""},...]
    @props <exclude:boolean>: dictates if were excluding options by fleet

    @return <list>: returns the options list filtered on the
    fleets that the user currently has selected. Returns original list
    if no fleets is selected
    */
    if (alertsFilterState.fleets.length && options) {
      const transformedFleetArray = alertsFilterState.fleets.map(item => item.Value);
      const validOprcodes = transformedFleetArray.map(
        (oprname) => fleetList.oprData[oprname]
      );
      if (exclude) {
        options = options.filter(
          (option) => !validOprcodes.includes(option.OPERATOR_CODE)
        );
      } else {
        options = options.filter((option) =>
          validOprcodes.includes(option.OPERATOR_CODE)
        );
      }
    }
    return options;
  };

  // fetchs filter
  const { data: fleetList, error: fleetListError } = useFetch(
      `${config.apiURL}/orca/filteroptions/oprname`,
      'GET',
  );
  const { data: acsnList, error: acsnListError } = useFetch(
      `${config.apiURL}/orca/filteroptions/acsn`,
      'GET',
  );
  const { data: actnList, error: actnListError } = useFetch(
    `${config.apiURL}/orca/filteroptions/actn`,
    'GET',
);
  const { data: messageTypeList, error: messageTypeListError } = useFetch(
      `${config.apiURL}/orca/filteroptions/messagetype`,
      'GET',
  );
  const { data: keywordList, error: keywordListError } = useFetch(
      `${config.apiURL}/orca/filteroptions/keyword`,
      'GET',
  );

  // check if it is internal user
  const [showFleetTab, setShowFleetTab] = useState(false);
  useEffect(() => {
    if (fleetList.oprData &&
      Object.keys(fleetList.oprData).length === 1) {
      setShowFleetTab(false);
      setAlertsFilterState({
        ...alertsFilterState,
        fleets: [{ Value: Object.keys(fleetList.oprData)[0] }]
      })
    } else {
      setShowFleetTab(true);
    }
  }, [fleetList.oprData]);

  // checks if the filter is loaded
  const isFiltersLoading = () => {
    const isLoading = Boolean(
        (!fleetList.oprData || !acsnList.data || !actnList.data ||
          !messageTypeList || !keywordList.keywords) &&
        !fleetListError && !acsnListError && !actnListError &&
        !messageTypeListError && !keywordListError,
    );
    return isLoading;
  };

  const isFiltersFetchError = () => {
    return Boolean(fleetListError || acsnListError || actnListError ||
      messageTypeListError || keywordListError);
  };

  const isFiltersValid = () => {
    // make sure the date/time and ac model filters arent empty
    // before starting a query
    return Boolean(
        alertsFilterState.startDate &&
        alertsFilterState.endDate &&
        alertsFilterState.acModel.length,
    );
  };

  const formatKeywords = (list) => {
    return list?.filter(row => !row.Value.includes('Select'))
  };

  const transformFleetObject = (obj) => {
    return Object.keys(obj).map(key => ({
      Value: key
    }));
  };

  return (
    <FiltersPanel
      applyURL={`${config.apiURL}/orca/alerts`}
      applyCallback={() => {
        setKeywordsSelected([]);
        setAcsnSelected([]);
        setSelectedRows([]);
        setRowSelected(null);
        }
      }
      filterStateName="alertsFilterState"
      filterState={alertsFilterState}
      setFilterState={setAlertsFilterState}
      formatFilters={formatFiltersState}
      setAppliedFilters={setAppliedAlertsFilters}
      defaultFilterState={defaultAlertsFilterState}
      isFiltersLoading={isFiltersLoading()}
      isFiltersFetchError={isFiltersFetchError()}
      isFiltersValid={isFiltersValid()}
      isTableDataLoading={isLoading}
      setIsTableDataLoading={setIsLoading}
      setTableDataState={setAlertsTableState}
      setError={setError}
      setCurTablePage={setCurTablePage}
      shouldValidateFilters={shouldValidateFilters}
      setShouldValidateFilters={setShouldValidateFilters}
      setMimicApplyClick={setMimicApplyClick}
      mimicApplyClick={mimicApplyClick}
      popupText="Date/Time filters cannot be empty!"
    >
      {!isFiltersLoading() && !isFiltersFetchError() && (
        <Fragment>
          <TextField
            id="keywords_search"
            sx={{
              marginBottom: "10px",
              marginX: '2rem',
              maxWidth: '350px',
              '& input::placeholder': {
              fontSize: '0.9rem',
            },
          }}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            placeholder="Search keywords..."
            size="small"
            value={alertsFilterState.searchKeyword}
            onChange={(e) => {
              setAlertsFilterState({
                ...alertsFilterState,
                searchKeyword: e.target.value,
              });
            }}
          />

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Date (UTC)
            </AccordionSummary>
            <AccordionDetails>
              <DateTimeSelect
                includeTime={false}
                filterState={alertsFilterState}
                shouldValidate={false}
              ></DateTimeSelect>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}>
              Aircraft Model
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxSelect
                filterState={alertsFilterState}
                options={[config.CRJ, config.CRJ700]}
                filterName="acModel"
                shouldValidate={shouldValidateFilters}
              />
            </AccordionDetails>
          </Accordion>

          {showFleetTab && (<Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}>
              Fleet
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                selectOptions={transformFleetObject(fleetList.oprData)}
                filterState={alertsFilterState}
                setFilterState={setAlertsFilterState}
                filterName="fleets"
                excludeName="excludeFleets"
                setAllFilters={true}
              />
            </AccordionDetails>
          </Accordion>)}

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Serial Number
            </AccordionSummary>
            <AccordionDetails>
              {acsnList.data && (
                <SelectBox
                  multiple
                  groupName="AC_MODEL"
                  groupOptionLabel="Value"
                  noOptionsText={alertsFilterState.acModel.length
                    ? 'No options' : 'Select an AC Model first'
                  }
                  selectOptions={filterOptionsByFleet(
                    filterOptionsByACModel(acsnList),
                    alertsFilterState.excludeFleets)}
                  filterState={alertsFilterState}
                  setFilterState={setAlertsFilterState}
                  filterName="acsn"
                  excludeName="excludeAcsn"
                  setAllFilters={true}
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Tail Number
            </AccordionSummary>
            <AccordionDetails>
              {actnList.data && (
                <SelectBox
                  multiple
                  groupName="AC_MODEL"
                  groupOptionLabel="Value"
                  noOptionsText={alertsFilterState.acModel.length
                    ? "No options" : "Select an AC Model first"}
                  selectOptions={filterOptionsByFleet(
                    filterOptionsByACModel(actnList),
                    alertsFilterState.excludeFleets,
                  )}
                  filterState={alertsFilterState}
                  setFilterState={setAlertsFilterState}
                  filterName="actn"
                  excludeName="excludeActn"
                  setAllFilters={true}
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Report Type
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={alertsFilterState.acModel.length
                  ? 'No options' : 'Select an AC Model first'
                }
                selectOptions={filterOptionsByACModel(messageTypeList)}
                filterState={alertsFilterState}
                setFilterState={setAlertsFilterState}
                filterName="messageType"
                excludeName="excludeMessageTypes"
                setAllFilters={true}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Events
            </AccordionSummary>
            <AccordionDetails>
              <SelectBoxATA
                multiple
                selectAll="wholeCategory"
                selectOptions={formatKeywords(keywordList.keywords)}
                filterState={alertsFilterState}
                setFilterState={setAlertsFilterState}
                filterName="keywords"
                excludeName="excludeKeywords"
              />
            </AccordionDetails>
          </Accordion>
        </Fragment>
      )}
    </FiltersPanel>
  );
};
