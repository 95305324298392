import React from 'react';
import { TextField, MenuItem, Select, Box, FormControl, InputLabel } from '@mui/material';

const FilterableSelect = (props) => {
  const {
    options,
    labelName,
    searchTerm,
    setSearchTerm,
    searchFilter,
    setSearchFilter
  } = props;

  const handleChange = (event) => {
    if (event.target.value !== "") {
      setSearchFilter(event.target.value);
    } else {
      setSearchFilter(event.target.value);
      setSearchTerm("");
    }
  };
  return (
    <Box
      display="flex"
      alignItems="center"
    >
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        {searchFilter === '' && (
          <InputLabel
            id={`${labelName}_labelid`}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '14px',
              transform: 'translateY(-50%)',
              fontSize: '0.875rem',
              pointerEvents: 'none',
            }}
          >
            {labelName}
          </InputLabel>
        )}
        <Select
          labelId={`${labelName}_labelid`}
          id={`${labelName}_id`}
          value={searchFilter}
          onChange={handleChange}
          sx={{
            height: 36,
            backgroundColor: '#f0f0f0',
            '.MuiSelect-select': {
              py: 1,
              fontSize: '0.875rem',
            },
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options.map((op) => (
            <MenuItem key={op.value} value={op.value}>
              {op.label}
            </MenuItem>
          ))}

        </Select>
      </FormControl>
      <TextField
        label={searchFilter === "" ? "Select a filter." : "Search..."}
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        disabled={searchFilter === ""}
        sx={{
          flexGrow: 1,
          width: 'auto',
          '& .MuiInputBase-root': {
            height: 36,
            display: 'flex',
            alignItems: 'center',
            padding: '0 6px',
          },
          '& .MuiFormLabel-root': {
            transform: searchTerm.length > 0 ? 'translateY(-6px) translateX(14px) scale(0.75)' : 'translateY(7px) translateX(14px) scale(1)',
            '&.Mui-focused': {
              transform: 'translateY(-6px) translateX(14px) scale(0.75)',
            },
            transition: 'transform 0.2s ease, top 0.2s ease',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '4px',
          },
        }}
      />
    </Box>
  );
};

export default FilterableSelect;
