import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import { TimelineFiltersContext } from 'orca/contexts/TimelineFiltersContext';
import { SpltFiltersContext } from 'orca/contexts/SpltFiltersContext';
import { AlertsFiltersContext } from 'orca/contexts/AlertsFiltersContext';

const styles = ({
  dateForm: {
    width: "112px",
    marginBottom: "15px",
  },
  dateFormInput: {
    fontSize: 12,
  },
  dateTimeContainer: {
    minHeight: "100%",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },

  dateTimeBox: {
    display: "flex",
    flexBasis: "45%",
    justifyContent: "center",
    flexDirection: "column",
    margin: "5px",
  },
});

export const DateTimeSelect = (props) => {
  const {
    filterState,
    includeTime,
    shouldValidate,
    filterName, // filter must be in this format: ${filterName}StartDate & ${filterName}EndDate
    setFilterState
  } = props;

  // change all tab's time simultaneously
  const {
    timelineFilterState,
    setTimelineFilterState,
  } = useContext(TimelineFiltersContext);

  const {
    spltFilterState,
    setSpltFilterState,
  } = useContext(SpltFiltersContext);

  const {
    alertsFilterState,
    setAlertsFilterState,
  } = useContext(AlertsFiltersContext);

  const onChangeActionGrouped = (dataType) => (e) => {
    setTimelineFilterState({
      ...timelineFilterState,
      [dataType]: e.target.value,
    });
    setSpltFilterState({
      ...spltFilterState,
      [dataType]: e.target.value,
    });
    setAlertsFilterState({
      ...alertsFilterState,
      [dataType]: e.target.value,
    });
  }
  // if filterName & setFilterState are present, change specific filterName
  const onChangeActionSingle = (fn) => (e) => {
    setFilterState({
      ...filterState,
      [fn]: e.target.value,
    });
  };

  return (
    <div style={styles.dateTimeContainer}>
      <div style={styles.dateTimeBox}>
        <TextField
          variant="standard"
          sx={styles.dateForm}
          error={!filterState.startDate && shouldValidate}
          id="startDate"
          label="Start Date"
          type="date"
          value={filterName && setFilterState ? filterState[`${filterName}StartDate`] : filterState.startDate}
          onChange={filterName && setFilterState ? onChangeActionSingle(`${filterName}StartDate`) : onChangeActionGrouped("startDate")}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            style: styles.dateFormInput,
          }} />
      </div>
      <div style={styles.dateTimeBox}>
        <TextField
          variant="standard"
          sx={styles.dateForm}
          error={!filterState.endDate && shouldValidate}
          id="endDate"
          label="End Date"
          type="date"
          value={filterName && setFilterState ? filterState[`${filterName}EndDate`] : filterState.endDate}
          onChange={filterName && setFilterState ? onChangeActionSingle(`${filterName}EndDate`) : onChangeActionGrouped("endDate") }
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: { min: filterState.startDate },
            style: styles.dateFormInput,
          }} />
      </div>
      {includeTime ? (
        <>
          <div style={styles.dateTimeBox}>
            <TextField
              variant="standard"
              sx={styles.dateForm}
              error={!filterState.startTime && shouldValidate}
              id="endDate"
              label="Start Time"
              type="time"
              value={filterName && setFilterState ? filterState[`${filterName}StartTime`] : filterState.startTime}
              onChange={filterName && setFilterState ? onChangeActionSingle(`${filterName}StartTime`) : onChangeActionGrouped("startTime")}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  input: styles.dateFormInput,
                },
              }} />
          </div>
          <div style={styles.dateTimeBox}>
            <TextField
              variant="standard"
              sx={styles.dateForm}
              error={!filterState.endTime && shouldValidate}
              id="endDate"
              label="End Time"
              type="time"
              value={filterName && setFilterState ? filterState[`${filterName}EndTime`] : filterState.endTime}
              onChange={filterName && setFilterState ? onChangeActionSingle(`${filterName}EndTime`) : onChangeActionGrouped("endTime")}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                classes: {
                  input: styles.dateFormInput,
                },
              }} />
          </div>
        </>
      ) : null}
    </div>
  );
};
