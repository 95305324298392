import React, { useContext, useEffect } from "react";
import Card from "@mui/material/Card";
import "orca/components/ReviewTab/ReviewTab.css";
import { useFetch } from "utils/apiUtils";
import { config } from "config/config";
import { ReviewDataContext } from "orca/contexts/ReviewDataContext";
import { RemoveTablePanel } from "orca/components/ReviewTab/RemoveTablePanel";
import { ReassignTablePanel } from "orca/components/ReviewTab/ReassignTablePanel";
import { LoadingIcon } from "orca/components/Miscellaneous/LoadingIcon";
// import { ReviewFiltersPanel } from "orca/components/ReviewTab/ReviewFiltersPanel"

export const ReviewTab = (props) => {
  const styles = ({
    root: {
      height: "100%",
      marginRight: "10px",
      borderTop: "1px solid rgba(0,0,0,0.2)",
      borderLeft: "1px solid rgba(0,0,0,0.2)",
      borderRight: "1px solid rgba(0,0,0,0.2)",
      borderBottom: "1px solid rgba(0,0,0,0.2)",
    },
  });

  // fetchs filter
  // const { data: fleetList, error: fleetListError } = useFetch(
  //   `${config.apiURL}/orca/filteroptions/oprname`,
  //   "GET"
  // );
  // const { data: acsnList, error: acsnListError } = useFetch(
  //   `${config.apiURL}/orca/filteroptions/acsn`,
  //   "GET"
  // );
  // const { data: actnList, error: actnListError } = useFetch(
  //   `${config.apiURL}/orca/filteroptions/actn`,
  //   "GET"
  // );
  // const { data: ataList, error: ataListError } = useFetch(
  //   `${config.apiURL}/orca/filteroptions/ata`,
  //   "GET"
  // );


  // check filter loading status
  // const isFiltersLoading = () => {
  //   const isLoading = Boolean(
  //     (!fleetList.oprData ||
  //       !acsnList.data ||
  //       !actnList.data ||
  //       !ataList.data) &&
  //       !fleetListError &&
  //       !acsnListError &&
  //       !actnListError &&
  //       !ataListError
  //   );
  //   setIsFirstTime(false);
  //   return isLoading;
  // };
  // const isFiltersFetchError = () => {
  //   return Boolean(
  //     fleetListError || acsnListError || actnListError ||
  //     ataListError
  //   );
  // };

  const {
    setReviewTableState,
    isLoading,
    setIsLoading,
    setError,
  } = useContext(ReviewDataContext);

  const { data: tableData, error: tableDataError } = useFetch(
    `${config.apiURL}/orca/orca/entryupdates/retrieve`,
    "GET"
  );

  useEffect(() => {
    setIsLoading(
      Boolean(
        (!tableData.data && !tableDataError)
      )
    );

    setError(
      Boolean(tableDataError)
    )

    setReviewTableState(
      tableData
    )
  }, [tableData, tableDataError]);

  return (
    <div className="wrapper">
      {isLoading ? (
        <LoadingIcon maxWidth={`500px`}></LoadingIcon>
      ) : (
        <div className="reviewTab">
          {/* <div className="reviewTabFiltersPane"> NOT ADDED. Uncomment if required in future versions.
            <Card sx={styles.root}>
              <ReviewFiltersPanel
                isFiltersLoading={isFiltersLoading()}
                isFiltersFetchError={isFiltersFetchError()}
                fleetList={fleetList}
                acsnList={acsnList}
                actnList={actnList}
                ataList={ataList}
              ></ReviewFiltersPanel>
            </Card>
          </div> */}
          <div className="reviewTabDataPane">
            <div className="reviewTabRemovePane">
              <Card sx={styles.root}>
                <RemoveTablePanel></RemoveTablePanel>
              </Card>
            </div>
            <div className="reviewTabReassignPane">
              <Card sx={styles.root}>
                <ReassignTablePanel></ReassignTablePanel>
              </Card>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
