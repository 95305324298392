import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";


const styles = ({
  selectBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  header: {
    padding: "0 0 0 0",
    margin: "0 0 0 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  pillSelectRoot: {
    width: "100%",
    padding: "0 0 0 0",
    margin: "0 0 0 0",
  },
  labelText: {
    fontSize: 12,
  },
});

export const SelectBoxSingle = (props) => {
  /*
  @props <title:String>: Title of the selectbox, it will show to the left of
  the exclude button, can be left empty
  @props <filterState>: the state the will be updated when options are selected
  or deselected
  @props <setFilterState>: setter for filterState
  @props <filterName>: name of the property in filterState that will be updated
  when options are selected or deselected e.g. "acModel" or "startDate"
  @props <excludeName>: name of the property in filterState that will be updated
  when the exlude button is toggled, if empty then the exclude button wont be
  rendered
  @props <selectOptions>: list of dropdown options
  @props <groupName>: name of the property in selectOptions to group options by
  e.g. if we want to group options by AC_MODEL
  @props <groupOptionLabel>: name of the property in selectOptions that has the
  label of the option e.g. "acsn" or "eqid"
  @props <multiple>: allow multiple option selection
  @props <noOptionsText>: text to show when no dropdown options available
  @props <placeholderText>: text to show in the select box
  @props <limit>: number of items to show (default 100)
  */
  const [inputValue, setInputValue] = useState("");
  const {
    noOptionsText,
    filterName,
    groupOptionLabel,
    excludeName,
    title,
    selectOptions,
    groupName,
    filterState,
    placeholderText,
    setFilterState,
    limit,
    displayLabel,
    optionChangeHandler,
    initialValue,
  } = props;

  const filterOptions = createFilterOptions({
    ignoreCase: true, // ignore case on search
    limit: limit ?? 100, // max number of dropdown options to show
  });

  const handleExcludeChange = (event, newState) => {
    setFilterState({
      ...filterState,
      [excludeName]: newState,
    });
  };
  const handleDropdownSelectChange = (event, newValue) => {
    try {
      setFilterState({
          ...filterState,
          [filterName]: newValue,
        });
      if (optionChangeHandler) {
        optionChangeHandler();
      }
    } catch {
      console.log("error when choosing new value, handleDropdownSelectChange")
    }
  }

  const handleTypedInputChangeGrouped = (event, newInputValue) => {
    // split used for debug purposes
    const newInputSplit = newInputValue.split(";+&debug&+;").map((item) => item.trim());
    // if they pressed the ;+&debug&+; update the filterstate with the input
    if (newInputSplit.length > 1) {
      setFilterState({
        ...filterState,
        [filterName]: filterState[filterName].concat(
          selectOptions.filter((i) =>
            newInputSplit.includes(i[groupOptionLabel])
          )
        ),
      });
    } else {
      // update the text box as they type
      setInputValue(newInputValue);
    }
  };
  return (
    <div style={styles.selectBox}>
      {excludeName && (
        <div style={styles.header}>
          <Typography sx={styles.labelText}>{title}</Typography>
          <FormControlLabel
            control={
              <Switch
                size="small"
                color="primary"
                checked={filterState[excludeName]}
                onChange={handleExcludeChange}
              />
            }
            label={
              <Typography sx={styles.labelText}>Exclude</Typography>
            }
            labelPlacement="start"
          />
        </div>
      )}
      {groupName ? (
        <Autocomplete
          sx={styles.pillSelectRoot}
          // by default "No Options" when dropdown is empty
          // if noOptionsText is provided show that if selectOptions
          // is empty
          noOptionsText={
            noOptionsText
              ? selectOptions.length
                ? "No Options"
                : noOptionsText
              : "No Options"
          }
          classes={{ input: styles.labelText }}
          options={selectOptions}
          groupBy={(options) => options[groupName]}
          getOptionLabel={(options) => options[displayLabel] || null}
          size="small"
          limitTags={6}
          filterOptions={filterOptions}
          value={filterState[filterName] || initialValue || null}
          defaultValue={initialValue || undefined}
          inputValue={inputValue}
          onChange={handleDropdownSelectChange}
          onInputChange={handleTypedInputChangeGrouped}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholderText || ""}
            />
          )}
        />
      ) : (
        <Autocomplete
        sx={styles.pillSelectRoot}
        // by default "No Options" when dropdown is empty
        // if noOptionsText is provided show that if selectOptions
        // is empty
        noOptionsText={
          noOptionsText
            ? selectOptions.length
              ? "No Options"
              : noOptionsText
            : "No Options"
        }
        classes={{ input: styles.labelText }}
        options={selectOptions}
        getOptionLabel={(options) => options[displayLabel] || null}
        size="small"
        limitTags={6}
        filterOptions={filterOptions}
        value={filterState[filterName] || initialValue || null}
        defaultValue={initialValue || undefined}
        inputValue={inputValue}
        onChange={handleDropdownSelectChange}
        onInputChange={handleTypedInputChangeGrouped}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholderText || ""}
          />
        )}
      />
      )}
    </div>
  );
};
