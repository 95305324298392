import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import 'orca/components/AlertsTab/AlertsTab.css';
import { DefaultText } from 'orca/components/Miscellaneous/DefaultText';
import { DiagnosticsFiltersContext } from 'mdp/contexts/DiagnosticsFiltersContext';
import { KeywordsList } from 'mdp/components/DiagnosticsTab/DiagnosticsComponents/KeywordsList'
import { SelectBoxSingle } from 'mdp/components/Miscellaneous/SelectBoxSingle';
import { DiagnosticsDataContext } from 'mdp/contexts/DiagnosticsDataContext';
import { apiFetcher } from "utils/apiUtils";
import { config } from 'config/config';

export const DiagnosticsTable = (props) => {
  const {
    columns,
    tableData,
    error,
    isLoading,
  } = props;

  const {
    diagTableFilterState,
    setDiagTableFilterState,
    displayKeywordsList,
    setDisplayKeywordsList,
  } = useContext(DiagnosticsFiltersContext);

  const {
    setDiagnosticsResultsTableState,
    setIsResultsLoading,
    setErrorResults,
    setDiagnosticsTableState,
  } = useContext(DiagnosticsDataContext);

  const theme = useTheme();
  const styles = {
    tableHeader: {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      fontWeight: 'bold',
    },
    spinnerAndText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '89%',
    },
    container: {
      height: '94%',
      scrollbarWidth: 'thin',
      backgroundColor: 'white',
    },
    paginationContainer: {
      height: "6%",
      display: "flex",
      alignItems: 'center',
      justifyContent: 'right',
      textAlign: "right",
      padding: "5px 15px 10px 0"
    },
    tableRow: {
      cursor: 'pointer',
      backgroundColor: "white",
      '&:hover': {
        backgroundColor: 'rgba(90, 129, 171, 0.14)',
      },
    },
    disabled: {
      color: 'rgba(205, 205, 205, 0.8)',
      cursor: 'notAllowed',
      pointerEvents: 'none',
    },
    selected: {
      backgroundColor: 'rgba(90, 129, 171, 0.24)',
    },
    tableSubRow: {
      padding: '3px 24px 3px 50px',
    },
    headerCell: {
      padding: '6px 8px 6px 8px',
      textAlign: 'center',
      fontWeight: 'bold',
      borderBottom: '2px solid lightgrey',
    },
    tableCell: {
      fontSize: 11,
      padding: '6px 8px 6px 6px',
      textAlign: 'center',
    },
  };

  const [chosenKeywordItem, setChosenKeywordItem] = useState({});
  const [chosenItemDefaultState, setChosenItemDefaultState] = useState({})

  useEffect(() => {
    if (tableData && tableData.data && (tableData.troubleshootOption === "KeywordChosen") && !Object.keys(diagTableFilterState).length) { // instead, if the searchKeyword = "smt", then reset accordingly.
      const updatedFilters = {};
      tableData.data?.forEach((row, rowIndex) => {
        updatedFilters[row.attributeQuestion] = null;
      });

      if (tableData.chosenItem?.attributeID?.length > 0) {
        setChosenKeywordItem((prevChosenKeywordItem) => {
          const newItem = {
            attributeID: tableData.chosenItem.attributeID,
            attributeQuestion: tableData.chosenItem.attributeQuestion,
            valueID: tableData.chosenItem.valueID,
            valueName: tableData.chosenItem.valueName,
          };
          updatedFilters[tableData.chosenItem.attributeQuestion] = newItem;
          return newItem;
        });
      }
      setDiagTableFilterState(updatedFilters);
    } else if (tableData?.troubleshootOption === "Keywords") {
      setChosenKeywordItem({});
      setChosenItemDefaultState({});
      setDiagTableFilterState({});
      setDisplayKeywordsList(true);
      setErrorResults(null);
      const emptySolution = {
        data: [],
        dataUpdatedQA: [],
        troubleshootOption: "getSolutions",
        searchKeyword: "[]"
      }
      setDiagnosticsResultsTableState(emptySolution);
    }
  }, [tableData]);

  // fetches solution data and possibly additional rows to questionaire
  const fetchSolutionsData = (filters) => {
    try {
      const resultArray = [];
      Object.keys(filters).forEach((key, index) => {
        const filterValue = filters[key];
        if (filterValue) {
          resultArray.push({ questionID: filterValue.attributeID, AnswerID: filterValue.valueID });
        }
      });
      if (resultArray.length) {
        setIsResultsLoading(true);
        setErrorResults(null);

        const payload = {
            troubleshootOption: "getSolutions",
            searchKeyword: JSON.stringify(resultArray, null, 0),
        }
        apiFetcher(`${config.apiURL}/mdp/crjDiagnostics`, "POST", payload, {
            "Content-Type": "application/json",
          })
            .then((response) => {
              const { dataUpdatedQA, ...solutionsData } = response;
              setDiagnosticsResultsTableState(solutionsData);
              setIsResultsLoading(false);

              if (dataUpdatedQA.length > 0) { // if questionaire requires new rows:
                const combinedResult = {};
                // create add new set of data to current set. Ignores duplicates
                const addToCombined = (arr) => {
                    arr.forEach(item => {
                        const key = item.attributeQuestion;
                        combinedResult[key] = item;
                    });
                };

                addToCombined(tableData.data);
                addToCombined(dataUpdatedQA);

                // convert combinedResult back to an array of objects
                setDiagnosticsTableState(prevState => ({
                  ...prevState,
                  data: Object.values(combinedResult)
                }));
              }
            })
            .catch((e) => {
              setErrorResults(e);
              setIsResultsLoading(false);
            });
        } else {
          setErrorResults(null);
          const emptySolution = {
            data: [],
            dataUpdatedQA: [],
            troubleshootOption: "getSolutions",
            searchKeyword: "[]"
          }
          setDiagnosticsResultsTableState(emptySolution);
        }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // if questionaire is reset and contains data, get a list of solutions for DiagnosticsResults
  const prevDiagTableFilterStateRef = useRef();
  useEffect(() => {
    // check if diagTableFilterState has changed
    const prevDiagTableFilterState = prevDiagTableFilterStateRef.current;

    if (
      diagTableFilterState &&
      prevDiagTableFilterState &&
      Object.keys(diagTableFilterState).length > 0
    ) {
      // compare values of keys
      const hasValueChanged = Object.keys(diagTableFilterState).some(
        key => diagTableFilterState[key] !== prevDiagTableFilterState[key]
      );

      if (hasValueChanged) {
        fetchSolutionsData(diagTableFilterState);
      }
    }

    // update the ref with the current state
    prevDiagTableFilterStateRef.current = diagTableFilterState;
  }, [diagTableFilterState]);

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {error && (
        <div style={styles.spinnerAndText}>
          <DefaultText text={error.toString()} />
        </div>
      )}

      {/* default message to show if the user hasnt made a query */}
      {!error && (!tableData || tableData?.data === null) && !isLoading && (
        <div style={styles.spinnerAndText}>
          <DefaultText text="Select a troubleshoot option." />
        </div>
      )}
      {/* if the query is complete or query in progress show the table */}
      {!error && (tableData?.data !== null || isLoading) && (
        <Fragment>
          <TableContainer sx={styles.container}>
            {/* if query in progress show loading spinner */}
            {isLoading && (
              <div style={styles.spinnerAndText}>
                <CircularProgress color="primary" />
              </div>
            )}
            {/* if the query completed but the table is empty */}
            {!isLoading && Array.isArray(tableData?.data) && tableData.data.length === 0 && (
              <div style={styles.spinnerAndText}>
                <DefaultText text="Data Not Available" />
              </div>
            )}
            {/* if query completed, table is not empty, and keywordsList is not displayed */}
            {!isLoading && Array.isArray(tableData?.data) && tableData.data.length > 0 && !displayKeywordsList && tableData?.troubleshootOption !== "Keywords" && (
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead sx={styles.headerCell} key={"tableHead"}>
                  <TableRow key={'headerRow'}>
                    {columns.map((column) => (
                      <TableCell
                        colSpan={columns.length + 1}
                        sx={styles.headerCell}
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody key={'tableBody'}>
                  {Object.keys(chosenKeywordItem).length !== 0 && (
                    <Fragment key={chosenKeywordItem.attributeQuestion}>
                      <TableRow
                        key={`row_defaultrow`}
                      >
                        <TableCell
                            sx = {{ border: 'none', padding: '8px', width: '50%' }}
                            key={`question_defaultrow`}
                            >
                              {chosenKeywordItem.attributeQuestion}
                          </TableCell>
                          <TableCell
                            sx = {{ border: 'none', padding: '8px', width: '50%' }}
                            key={`options_defaultrow`}>
                              <SelectBoxSingle
                                noOptionsText={'No options provided.'}
                                selectOptions={[chosenKeywordItem]}
                                displayLabel={"valueName"}
                                filterState={chosenItemDefaultState}
                                setFilterState={setChosenItemDefaultState}
                                filterName={chosenKeywordItem.attributeName}
                                initialValue = {chosenKeywordItem}
                              />
                          </TableCell>
                      </TableRow>
                    </Fragment>
                  )}
                  {tableData.data.map((row, rowIndex) => {
                    const question = row.attributeQuestion;
                    let options;
                    if (Array.isArray(row.answers)) {
                      options = row.answers;
                    } else {
                      console.log("row.answers is not an array. implement here")
                    }
                    return (
                      <Fragment key={question}>
                        <TableRow
                          key={`row_${rowIndex}`}
                        >
                          <TableCell
                            sx = {{ border: 'none', padding: '8px', width: '50%' }}
                            key={`question_${rowIndex}`}
                            >
                              {question}
                          </TableCell>
                          <TableCell
                            sx = {{ border: 'none', padding: '8px', width: '50%' }}
                            key={`options_${rowIndex}`}>
                              <SelectBoxSingle
                                noOptionsText={'No options provided.'}
                                selectOptions={options}
                                displayLabel={"valueName"}
                                filterState={diagTableFilterState}
                                setFilterState={setDiagTableFilterState}
                                filterName={question}
                              />
                          </TableCell>
                        </TableRow>

                      </Fragment>
                    );
                  })}
                </TableBody>
              </Table>)}
              {/* if query completed, table is not empty, and keywordsList is displayed */}
              {!isLoading && Array.isArray(tableData?.data) && tableData.data.length > 0 && displayKeywordsList && (
                <KeywordsList kl={tableData?.data}/>
              )}
          </TableContainer>

        </Fragment>)
      }
    </Fragment>
  );
};
