import React, { useState, createContext } from 'react';

export const DiagnosticsFiltersContext = createContext();

export const DiagnosticsFiltersContextProvider = (props) => {
  const defaultDiagnosticsFilterState = {
    name: 'diagnosticsFiltersState',
    troubleshootOption: 'ATA - 4 Digit',
    ata: null,
    eicas: null,
    searchKeyword: '',
  };

  // state of the filters panel in the EqIDLookup tab
  const [diagnosticsFilterState, setDiagnosticsFilterState] = useState(
      defaultDiagnosticsFilterState,
  );

  const defaultDiagTableFilterState = {
    name: 'diagTableFilterState',
  }
  const [diagTableFilterState, setDiagTableFilterState] = useState(defaultDiagTableFilterState);

  const [displayKeywordsList, setDisplayKeywordsList] = useState(false);

  // apply button to trigger a search
  const [mimicApplyClick, setMimicApplyClick] = useState(false);

  // variable facades
  const [curTablePage, setCurTablePage] = useState(0);

  const [appliedDiagnosticsFilters, setAppliedDiagnosticsFilters] = useState(null);

  // troubleshoot toggle button settings
  const [troubleshootByOption, setTroubleshootByOption] = React.useState('ATA - 4 Digit');

  // access and view of MDP Diagnostics
  const [accessTS, setAccessTS] = useState(null);
  const [viewTS, setViewTS] = useState(null);

  return (
    <DiagnosticsFiltersContext.Provider
      value={{
        diagnosticsFilterState,
        setDiagnosticsFilterState,
        defaultDiagnosticsFilterState,
        mimicApplyClick,
        setMimicApplyClick,
        curTablePage,
        setCurTablePage,
        appliedDiagnosticsFilters,
        setAppliedDiagnosticsFilters,
        diagTableFilterState,
        setDiagTableFilterState,
        displayKeywordsList,
        setDisplayKeywordsList,
        troubleshootByOption,
        setTroubleshootByOption,
        accessTS,
        setAccessTS,
        viewTS,
        setViewTS,
      }}
    >
      {props.children}
    </DiagnosticsFiltersContext.Provider>
  );
};
export default DiagnosticsFiltersContextProvider;
