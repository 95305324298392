import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import backgroundImage from 'assets/background.jpg';
import { Button } from '@mui/material';
import { config } from 'config/config';

const getStyles = (theme) => {
  return ({
    DNASuite: {
      background: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'right',
      color: 'rgb(6,57,112)', // Font color
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100vh',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-evenly',
      marginTop: '50px',
    },
    button: {
      borderRadius: theme.spacing(2),
      padding: '15px',
      border: '2px solid black',
      marginLeft: '5px',
      marginRight: '5px',
      minWidth: '125px',
      backgroundColor: 'rgb(210, 210, 210)',
      textTransform: 'none',
      fontSize: '1rem',
      color: 'black',
    },
  });
};

const DnaHomepage = () => {
  const history = useHistory();
  const theme = useTheme();
  const styles = getStyles(theme);

  const redirectToLink = (link) => {
    history.push(link);
  };

  return (
    <div style={styles.DNASuite}>
      <div className="title">
        <h1>Welcome to the MHIRJ DnA Suite</h1>
        <h3>(Diagnostic and Analytical Solutions)</h3>
      </div>
      <div style={styles.buttonContainer}>
        <Button
          sx={styles.button}
          onClick={() => redirectToLink('/mdp/Fleet')}
        >
          {' '}
          MDP{' '}
        </Button>
        <Button
          sx={styles.button}
          onClick={() => redirectToLink('/orca/Timeline')}
        >
          {' '}
          ORCA{' '}
        </Button>
        <Button
          sx={styles.button}
          onClick={() => { window.location.href = config.omdURL }}
        >
          {' '}
          OMD{' '}
        </Button>
        <Button
          sx={styles.button}
          onClick={() => { window.location.href = config.cttURL }}
        >
          {' '}
          CT{' '}
        </Button>
      </div>
    </div>
  );
};

export default DnaHomepage;
