/* eslint-disable new-cap */
import React, { Fragment, useContext } from 'react';
import { config } from 'config/config';
import { TimelineDataContext } from 'orca/contexts/TimelineDataContext';
import { TimelineFiltersContext } from 'orca/contexts/TimelineFiltersContext';
import { TimelineTable } from 'orca/components/TimelineTab/TimelineTable';


const columns = [
  {
    key: 'ata',
    label: 'ATA',
    minWidth: 100,
    sortable: true,
  },
  {
    key: 'ac_sn',
    label: 'Serial',
    minWidth: 100,
    sortable: true,
  },
  {
    key: 'ac_tn',
    label: 'Tail',
    minWidth: 100,
    sortable: true,
  },
  {
    key: 'occurences',
    label: 'Number of Events',
    minWidth: 100,
    sortable: true,
  },
  {
    key: 'timeline',
    label: 'Timeline',
    minWidth: 200,
    sortable: true,
  },
  {
    key: 'intensity',
    label: 'Severity',
    minWidth: 100,
    sortable: true,
  },
];


export const TimelineTablePanel = (props) => {
  const {
    ataList,
  } = props;


  const {
    timelineTableState,
    setTimelineTableState,
    isLoading,
    setIsLoading,
    error,
    setError,
  } = useContext(TimelineDataContext);

  const {
    timelineFilterState,
    setTimelineFilterState,
    defaultTimelineFilterState,
    rowsPerPage,
    setRowsPerPage,
    curTablePage,
    setCurTablePage,
    prevTimelineFilterState,
    setPrevTimelineFilterState,
    setMimicApplyClick,
    timelineSelectedRows: selectedRows,
    setTimelineSelectedRows: setSelectedRows,
    timelineSelectedRowIndex: selectedRowIndex,
    setTimelineSelectedRowIndex: setSelectedRowIndex,
    setChronicSelectedRowIndex,
    setChronicSelectedRows,
    setDataSelected,
    sortDirectionTop: sortDirection,
    setSortDirectionTop: setSortDirection,
    sortColTop: sortCol,
    setSortColTop: setSortCol,
  } = useContext(TimelineFiltersContext);
  // Filtered table data based on number of occurrence applied
  const tableData = timelineTableState?.data ?? []; // original table data

  // clear bottom table if new filter is applied
  if (isLoading) {
    setSelectedRowIndex(null);
    setDataSelected(null);
  }

  let timeoutId;
  let clickCount = 0;

  const handClickEvent = (data, selectedRowIndex) => {
    const getDateString = (maxReportedDate, offset) => {
      const date = new Date(maxReportedDate);
      date.setFullYear(date.getFullYear() + offset);
      const tzOffset = date.getTimezoneOffset();
      const dateWithOffset = new Date(
        date.getTime() - tzOffset * 60 * 1000);
      return dateWithOffset.toISOString().split('T')[0];
    };

    // Cancel any pending timeouts
    clearTimeout(timeoutId);

    // Set a timeout to handle the click event after a delay
    timeoutId = setTimeout(() => {
      if (clickCount === 1) {
        // Single click handler
        setDataSelected(data);
        setSelectedRowIndex(selectedRowIndex);
        setChronicSelectedRowIndex(null);
        setChronicSelectedRows([]);
      } else if (clickCount === 2) {
        // Double click handler
        console.log('Double click');
        setDataSelected(null);
        setSelectedRowIndex(null);
        setSelectedRows([]);
        setSortCol(null);
        const acModel = data.ac_model === 'CRJ'
                                      ? config.CRJ : config.CRJ700;

        if (prevTimelineFilterState == null) {
          setPrevTimelineFilterState(timelineFilterState);

          let labelDesc;
          let concatLabel;
          if (ataList.data && Array.isArray(ataList.data)) {
            // Find the object with the matching ATA value
            const matchingObject = ataList.data.find(item =>
              (item.Value === data.ata) && (item.AC_MODEL === data.ac_model)
            );
            if (matchingObject.DESCRIPTION && matchingObject.DESCRIPTION !== 'UNKNOWN') {
              labelDesc = matchingObject.DESCRIPTION;
              concatLabel = `${matchingObject.Value}: ${matchingObject.DESCRIPTION || ''}`;
            } else {
              concatLabel = matchingObject.Value;
            }
          }

          setTimelineFilterState({
            ...defaultTimelineFilterState,
            startDate: getDateString(data.max_reported_date, -1),
            endDate: getDateString(data.max_reported_date, 0),
            acModel,
            fleets: timelineFilterState.fleets,
            ata: [{
              Value: (data.ata).toString(),
              AC_MODEL: acModel,
              DESCRIPTION: labelDesc,
              chapter: (data.ata).toString().split('-')[0],
              label: concatLabel
            }],
            acsn: [{ Value: data.ac_sn, AC_MODEL: acModel, OPERATOR_CODE: data.operator_code }],
            actn: [{ Value: data.ac_tn, AC_MODEL: acModel, OPERATOR_CODE: data.operator_code }],
          });
        } else {
          setTimelineFilterState(prevTimelineFilterState);
          setPrevTimelineFilterState(null);
        }

        setMimicApplyClick(true);
      }

      // Reset the click count after handling the click event
      clickCount = 0;
    }, 200);

    // Increment the click count
    clickCount++;
  };


  return (
    <Fragment>
      <TimelineTable
        rowKey="id"
        columns={columns}
        tableData={{
          ...timelineTableState,
          data: tableData,
        }}
        setTimelineTableState={setTimelineTableState}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        error={error}
        setError={setError}
        timelineFilterState={timelineFilterState}
        isPrevState={prevTimelineFilterState !== null}
        startDate={timelineFilterState.startDate}
        endDate={timelineFilterState.endDate}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        curTablePage={curTablePage}
        setCurTablePage={setCurTablePage}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        selectedRowIndex={selectedRowIndex}
        setSelectedRowIndex={setSelectedRowIndex}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        sortCol={sortCol}
        setSortCol={setSortCol}
        onClickRow={handClickEvent}
      >
      </TimelineTable>
    </Fragment>
  );
};
