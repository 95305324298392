/* Root component of the Diagnostics tab */
import React, { Fragment, useState, useContext } from 'react';
import Card from '@mui/material/Card';
import { DiagnosticsTablePanel } from 'mdp/components/DiagnosticsTab/DiagnosticsTablePanel';
import { DiagnosticsFiltersPanel } from 'mdp/components/DiagnosticsTab/DiagnosticsFiltersPanel';
import { DiagnosticsResultsPanel } from 'mdp/components/DiagnosticsTab/DiagnosticsResultsPanel';
import { DiagnosticsFiltersContext } from 'mdp/contexts/DiagnosticsFiltersContext';
import { DiagnosticsTabLocked } from 'mdp/components/DiagnosticsTab/DiagnosticsComponents/DiagnosticsTabLocked'

export const DiagnosticsTab = (props) => {
  const [isSolutionPanelExpand, setIsSolutionPanelExpand] = useState(false);
  const [hideDetails, setHideDetails] = useState(false);
  const [hideFilters, setHideFilters] = useState(false);

  const { accessTS } = useContext(DiagnosticsFiltersContext);

  const getMessagePaneClass = () => {
    // return the appropriate css class depending on which panels are hidden
    let classname;
    if (hideDetails && !hideFilters) {
      classname = 'tablePanel expandRight';
    } else if (hideFilters && !hideDetails) {
      classname = 'tablePanel expandLeft';
    } else if (hideFilters && hideDetails) {
      classname = 'tablePanel expandFull';
    } else {
      classname = 'tablePanel';
    }
    return classname;
  };

  return (
    <Fragment>
      {accessTS ? (
        <div className="threePanelTab">
          {/* Filters Panel */}
          <div className={'filtersPanel'.concat(hideFilters ? ' hide' : '')}>
            <Card className="threePanelTabCard">
              <DiagnosticsFiltersPanel/>
            </Card>
          </div>
          {/* Diagnostics Table */}
          {
            <Fragment>
              <div className={getMessagePaneClass()}>
                <Card className="threePanelTabCard">
                  <DiagnosticsTablePanel
                    hideDetails={hideDetails}
                    hideFilters={hideFilters}
                    setHideDetails={setHideDetails}
                    setHideFilters={setHideFilters}
                    // isDetailsPanelExpand={isSolutionPanelExpand}
                  />
                </Card>
              </div>
            </Fragment>
          }
          {/* Diagnostics Results Panel */}
          {
            <div className={'detailsPanel'.concat(hideDetails ? ' hide' : '')}>
            <Card className="threePanelTabCard">
              <DiagnosticsResultsPanel
                isSolutionPanelExpand={isSolutionPanelExpand}
                setIsSolutionPanelExpand={setIsSolutionPanelExpand}
              />
            </Card>
          </div>
          }
        </div>
      ) : (
        <Fragment>
          <div className="threePanelTab">
            {/* Filters Panel */}
            <div className={'filtersPanel'.concat(hideFilters ? ' hide' : '')}>
              <Card className="threePanelTabCard">
                <DiagnosticsFiltersPanel disableFunctionality={!accessTS}/>
              </Card>
            </div>
            {/* Diagnostics Table */}
            {
              <Fragment>
                <div className={getMessagePaneClass()}>
                  <Card className="threePanelTabCard">
                    <DiagnosticsTablePanel
                      hideDetails={hideDetails}
                      hideFilters={hideFilters}
                      setHideDetails={setHideDetails}
                      setHideFilters={setHideFilters}
                      // isDetailsPanelExpand={isSolutionPanelExpand}
                    />
                  </Card>
                </div>
              </Fragment>
            }
            {/* Diagnostics Results Panel */}
            {
              <div className={'detailsPanel'.concat(hideDetails ? ' hide' : '')}>
              <Card className="threePanelTabCard">
                <DiagnosticsResultsPanel
                  isSolutionPanelExpand={isSolutionPanelExpand}
                  setIsSolutionPanelExpand={setIsSolutionPanelExpand}
                />
              </Card>
            </div>
            }
          </div>
        <DiagnosticsTabLocked/>
        </Fragment>
      )}
    </Fragment>
  );
};
