import React, { useContext, useEffect, useState } from 'react';
import { TimelineDataContext } from 'orca/contexts/TimelineDataContext';
import { TimelineFiltersContext } from 'orca/contexts/TimelineFiltersContext';
import { ChronicTable } from 'orca/components/TimelineTab/ChronicTable';
import { NotesPanel } from 'orca/components/TimelineTab/NotesPanel';


const columns = [
  {
    key: 'ata',
    label: 'ATA',
    minWidth: 60,
    sortable: false,
  },
  {
    key: 'ac_sn',
    label: 'Serial',
    minWidth: 60,
    sortable: false,
  },
  {
    key: 'ac_tn',
    label: 'Tail',
    minWidth: 60,
    sortable: false,
  },
  {
    key: 'message_type',
    label: 'Report Type',
    minWidth: 60,
    sortable: true,
  },
  {
    key: 'discrepancy',
    label: 'Discrepancy',
    minWidth: 200,
    sortable: false,
  },
  {
    key: 'corrective_action',
    label: 'Corrective Action',
    minWidth: 200,
    sortable: false,
  },
  {
    key: 'reported_date',
    label: 'Reported Date',
    minWidth: 100,
    sortable: true,
  },
  {
    key: 'resolved_date',
    label: 'Resolved Date',
    minWidth: 100,
    sortable: true,
  },
  {
    key: 'mdc_linkage',
    label: 'MDC Linkage',
    minWidth: 100,
    sortable: false,
  },
];

export const ChronicTablePanel = (props) => {
  const styles = ({
    container: {
      display: "flex",
      height: "100%",
      justifyContent: "space-around",
    },
  });

  const {
    timelineTableState,
  } = useContext(TimelineDataContext);

  const {
    dataSelected,
    chronicSelectedRows,
    setChronicSelectedRows,
    chronicSelectedRowIndex,
    setChronicSelectedRowIndex,
    isNotesOpen,
    setIsNotesOpen,
  } = useContext(TimelineFiltersContext);

  // display 'open' if the resolved date is null
  const checkDate = (date) => {
    if (date.slice(0, 4) === '1900') return false;
    return true;
  };

  const [chronicsUnestedData, setChronicsUnestedData] = useState([]);
  const [selectedAtaAcsn, setSelectedAtaAcsn] = useState([]);
  const [enableNotes, setEnableNotes] = useState(false);
  useEffect(() => {
    const chronicsUD = dataSelected?.chronics.map((chronic) => ({
      operator_code: dataSelected.operator_code,
      ac_model: dataSelected.ac_model,
      ac_sn: dataSelected.ac_sn,
      ac_tn: dataSelected.ac_tn,
      ata: dataSelected.ata,
      reported_date: chronic.reported_date,
      discrepancy: chronic.discrepancy,
      corrective_action: chronic.corrective_action,
      resolved_date: checkDate(chronic.resolved_date)
        ? chronic.resolved_date : 'open',
      message_type: chronic.message_type,
      defect_id: chronic.defect_id,
      split_sub_group: chronic.split_sub_group,
    })) ?? [];
    setChronicsUnestedData(chronicsUD);
    if (chronicsUD.length > 0) {
      setSelectedAtaAcsn({
        ata: chronicsUD[0].ata.replace("-", ""),
        ac_sn: chronicsUD[0].ac_sn.toString(),
      });
      setEnableNotes(true);
    } else {
      setSelectedAtaAcsn(null);
      setEnableNotes(false);
    }
  }, [dataSelected?.chronics]);

  return (
    <div style={styles.container}>
      <div style={{ width: isNotesOpen ? "70%" : "100%" }}>
        <ChronicTable
          rowKey="defect_id"
          columns={columns}
          tableData={{
            ...timelineTableState,
            data: chronicsUnestedData,
          }}
          selectedRows={chronicSelectedRows}
          setSelectedRows={setChronicSelectedRows}
          selectedRowIndex={chronicSelectedRowIndex}
          setSelectedRowIndex={setChronicSelectedRowIndex}
          isNotesOpen={isNotesOpen}
          setIsNotesOpen={setIsNotesOpen}
        >
        </ChronicTable>
      </div>
      {isNotesOpen && (
      <div style={{ width: "30%" }}>
        <NotesPanel
          // selectedChronicDefectID={chronicSelectedRowIndex}
          selectedAtaAcsn={selectedAtaAcsn}
          setIsNotesOpen={setIsNotesOpen}
          enableNotes={enableNotes}
        />
      </div>
      )}
    </div>
  );
};

