import React, { Fragment, useContext } from 'react';
import { TablePanel } from 'mdp/components/Miscellaneous/TablePanel';
import { DiagnosticsDataContext } from 'mdp/contexts/DiagnosticsDataContext';
import { DiagnosticsTable } from 'mdp/components/DiagnosticsTab/DiagnosticsTable'

export const DiagnosticsTablePanel = (props) => {
  const {
    setHideFilters,
    hideDetails,
    setHideDetails,
    hideFilters,
  } = props;

  const {
    diagnosticsTableState,
    isLoading,
    error,
  } = useContext(DiagnosticsDataContext);

  const columns = [
    {
      key: 'questions',
      id: "id_0",
      label: 'Select options to filter possible solutions.',
    }
  ];

  return (
    <TablePanel
      title="CRJ Diagnostics"
      hideDetails={hideDetails}
      hideFilters={hideFilters}
      setHideDetails={setHideDetails}
      setHideFilters={setHideFilters}
      expandRightTabName="Solutions"
    >
      <Fragment>
        <DiagnosticsTable
            columns={columns}
            tableData={ diagnosticsTableState }
            error={error}
            isLoading={isLoading}
        ></DiagnosticsTable>
      </Fragment>
    </TablePanel>
  );
};
