import React, { Fragment, useContext, useState, useLayoutEffect } from 'react';
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import { ReviewFiltersContext } from 'orca/contexts/ReviewFiltersContext';
import { ReviewDataContext } from "orca/contexts/ReviewDataContext";
import { ReviewTable } from 'orca/components/ReviewTab/ReviewTable';

const columns = [
  {
    key: 'ata',
    label: 'ATA',
    minWidth: 50,
    sortable: true,
  },
  {
    key: 'ac_sn',
    label: 'Serial',
    minWidth: 50,
    sortable: true,
  },
  {
    key: 'ac_tn',
    label: 'Tail',
    minWidth: 50,
    sortable: true,
  },
  {
    key: 'message_type',
    label: 'Report Type',
    minWidth: 50,
    sortable: true,
  },
  {
    key: 'discrepancy',
    label: 'Discrepancy',
    minWidth: 150,
    sortable: false,
  },
  {
    key: 'corrective_action',
    label: 'Corrective Action',
    minWidth: 150,
    sortable: false,
  },
  {
    key: 'reported_date',
    label: 'Reported Date',
    minWidth: 80,
    sortable: true,
  },
  {
    key: 'resolved_date',
    label: 'Resolved Date',
    minWidth: 80,
    sortable: true,
  },
  {
    key: 'original_ata',
    label: 'Original ATA',
    minWidth: 60,
    sortable: true,
  },
  {
    key: 'mdc_linkage',
    label: 'MDC Linkage',
    minWidth: 100,
    sortable: false,
  },
];

export const ReassignTablePanel = (props) => {
  const theme = useTheme();
  const styles = ({
    tableTitleBar: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: "1px solid " + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: "30px",
      padding: "0 0 0 0",
    },
    tableTitleBarTitle: {
      color: theme.palette.text.title2,
      paddingTop: "1px",
      textAlign: "center",
      width: "100%",
    },
  })

  const {
    reviewTableState,
    setReviewTableState,
    setIsLoading,
    isLoading,
    error,
    setError,
  } = useContext(ReviewDataContext);

  const {
    btmRowsPerPage,
    setBtmRowsPerPage,
    btmCurTablePage,
    setBtmCurTablePage,
    btmSelectedRowId,
    setBtmSelectedRowId,
    btmSelectedRows,
    setBtmSelectedRows,
    sortDirectionBtm,
    setSortDirectionBtm,
    sortColBtm,
    setSortColBtm,
  } = useContext(ReviewFiltersContext);

  const [displayedTableData, setDisplayedTableData] = useState([]);
  // original format of full displayedTableData
  const [originalDisplayedTableData, setOriginalDisplayedTableData] = useState([]);
  // used for search filters
  const [reassignSearch, setReassignSearch] = useState('');
  const [reassignSearchFilter, setReassignSearchFilter] = useState('');

  useLayoutEffect(() => {
    const fullData = reviewTableState?.data.filter(row => row.orca_status === "N");
    if (reviewTableState?.data) {
      setDisplayedTableData(fullData);
      setOriginalDisplayedTableData(fullData);
    }
  }, [reviewTableState])

  useLayoutEffect(() => {
    if (reassignSearch === "" || reassignSearchFilter === "") {
      setDisplayedTableData(originalDisplayedTableData);
    } else {
      setDisplayedTableData(filterData(originalDisplayedTableData, reassignSearchFilter, reassignSearch));
    }
  }, [reassignSearch, reassignSearchFilter, originalDisplayedTableData])

  const filterData = (data, filterName, searchValue) => {
    return data.filter(item => {
      const fieldValue = item[filterName]?.toString() || '';
      return fieldValue.toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  return (
    <Fragment>
      <CardContent sx={styles.tableTitleBar}>
        <div style={styles.tableTitleBarTitle}>Reassigned</div>
      </CardContent>

      <ReviewTable
        rowKey="defect_id"
        tableName="reassign"
        columns={columns}
        tableData={{
          ...reviewTableState,
          data: displayedTableData,
        }}
        setTableData={setDisplayedTableData}
        setReviewTableState={setReviewTableState}
        error={error}
        setError={setError}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        rowsPerPage={btmRowsPerPage}
        setRowsPerPage={setBtmRowsPerPage}
        curTablePage={btmCurTablePage}
        setCurTablePage={setBtmCurTablePage}
        selectedRowIndex={btmSelectedRowId}
        setSelectedRowIndex={setBtmSelectedRowId}
        selectedRows={btmSelectedRows}
        setSelectedRows={setBtmSelectedRows}
        sortDirection={sortDirectionBtm}
        setSortDirection={setSortDirectionBtm}
        sortCol={sortColBtm}
        setSortCol={setSortColBtm}
        searchTerm={reassignSearch}
        setSearchTerm={setReassignSearch}
        searchFilter={reassignSearchFilter}
        setSearchFilter={setReassignSearchFilter}
      >
      </ReviewTable>
    </Fragment>
  );
};
