import React, { Fragment, useState, useContext, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import { SelectBox } from 'mdp/components/Miscellaneous/SelectBox';
import { FiltersPanel } from 'mdp/components/Miscellaneous/FiltersPanel';
import { CheckBoxSelect } from 'mdp/components/Miscellaneous/CheckBoxSelect';
import { RawDataDataContext } from 'mdp/contexts/RawDataDataContext';
import { RawDataFiltersContext } from 'mdp/contexts/RawDataFiltersContext';
import { useFetch } from 'utils/apiUtils';
import { config } from 'config/config';

export const formatFiltersState = (filterState) => {
  // apply these formatting before the search is sent to backend
  if (filterState.acModel.length) {
    return {
      ...filterState,
      acModel: filterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700',
      acsn: filterState.acsn.map((i) => i.Value),
      actn: filterState.actn.map((i) => i.Value),
      fileTypes: filterState.fileTypes.map((i) => i.Value),
    };
  } else {
    return filterState;
  }
};

export const RawDataFiltersPanel = (props) => {
  const styles = ({
    accordianRoot: {
      boxShadow: 'none',
    },
    accordianLabel: {
      fontSize: 16,
    },
    searchBar: {
      marginTop: '30px',
      width: '100%',
    },
    searchBarText: {
      fontSize: 12,
    },
    dateForm: {
      marginBottom: '15px',
    },
    dateFormInput: {
      fontSize: 12,
    },
    dateTimeContainer: {
      minHeight: '100%',
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    dateTimeBox: {
      display: 'flex',
      flexBasis: '45%',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: '5px',
    },
  });
  // whether filter components should check if their value is empty
  const [shouldValidateFilters, setShouldValidateFilters] = useState(false);
  const {
    mimicApplyClick,
    setMimicApplyClick,
    rawDataFilterState,
    setRawDataFilterState,
    setAppliedRawDataFilters,
    defaultRawDataFilterState,
    setCurTablePage,
    setSelectedRows,
  } = useContext(RawDataFiltersContext);
  const {
    setRawDataTableState,
    isLoading: isRawDataTabLoading,
    setIsLoading: setIsRawDataTabLoading,
    setError,
  } = useContext(RawDataDataContext);
  // fetch filter dropdown options
  const { data: fleetList, error: fleetListError } = useFetch(
      `${config.apiURL}/mdp/unique/oprname`,
      'GET',
  );
  const { data: acsnList, error: acsnListError } = useFetch(
      `${config.apiURL}/mdp/filteroptions/acsn`,
      'GET',
  );
  const { data: actnList, error: actnListError } = useFetch(
      `${config.apiURL}/mdp/filteroptions/actn`,
      'GET',
  );
  const { data: fileTypesList, error: fileTypesListError } = useFetch(
      `${config.apiURL}/mdp/filteroptions/filetype`,
      'GET',
  );
  const [showFleetTab, setShowFleetTab] = useState(false);
  useEffect(() => {
    if (fleetList.oprData && Object.keys(fleetList.oprData).length === 1) {
      setShowFleetTab(false);
    } else {
      setShowFleetTab(true);
    }
  }, [fleetList]);

  const handleEndDateOffsetChange = (event) => {
    const newDate = new Date(rawDataFilterState.endDate);
    newDate.setDate(newDate.getDate() - event.target.value + 1);
    const offset = newDate.getTimezoneOffset();
    const newDateWithOffset = new Date(newDate.getTime() - offset * 60 * 1000);

    setRawDataFilterState({
      ...rawDataFilterState,
      startDate: newDateWithOffset.toISOString().split('T')[0],
      endDateOffset: event.target.value,
    });
  };

  const handleChangeStartDate = (event) => {
    const date1 = new Date(rawDataFilterState.endDate);
    const date2 = new Date(event.target.value);
    const difference = date1.getTime() - date2.getTime();
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

    setRawDataFilterState({
      ...rawDataFilterState,
      startDate: event.target.value,
      endDateOffset: TotalDays,
    });
  };

  const handleChangeEndDate = (event) => {
    const date1 = new Date(event.target.value);
    const date2 = new Date(rawDataFilterState.startDate);
    const difference = date1.getTime() - date2.getTime();
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

    setRawDataFilterState({
      ...rawDataFilterState,
      endDate: event.target.value,
      endDateOffset: TotalDays,
    });
  };

  const isFiltersLoading = () => {
    return Boolean(
        (!fleetList.oprData ||
        !acsnList.data ||
        !actnList.data ||
        !fileTypesList.data) &&
        !fleetListError &&
        !acsnListError &&
        !actnListError &&
        !fileTypesListError,
    );
  };
  const isFiltersFetchError = () => {
    return Boolean(fleetListError || acsnListError || actnListError);
  };
  const isFiltersValid = () => {
    // make sure the date/time and ac model filters arent empty
    // before starting a query
    return Boolean(
        rawDataFilterState.startDate &&
        rawDataFilterState.endDate &&
        rawDataFilterState.acModel.length,
    );
  };
  const filterOptionsByACModel = (options) => {
    /*
    @props <options:list>: list of dropdown options
    [{value:"",AC_MODEL:""},{value:"",AC_MODEL:""},...]

    @return <list>: returns the options list filtered on the
    acmodel that the user currently has selected. Returns empty list
    if no ac model is selected
    */
    if (rawDataFilterState.acModel.length) {
      // our database uses CRJ and CRJ700 to identify CRJ200 and
      // CRJ700/900/1000 respectively, when displying models to the
      // user, make sure to use CRJ200 and CRJ700/900/1000

      return options.data.reduce(
        (i, j) => {
          if (j.AC_MODEL === (
            rawDataFilterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700'
          )) {
            i.push({ ...j, AC_MODEL: rawDataFilterState.acModel });
          }
          return i
        }, []);
    } else {
      return [];
    }
  };

  const filterOptionsByFleet = (options, exclude) => {
    /*
    @props <options:list>: list of dropdown options
    [{ACSN/ACTN:"",AC_MODEL:"", OPERATOR_CODE:""},
    {ACSN/ACTN:"",AC_MODEL:"", OPERATOR_CODE:""},...]
    @props <exclude:boolean>: dictates if were
    excluding/including options by fleet

    @return <list>: returns the options list filtered on the
    fleets that the user currently has selected. Returns original list
    if no fleets is selected
    */
    if (rawDataFilterState.fleets.length && options) {
      const validOprcodes = rawDataFilterState.fleets.map(
          (oprname) => fleetList.oprData[oprname],
      );
      if (exclude) {
        options = options.filter(
            (option) => !validOprcodes.includes(option.OPERATOR_CODE),
        );
      } else {
        options = options.filter((option) =>
          validOprcodes.includes(option.OPERATOR_CODE),
        );
      }
      console.log(options)
    }
    return options;
  };

  return (
    <FiltersPanel
      applyURL={`${
        config.apiURL
      }/mdp/rawdata?offset=0&limit=${config.initialFetchLimit}`}
      applyCallback={() => setSelectedRows([])}
      filterStateName="rawDataFilterState"
      filterState={rawDataFilterState}
      setFilterState={setRawDataFilterState}
      formatFilters={formatFiltersState}
      setAppliedFilters={setAppliedRawDataFilters}
      defaultFilterState={defaultRawDataFilterState}
      isFiltersLoading={isFiltersLoading()}
      isFiltersFetchError={isFiltersFetchError()}
      isFiltersValid={isFiltersValid()}
      isTableDataLoading={isRawDataTabLoading}
      setIsTableDataLoading={setIsRawDataTabLoading}
      setTableDataState={setRawDataTableState}
      setError={setError}
      setCurTablePage={setCurTablePage}
      shouldValidateFilters={shouldValidateFilters}
      setShouldValidateFilters={setShouldValidateFilters}
      setMimicApplyClick={setMimicApplyClick}
      mimicApplyClick={mimicApplyClick}
      popupText="Date/Time and Aircraft Model filters cannot be empty!"
    >
      {!isFiltersLoading() && !isFiltersFetchError() && (
        <Fragment>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Date (UTC)
            </AccordionSummary>
            <AccordionDetails className="otherFilters">
              <div style={styles.dateTimeContainer}>
                <div style={{ paddingBottom: '10px', width: '100%' }}>
                  <CheckBoxSelect
                    filterState={rawDataFilterState}
                    setFilterState={setRawDataFilterState}
                    options={["Query By Message Date", "Query By Loaded Date"]}
                    filterName="dateStatusOption"
                  />
                </div>
                <div style={styles.dateTimeBox}>
                  <TextField
                    variant="standard"
                    fullWidth
                    sx={styles.dateForm}
                    id="startDate"
                    label="Start Date"
                    type="date"
                    value={rawDataFilterState.startDate}
                    onChange={handleChangeStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: styles.dateFormInput,
                    }} />
                </div>
                <div style={styles.dateTimeBox}>
                  <TextField
                    variant="standard"
                    fullWidth
                    sx={styles.dateForm}
                    id="endDate"
                    label="End Date"
                    type="date"
                    value={rawDataFilterState.endDate}
                    onChange={handleChangeEndDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { min: rawDataFilterState.startDate },
                      style: styles.dateFormInput,
                    }} />
                </div>
                {rawDataFilterState.dateStatusOption === 'Query By Message Date' && (
                  <Fragment>
                    <div style={styles.dateTimeBox}>
                    <TextField
                      variant="standard"
                      fullWidth
                      sx={styles.dateForm}
                      id="endDate"
                      label="Start Time"
                      type="time"
                      value={rawDataFilterState.startTime}
                      onChange={(event) =>
                        setRawDataFilterState({
                          ...rawDataFilterState,
                          startTime: event.target.value,
                        })
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        style: styles.dateFormInput,
                      }} />
                    </div>
                    <div style={styles.dateTimeBox}>
                      <TextField
                        variant="standard"
                        fullWidth
                        sx={styles.dateForm}
                        id="endDate"
                        label="End Time"
                        type="time"
                        value={rawDataFilterState.endTime}
                        onChange={(event) =>
                          setRawDataFilterState({
                            ...rawDataFilterState,
                            endTime: event.target.value,
                          })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          style: styles.dateFormInput,
                        }} />
                    </div>
                    <div>
                      <p>Or</p>
                    </div>
                    <TextField
                      variant="standard"
                      fullWidth
                      type="number"
                      label="Days"
                      value={rawDataFilterState.endDateOffset}
                      onChange={handleEndDateOffsetChange}
                      editable={"true"}
                      sx={styles.dateForm}
                      InputProps={{
                        style: styles.dateFormInput,
                      }} />
                  </Fragment>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Model
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxSelect
                filterState={rawDataFilterState}
                setFilterState={setRawDataFilterState}
                options={[config.CRJ, config.CRJ700]}
                filterName="acModel"
                shouldValidate={shouldValidateFilters}
              />
            </AccordionDetails>
          </Accordion>
          {showFleetTab && (
            <Accordion sx={styles.accordianRoot}>
              <AccordionSummary
                sx={styles.accordianLabel}
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                Fleet
              </AccordionSummary>
              <AccordionDetails>
                <SelectBox
                  multiple
                  selectOptions={Object.keys(fleetList.oprData)}
                  filterState={rawDataFilterState}
                  setFilterState={setRawDataFilterState}
                  filterName="fleets"
                  excludeName="excludeFleets"
                />
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Serial Number
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByFleet(
                    filterOptionsByACModel(acsnList),
                    rawDataFilterState.excludeFleets,
                )}
                filterState={rawDataFilterState}
                setFilterState={setRawDataFilterState}
                filterName="acsn"
                excludeName="excludeAcsn"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Tail Number
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByFleet(
                    filterOptionsByACModel(actnList),
                    rawDataFilterState.excludeFleets,
                )}
                filterState={rawDataFilterState}
                setFilterState={setRawDataFilterState}
                filterName="actn"
                excludeName="excludeActn"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              File Type
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByACModel(fileTypesList)}
                filterState={rawDataFilterState}
                setFilterState={setRawDataFilterState}
                filterName="fileTypes"
                excludeName="excludeFileTypes"
              />
            </AccordionDetails>
          </Accordion>
        </Fragment>
      )}
    </FiltersPanel>
  );
};
