/* eslint-disable max-len */
/* Root component representing the expanded popup version of the Details panel */
import React, { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Button, FormHelperText } from "@mui/material";
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";
/* eslint-enable max-len */

export const ReassignPanel = (props) => {
  const {
    isPopupOpen,
    setIsPopupOpen,
    selectedId,
    setShowAlertPopup,
    setMessageInfo,
    reFetchTableData,
    ataList,
  } = props;

  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: "1px solid " + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: "5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    panelTitle: {
      position: "absolute",
    },
    panelButtons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      marginLeft: "auto",
      marginRight: "5px",
    },
    iconButtons: {
      padding: "0 0 0 0",
      margin: "0 5px 0 10px",
    },
    buttonIcons: {
      width: "20px",
      height: "20px",
      fill: theme.palette.primary.dark,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      width: width > 700 ? "20%" : "30%",
      height: height > 905 ? "20%" : "30%",
    },
    panel: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: "10px",
      height: "100%",
      gap: "15px",
    },
    textBox: {
      display: 'flex',
      flexDirection: 'row',
      padding: '10x',
      marginBottom: '5px',
      justifyContent: 'center',
    },
    dash: {
      margin: '15px 5px 0px 5px',
      fontSize: '1.2em',
      fontWeight: '600',
      color: 'grey'
    },
  });

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    setChapter(null);
    setSection(null);
    setErrorChapter(false);
    setErrorSection(false);
  }, [isPopupOpen])

  const [chapter, setChapter] = useState(null);
  const [section, setSection] = useState(null);
  const [errorChapter, setErrorChapter] = useState(false);
  const [errorSection, setErrorSection] = useState(false);

  const handleInputChange = (event) => {
    if (event.target.id === "chapter") {
      setChapter(event.target.value);
    } else {
      setSection(event.target.value);
    }
  };

  const formatATA = (ataList) => {
    const newList = ataList?.data.map(ata => {
      if (ata.Value.length < 5) {
        return ata.Value.replace("-", "") + "0";
      } else {
        return ata.Value.replace("-", "");
      }
    })
    return newList;
  };

  const validateInput = (value) => {
    if (value === null || value.length !== 2 || isNaN(Number(value))) {
      return false;
    }
    return true;
  };

  const onSaveClick = () => {
    const isChapterValid = (validateInput(chapter) && chapter !== "00");
    const isSectionValid = (validateInput(section));

    setErrorChapter(!isChapterValid);
    setErrorSection(!isSectionValid);

    if (isChapterValid && isSectionValid) {
      if (formatATA(ataList).includes(chapter + section)) {
        console.log(chapter + section);
        handleChangeApply();
      } else {
        setOpenConfirmation(true);
      }
    }
  };


  const handleApply = () => {
    setOpenConfirmation(false);
    handleChangeApply();
  };

  const handleChangeApply = () => {
    console.log(selectedId)
    apiFetcher(
      `${config.apiURL}/orca/orca/entryupdates/update`,
      "POST",
      { defectid: selectedId, ata: chapter + section },
      { "Content-Type": "application/json" }
    )
    .then((response) => {
      setIsPopupOpen(false);
      setMessageInfo({
        message: "The selected entry(ies) is updated successfully",
        severity: "info",
      });
      setShowAlertPopup(true);
      reFetchTableData();
    })
    .catch((e) => {
      setMessageInfo({
        message: "Error",
        severity: "error",
      });
      setShowAlertPopup(true);
    });
  }

  const handleCancel = () => {
    setOpenConfirmation(false);
    setChapter(chapter);
    setSection(section);
  };

  return (
    <Modal
      sx={styles.modal}
      closeAfterTransition
      open={isPopupOpen}
      onClose={() => handleClose()}
    >
      <Slide
        in={isPopupOpen}
        direction="down"
        mountOnEnter
        unmountOnExit
      >
        <Card elevation={3} sx={styles.card}>
          <CardContent sx={styles.title}>
            <div style={styles.panelTitle}>ATA Reassign</div>
            <div style={styles.panelButtons}>
              <IconButton
                sx={styles.iconButtons}
                onClick={() => handleClose()}
                size="large">
                <CloseIcon sx={styles.buttonIcons} />
              </IconButton>
            </div>
          </CardContent>

          <div style={styles.panel}>
            <FormHelperText>
              Please enter 4 digits ATA (e.g. 21-51)
            </FormHelperText>

            <div style={styles.textBox}>
              <TextField
                error={errorChapter}
                style={{ marginRight: '10px', width: '20%' }}
                variant="standard"
                id="chapter"
                type="text"
                label="Chapter"
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
                helperText={errorChapter ? "Invalid" : ""}
                onChange={handleInputChange}
                />

              <div style={styles.dash}>-</div>

              <TextField
                error={errorSection}
                style={{ marginLeft: '10px', width: '20%' }}
                variant="standard"
                id="section"
                type="text"
                label="Section"
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
                helperText={errorSection ? "Invalid" : ""}
                onChange={handleInputChange}
                />
            </div>

            <Button
              variant="outlined"
              color="primary"
              onClick={onSaveClick}
            >
              Save
            </Button>
          </div>

          <Dialog maxWidth="xs" open={openConfirmation}>
            <DialogTitle>The ATA entered might be incorrect, would you still want to proceed the changes?</DialogTitle>
            <DialogActions>
              <Button
                color="error"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={handleApply}
              >
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </Slide>
    </Modal>
  )
};
