import React, { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { TimelineFiltersContext } from 'orca/contexts/TimelineFiltersContext';
import { SpltFiltersContext } from 'orca/contexts/SpltFiltersContext';
import { ReviewFiltersContext } from 'orca/contexts/ReviewFiltersContext';


const filterOptions = createFilterOptions({
  ignoreCase: true, // ignore case on search
  limit: 100, // max number of dropdown options to show
});
const styles = ({
  selectBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  header: {
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pillSelectRoot: {
    width: '100%',
    padding: '0 0 0 0',
    margin: '0 0 0 0',
  },
  labelText: {
    fontSize: 12,
  },
});

export const SelectBoxATA = (props) => {
  /*
  @props <title:String>: Title of the selectbox, it will show to the left of
  the exclude button, can be left empty
  @props <filterState>: the state the will be updated when options are selected
  or deselected
  @props <setFilterState>: setter for filterState
  @props <filterName>: name of the property in filterState that will be updated
  when options are selected or deselected e.g. "acModel" or "startDate"
  @props <excludeName>: name of the property in filterState that will be updated
  when the exlude button is toggled, if empty then the exclude button wont be
  rendered
  @props <selectOptions>: list of dropdown options
  @props <groupName>: name of the property in selectOptions to group options by
  e.g. if we want to group options by AC_MODEL
  @props <groupOptionLabel>: name of the property in selectOptions that has the
  label of the option e.g. "acsn" or "eqid"
  @props <multiple>: allow multiple option selection
  @props <noOptionsText>: text to show when no dropdown options available
  @props <placeholderText>: text to show in the select box
  */
  const {
    multiple,
    groupName,
    groupOptionLabel,
    selectAll,
    filterState,
    setFilterState,
    noOptionsText,
    selectOptions,
    filterName,
    excludeName,
    placeholderText,
    title,
    setAllFilters,
  } = props;

  const [inputValue, setInputValue] = useState('');

  // change all tab's filter simultaneously
  const {
    timelineFilterState,
    setTimelineFilterState,
  } = useContext(TimelineFiltersContext);

  const {
    spltFilterState,
    setSpltFilterState,
  } = useContext(SpltFiltersContext);

  const {
    reviewFilterState,
    setReviewFilterState,
  } = useContext(ReviewFiltersContext);


  const handleExcludeChange = (event, newState) => {
    if (setAllFilters) {
      setTimelineFilterState({
        ...timelineFilterState,
        [excludeName]: newState,
      });
      setSpltFilterState({
        ...spltFilterState,
        [excludeName]: newState,
      });
      setReviewFilterState({
        ...reviewFilterState,
        [excludeName]: newState,
      });
    } else {
      setFilterState({
        ...filterState,
        [excludeName]: newState,
      });
    }
  };

  const handleDropdownSelectChange = (event, newValue) => {
    let valueToSet = [...newValue];

    // Check if we added a "Select All" option
    const containsSelectAll = newValue.find((v) => v[selectAll]);
    if (containsSelectAll) {
      // Get all chapters that are not currently selected
      // and match the chapter of the "Select All"
      const allChapters = selectOptions.filter((v) => {
        if (
          filterState[filterName].some(
              (filterAlreadyApplied) => v.Value === filterAlreadyApplied.Value,
          )
        ) {
          return false;
        }
        return v[groupName] ===
          containsSelectAll[groupName] && !v[selectAll];
      });

      // Append new chapters selected to already set chapters
      // and remove the "Select All" option
      const removeSelectAllFilters = newValue.filter(
          (v) => !v[selectAll]);
      valueToSet = [...removeSelectAllFilters, ...allChapters];
    }

    const sortedValueToSet = valueToSet.slice().sort((a, b) => {
      return a.Value.localeCompare(b.Value);
    })

    if (setAllFilters) {
      setTimelineFilterState({
        ...timelineFilterState,
        [filterName]: sortedValueToSet,
      });
      setSpltFilterState({
        ...spltFilterState,
        [filterName]: sortedValueToSet,
      });
      setReviewFilterState({
        ...reviewFilterState,
        [filterName]: sortedValueToSet,
      });
    } else {
      setFilterState({
        ...filterState,
        [filterName]: sortedValueToSet,
      });
    }
  };

  const handleTypedInputChangeGrouped = (event, newInputValue) => {
    const newInputSplit = newInputValue.split(';').map((item) => item.trim());
    // if they pressed the ; update the filterstate with the input
    if (newInputSplit.length > 1) {
      setFilterState({
        ...filterState,
        [filterName]: filterState[filterName].concat(
            selectOptions.filter((i) =>
              newInputSplit.includes(i[groupOptionLabel]),
            ),
        ),
      });
    } else {
      // update the text box as they type
      setInputValue(newInputValue);
    }
  };

  return (
    <div style={styles.selectBox}>
      {excludeName && (
        <div style={styles.header}>
          <Typography sx={styles.labelText}>{title}</Typography>
          <FormControlLabel
            control={
              <Switch
                size="small"
                color="primary"
                checked={filterState[excludeName]}
                onChange={handleExcludeChange}
              />
            }
            label={
              <Typography sx={styles.labelText}>Exclude</Typography>
            }
            labelPlacement="start"
          />
        </div>
      )}
      {groupName ? (
        <Autocomplete
          sx={styles.pillSelectRoot}
          // by default "No Options" when dropdown is empty
          // if noOptionsText is provided show that if props.selectOptions
          // is empty
          noOptionsText={
            noOptionsText
              ? selectOptions.length
                ? 'No Options'
                : noOptionsText
              : 'No Options'
          }
          multiple={multiple}
          classes={{ style: styles.labelText }}
          // filter out options from dropdown that have already been selected
          options={selectOptions.filter(
              (i) =>
                !filterState[filterName]
                    .map((j) => j[groupOptionLabel])
                    .includes(i[groupOptionLabel]),
          )}
          groupBy={(options) => options[groupName]}
          getOptionLabel={(options) => options[groupOptionLabel]}
          size="small"
          limitTags={6}
          filterOptions={filterOptions}
          value={filterState[filterName]}
          inputValue={inputValue}
          onChange={handleDropdownSelectChange}
          onInputChange={handleTypedInputChangeGrouped}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                className=""
                variant="outlined"
                placeholder={placeholderText || ''}
              />
            );
          }}
        />
      ) : (
        <Autocomplete
          filterSelectedOptions
          disableCloseOnSelect
          sx={styles.pillSelectRoot}
          noOptionsText={noOptionsText || "No Options"}
          classes={{ style: styles.labelText }}
          multiple={multiple}
          size="small"
          limitTags={6}
          filterOptions={filterOptions}
          options={selectOptions}
          getOptionLabel={(option) => option.Value}
          value={filterState[filterName]}
          inputValue={inputValue}
          onChange={handleDropdownSelectChange}
          onInputChange={handleTypedInputChangeGrouped}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholderText || ""}
            />
          )}
        />
      )}
    </div>
  );
};
