import React, { Fragment, useState, useContext, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import { CsvExportButton } from 'orca/components/Miscellaneous/CsvExportButton';
import { LoadingIcon } from "orca/components/Miscellaneous/LoadingIcon";
import Checkbox from '@mui/material/Checkbox';
import 'orca/components/AlertsTab/AlertsTab.css';
import { DefaultText } from 'orca/components/Miscellaneous/DefaultText';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { EntryRemoveButton } from "orca/components/Miscellaneous/EntryRemoveButton";
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";
import { AlertsFiltersContext } from 'orca/contexts/AlertsFiltersContext';
import { AlertsDataContext } from 'orca/contexts/AlertsDataContext';

export const AlertsTable = (props) => {
  const {
    columns,
    tableData,
    unnestedData,
    error,
    setError,
    isLoading,
    setIsLoading,
    keywordsSelected,
    setKeywordsSelected,
    acsnSelected,
    setAcsnSelected,
    selectedRows,
    setSelectedRows,
    rowSelected,
    setRowSelected,
  } = props;

  const theme = useTheme();
  const styles = {
    tableHeader: {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      fontWeight: 'bold',
    },
    spinnerAndText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '89%',
    },
    container: {
      height: '94%',
      scrollbarWidth: 'thin',
      backgroundColor: 'white',
    },
    paginationContainer: {
      height: "6%",
      display: "flex",
      gap: '14px',
      alignItems: 'center',
      justifyContent: 'right',
      textAlign: "right",
      padding: "5px 15px 10px 0"
    },
    tableRow: {
      cursor: 'pointer',
      backgroundColor: "white",
      '&:hover': {
        backgroundColor: 'rgba(90, 129, 171, 0.14)',
      },
    },
    disabled: {
      color: 'rgba(205, 205, 205, 0.8)',
      cursor: 'notAllowed',
      pointerEvents: 'none',
    },
    selected: {
      backgroundColor: 'rgba(90, 129, 171, 0.24)',
    },
    tableSubRow: {
      padding: '3px 24px 3px 50px',
    },
    headerCell: {
      padding: '6px 8px 6px 8px',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    tableCell: {
      fontSize: 11,
      padding: '6px 8px 6px 6px',
      textAlign: 'center',
    },
    snackBar: {
      vertical: "bottom",
      horizontal: "center",
    },
  };

  const formatFiltersState = (filterState) => {
    // apply these formatting before the search is sent to backend
    if (filterState.acModel.length) {
      return {
        ...filterState,
        acModel: filterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700',
        acsn: filterState.acsn.map((i) => i.Value),
        actn: filterState.actn.map((i) => {
          // for SkyWest, add 'N' as prefix to tail numm
          if (i.OPERATOR_CODE === "SKW" && /^[0-9]{3}[A-Z]{2}$/.test(i.Value)) {
            return "N" + i.Value;
          }
          return i.Value;
        }),
        messageType: filterState.messageType.map((i) => i.Value),
        keywords: filterState.keywords.map((i) => i.Value),
      };
    } else {
      return filterState;
    }
  };

  const handleKeywordsClick = (keyword, isSelected) => {
    // if keyword is already selected, collapse and uncheck
    if (isSelected) {
      setKeywordsSelected(
          keywordsSelected.filter((item) => item !== keyword),
      );
    } else {
      // if keyword is already collapsed, check and expand it
      setKeywordsSelected([...keywordsSelected, keyword]);
    }
  };

  const handleActnClick = (row, isPairSelected) => {
    // if ac_tn is already selected, collapse and uncheck
    if (isPairSelected) {
      setAcsnSelected(acsnSelected.filter(
          (item) => item.keyword !== row.keyword ||
          item.ac_sn !== row.ac_sn
        ),
      );
    } else {
      // if ac_tn is already collapsed, check and expand it
      setAcsnSelected([...acsnSelected, row]);
    }
  };

  const handleCheckBoxClick = (row, isCheckBoxSelected) => {
    if (isCheckBoxSelected) {
    // If check box is already selected, uncheck it
      setSelectedRows(selectedRows.filter(
          (item) => item.keyword !== row.keyword ||
          item.ac_sn !== row.ac_sn || item.index !== row.index
        ),
      );
    } else {
    // If check box is unchecked, check it
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAllCheckBox = (event) => {
    // if "select all" is unchecked then check all rows
    if (event.target.checked) {
      setSelectedRows(unnestedData);
    } else {
      // if "select all" is checked then uncheck all rows
      setSelectedRows([]);
    }
  };

  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [messageInfo, setMessageInfo] = useState(
    {
      message: "",
      severity: null,
    }
  )
  const [kwAlertState, setKWAlertState] = useState([]);
  const [filteredSearchData, setFilteredSearchData] = useState([]);

  const { alertsFilterState } = useContext(AlertsFiltersContext);
  const { setAlertsTableState } = useContext(AlertsDataContext);

  const handleAlertClosePopup = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertPopup(false);
  };

  const loadTableData = (category, categoryIndex) => {
    const keyword = category.keyword;
    const selected = keywordsSelected.includes(keyword);
    const isValid = category.value[0].value.length !== 0
    return (
      <Fragment key={keyword}>
        <TableRow
          sx={isValid ? (selected ? styles.selected
                          : styles.tableRow)
              : styles.disabled}
          key={`row_${categoryIndex}`}
          onClick={() => handleKeywordsClick(keyword, selected)}
        >
          <TableCell
            sx={!isValid ? styles.disabled : {}}
            key={`keyword_${categoryIndex}`}
            colSpan={columns.length + 1}>
            {selected
            ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
            {keyword}
          </TableCell>
        </TableRow>

        {selected && category.value.map((subCategory, subCategoryIndex) => {
          const acsn = subCategory.ac_sn;
          const actn = subCategory.ac_tn;
          const isPairSelected = acsnSelected.some(
            (item) => item.keyword === subCategory.keyword &&
            item.ac_sn === subCategory.ac_sn
          );
          return (
            <Fragment key={`fragment_${acsn}_${subCategoryIndex}`}>
              <TableRow
                sx={isPairSelected ? styles.selected : styles.tableRow}
                key={`row_${acsn}_${subCategoryIndex}`}
                onClick={() =>
                  handleActnClick(subCategory, isPairSelected)}
              >
                <TableCell
                  colSpan={columns.length + 1}
                  key={`cell_${acsn}_${subCategoryIndex}`}
                  sx={styles.tableSubRow}>
                  {isPairSelected
                    ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
                  {`${acsn} / ${actn}`}
                </TableCell>
              </TableRow>

              {isPairSelected &&
                subCategory.value.map((row, valueIndex) => {
                  const isCheckBoxSelected = selectedRows.some(
                    (item) => item.keyword === row.keyword &&
                    item.ac_sn === row.ac_sn &&
                    item.index === row.index)
                  const isRowSelected = (
                    rowSelected?.keyword === row.keyword &&
                    rowSelected?.ac_sn === row.ac_sn &&
                    rowSelected?.index === row.index)
                  return (
                    (<TableRow
                      sx={isRowSelected
                        ? styles.selected : styles.tableRow}
                      key={`cat_${categoryIndex},_subCat_${subCategoryIndex}_value_${valueIndex}`}
                      onClick={() => setRowSelected(row)}
                    >
                      <TableCell
                        padding="checkbox"
                        key={`cell_${categoryIndex},_subCat_${subCategoryIndex}_value_${valueIndex}`}
                      >
                        <Checkbox
                          color="primary"
                          checked={isCheckBoxSelected}
                          onClick={() => {
                            handleCheckBoxClick(
                              row, isCheckBoxSelected);
                          }}
                        />
                      </TableCell>
                      {columns.map((column, columnsIndex) => {
                        const value = row[column.key];
                        return (
                          <TableCell
                            align={column.align}
                            sx={styles.tableCell}
                            key={`${categoryIndex}_${subCategoryIndex}_${valueIndex}_${columnsIndex}`}
                          >{value}
                          </TableCell>
                        );
                      })}
                    </TableRow>)
                  );
                })
              }
            </Fragment>
          );
        })
        }

      </Fragment>
    );
  }

  const reFetchTableData = () => {
    setIsLoading(true);
    setError(null);

    const payload = formatFiltersState(alertsFilterState);

    const applyCallback = () => {
      setKeywordsSelected([]);
      setAcsnSelected([]);
      setSelectedRows([]);
      setRowSelected(null);
    };

    apiFetcher(
      `${config.apiURL}/orca/alerts`,
      "POST",
      payload,
      { "Content-Type": "application/json" }
    )
      .then((response) => {
        applyCallback();
        setAlertsTableState(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (tableData?.data[0] !== null) { // check if tableData is not empty (do not run at start)
      // Apply events keywords if search doesn't exist
      if (tableData?.searchKeyword === "") {
          setKWAlertState(tableData.data.map(category => category.keyword));
      } else if (tableData?.originalEventState?.length !== 0) { // else if there exist selected events, select those events
          setKWAlertState(tableData.originalEventState);
      }
      setFilteredSearchData(tableData.data?.filter(category => category.keyword === tableData.searchKeyword));
    }
  }, [tableData.data]);

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {error && (
        <div style={styles.spinnerAndText}>
          <DefaultText text={error.toString()} />
        </div>
      )}

      {/* default message to show if the user hasnt made a query */}
      {!error && !tableData && !isLoading && (
        <div style={styles.spinnerAndText}>
          <DefaultText text="Apply some filters" />
        </div>
      )}

      {/* if the query is complete or query in progress show the table */}
      {!error && (tableData || isLoading) && (
        <Fragment>
          <TableContainer sx={styles.container}>
            {/* if query in progress show loading spinner */}
            {isLoading && (
              <div style={styles.spinnerAndText}>
                <LoadingIcon maxWidth={`250px`}></LoadingIcon>
              </div>
            )}
            {/* if the query completed but the table is empty */}
            {!isLoading && (tableData.data[0] === null) && (
              <div style={styles.spinnerAndText}>
                <DefaultText text="Data Not Available" />
              </div>
            )}
            {/* if query completed and table is not empty */}
            {!isLoading && (tableData.data[0] !== null) && (
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead sx={styles.headerCell}>
                  <TableRow key={'headerRow'}>
                    {tableData.data?.length !== 0 &&
                      (<TableCell padding="checkbox" key={'headerCell'}>
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < unnestedData.length
                          }
                          checked={
                            unnestedData.length > 0 &&
                            selectedRows.length === unnestedData.length
                          }
                          onChange={handleSelectAllCheckBox}
                        />
                      </TableCell>)}

                    {columns.map((column) => (
                      <TableCell
                        sx={styles.headerCell}
                        key={column.key}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  { // Display all data related to search bar
                    filteredSearchData?.map((category, categoryIndex) => loadTableData(category, categoryIndex))
                  }
                </TableBody>

                <TableBody>
                  { // Check if originalEventState exists (first load). Add line seperation if there exist event filters and search filter
                  (tableData.originalEventState && tableData?.originalEventState?.length !== 0 && tableData?.searchKeyword !== "") && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} style={{ borderBottom: '2px solid #888', borderTop: '2px solid #888' }} />
                    </TableRow>
                  )}
                  { // Display all data related to events
                    tableData.data
                    ?.filter(category => kwAlertState?.includes(category.keyword))
                    .map((category, categoryIndex) => loadTableData(category, categoryIndex))
                  }
                </TableBody>

              </Table>)}
          </TableContainer>

          <div style={styles.paginationContainer}>
            <EntryRemoveButton
              style={{ marginRight: '16px' }} // Add right margin to create space between buttons
              hasTableData={tableData.data.length !== 0}
              selectedRows={selectedRows}
              setShowAlertPopup={setShowAlertPopup}
              setMessageInfo={setMessageInfo}
              reFetchTableData={reFetchTableData}
            />

            <CsvExportButton
              tableData={selectedRows.map(
                  ({ index, ...rest }) => rest)}
              hasTableData={unnestedData.length !== 0}
              fileName={"AlertingDataExport"}
              setMessageInfo={setMessageInfo}
              setShowAlertPopup={setShowAlertPopup}
            />
          </div>

          <Snackbar
            anchorOrigin={styles.snackBar}
            open={showAlertPopup}
            autoHideDuration={3000}
            onClose={(event, reason) => handleAlertClosePopup(event, reason)}
          >
            <MuiAlert
              onClose={(event, reason) => handleAlertClosePopup(event, reason)}
              severity={messageInfo.severity}
              elevation={6}
              variant="filled"
            >
              {messageInfo.message}
            </MuiAlert>
          </Snackbar>
        </Fragment>)
      }
    </Fragment>
  );
};
