import React, { useState, useEffect } from 'react';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const DiagnosticsResultsExpanded = (props) => {
  const {
    selectedRow,
    setIsSolutionPanelExpand,
    isSolutionPanelExpand,
  } = props;

  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: '1px solid ' + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: '5%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 0 0 0',
    },
    detailsPanelTitle: {
      position: 'absolute',
    },
    detailsPanelButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: '5px',
    },
    iconButtons: {
      padding: '0 0 0 0',
      margin: '0 10px 0 10px',
    },
    buttonIcons: {
      width: '25px',
      height: '25px',
      fill: theme.palette.primary.dark,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      height: height > 905 ? '65%' : '80%',
    },
    body: {
      padding: theme.spacing(2),
      overflow: 'scroll',
      height: '100%',
    },
  });

  const handleClose = () => {
    setIsSolutionPanelExpand(false);
  };

  return <>
    <Modal
      sx={styles.modal}
      closeAfterTransition
      open={isSolutionPanelExpand}
      onClose={() => setIsSolutionPanelExpand(false)}
    >
      <Slide
        in={isSolutionPanelExpand}
        direction="down"
        mountOnEnter
        unmountOnExit
      >
        <Card elevation={3} sx={styles.card}>
          {/* Title bar with close button */}

          <CardContent sx={styles.title}>
            <div style={styles.detailsPanelTitle}>Solution: {selectedRow?.caseName}</div>
            <div style={styles.detailsPanelButtons}>
              <IconButton
                sx={styles.iconButtons}
                onClick={() => handleClose()}
                size="large">
                <CloseIcon sx={styles.buttonIcons} />
              </IconButton>
            </div>
          </CardContent>

          {/* Details Area */}
          <div style={styles.body}>
            {"Nothing added yet because we haven't received solution data from database."}
          </div>
        </Card>
      </Slide>
    </Modal>
  </>;
};
