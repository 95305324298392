import React, { Fragment, useState, useContext } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
  } from '@mui/material';
import 'orca/components/AlertsTab/AlertsTab.css';
import { useTheme } from '@mui/material/styles';
import { config } from 'config/config';
import { apiFetcher } from "utils/apiUtils";
import { DiagnosticsFiltersContext } from 'mdp/contexts/DiagnosticsFiltersContext';
import { DiagnosticsDataContext } from 'mdp/contexts/DiagnosticsDataContext';

export const KeywordsList = (props) => {
    const {
        kl
    } = props;

    const columns = [
        {
          key: 'keywordsList',
          label: 'Double-click to confirm your selection.',
          id: "kl_id_0",
          minWidth: 400,
        },
      ];

    const [selectedRow, setSelectedRow] = useState(null);


    const theme = useTheme();
    const styles = {
        tableHeader: {
            color: theme.palette.primary.main,
            backgroundColor: 'white',
            fontWeight: 'bold',
        },
        spinnerAndText: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '89%',
        },
        container: {
            height: '94%',
            scrollbarWidth: 'thin',
            backgroundColor: 'white',
        },
        paginationContainer: {
            height: "6%",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'right',
            textAlign: "right",
            padding: "5px 15px 10px 0"
        },
        tableRow: {
            cursor: 'pointer',
            backgroundColor: "white",
            '&:hover': {
            backgroundColor: 'rgba(90, 129, 171, 0.14)',
            },
        },
        disabled: {
            color: 'rgba(205, 205, 205, 0.8)',
            cursor: 'notAllowed',
            pointerEvents: 'none',
        },
        selected: {
            backgroundColor: 'rgba(90, 129, 171, 0.24)',
        },
        tableSubRow: {
            padding: '3px 24px 3px 50px',
        },
        headerCell: {
            padding: '6px 8px 6px 8px',
            textAlign: 'center',
            fontWeight: 'bold',
        },
        tableCell: {
            fontSize: 11,
            padding: '6px 8px 6px 6px',
            textAlign: 'center',
        },
    };

    const {
        setDisplayKeywordsList,
      } = useContext(DiagnosticsFiltersContext);
      const {
        setDiagnosticsTableState,
        setIsLoading,
        setError,
      } = useContext(DiagnosticsDataContext);


    const handleRowClick = (row, rowIndex) => {
        setSelectedRow(rowIndex);
    }

    const handleDoubleClick = (row) => {
        setDisplayKeywordsList(false);
        setIsLoading(true);
        setError(null);
        const payload = {
            troubleshootOption: "KeywordChosen",
            searchKeyword: row.attributeID,
            valueID: row.valueID,
        }
        apiFetcher(`${config.apiURL}/mdp/crjDiagnostics`, "POST", payload, {
            "Content-Type": "application/json",
          })
            .then((response) => {
              setDiagnosticsTableState(response);
              setIsLoading(false);
              setSelectedRow(null);
            })
            .catch((e) => {
              setError(e);
              setIsLoading(false);
            });
    }

    return (
        <Fragment>
            {(
            <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead sx={styles.headerCell} key={'tableHead'}>
            <TableRow key={'headerRow'}>
                {columns.map((column) => (
                <TableCell
                    sx={styles.headerCell}
                    key={column.id}
                    align={column.align}
                    style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth
                    }}
                >
                    {column.label}
                </TableCell>
                ))}
            </TableRow>
            </TableHead>


            <TableBody key={'tableBody'}>
            {kl.map((row, rowIndex) => {
                return (
                /* cannot do <Fragment key={row.attributeID}> for now bc some attributeIDs are repeated. */
                <Fragment key={row.attributeFullName}>
                    <TableRow
                    key={`row_${rowIndex}`}
                    onClick={() => handleRowClick(row, rowIndex)}
                    onDoubleClick={() => handleDoubleClick(row)}
                    sx={selectedRow === rowIndex ? styles.selected : styles.tableRow}
                    >
                    <TableCell
                        // sx={!isValid ? styles.disabled : {}}
                        sx = {{ border: 'none', padding: '8px', borderBottom: '1px solid lightgrey' }}
                        key={`question_${rowIndex}`}
                        colSpan={columns.length}>
                        {row.attributeFullName}
                    </TableCell>
                    </TableRow>


                </Fragment>
                );
            })}
            </TableBody>
        </Table>
    )}
    </Fragment>
    )
}
