/* eslint-disable new-cap */
import React, { useState, createContext } from 'react';

export const TimelineFiltersContext = createContext();

export const TimelineFiltersContextProvider = (props) => {
  const getDateString = (offset) => {
    const newDate = new Date();
    newDate.setMonth(newDate.getMonth() + offset);
    const tzOffset = newDate.getTimezoneOffset();
    const newDateWithOffset = new Date(
        newDate.getTime() - tzOffset * 60 * 1000);
    return newDateWithOffset.toISOString().split('T')[0];
  };

  const defaultTimelineFilterState = {
    name: 'timelineFilterState',
    startDate: getDateString(-1), // one month ago
    endDate: getDateString(0),
    acModel: '',
    fleets: [],
    ata: [],
    acsn: [],
    actn: [],
    messageType: [],
    category: [],
    severity: [],
    excludeFleets: false,
    excludeAta: false,
    excludeAcsn: false,
    excludeActn: false,
    excludeMessageTypes: false,
    excludeCategory: false,
    excludeSeverity: false,
    minNumOfOccur: '',
    maxNumOfOccur: '',
    isAllOccurence: true,
  };
  // state of the filters panel in the raw data tab
  const [
    timelineFilterState,
    setTimelineFilterState,
  ] = useState(defaultTimelineFilterState);
  // copy of the filters that were applied in the current search
  const [appliedTimelineFilters, setAppliedTimelineFilters] = useState(null);
  // number of rows to display per page in the data table
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // page number the user is on in the raw data table
  const [curTablePage, setCurTablePage] = useState(0);
  // copy of previous filters for the use of expanding timeline
  const [prevTimelineFilterState, setPrevTimelineFilterState] = useState(null);
  // number of occurences toggled input State
  const [showToggleInput, setShowToggleInput] = useState(false);
  // number of occurrence that is applied in filter
  const [minNumOfOccur, setMinNumOfOccur] = useState(null);
  const [maxNumOfOccur, setMaxNumOfOccur] = useState(null);
  const [mimicApplyClick, setMimicApplyClick] = useState(false);

  // state used to store data selected in top table
  const [timelineSelectedRows, setTimelineSelectedRows] = useState([]);
  // state used to store row index selected in top table
  const [
    timelineSelectedRowIndex, setTimelineSelectedRowIndex] = useState(null);
  // state used to store data selected in bottom table
  const [chronicSelectedRows, setChronicSelectedRows] = useState([]);
  const [
    chronicSelectedRowIndex, setChronicSelectedRowIndex] = useState(null);
  // state used to display data in bottom chronic table for selected row
  const [dataSelected, setDataSelected] = useState(null);

  // state used to store sorting condition in top table
  const [sortDirectionTop, setSortDirectionTop] = useState("asc");
  const [sortColTop, setSortColTop] = useState(null);
  const [isNotesOpen, setIsNotesOpen] = useState(false);


  return (
    <TimelineFiltersContext.Provider
      value={{
        timelineFilterState,
        setTimelineFilterState,
        appliedTimelineFilters,
        setAppliedTimelineFilters,
        defaultTimelineFilterState,
        rowsPerPage,
        setRowsPerPage,
        curTablePage,
        setCurTablePage,
        prevTimelineFilterState,
        setPrevTimelineFilterState,
        mimicApplyClick,
        setMimicApplyClick,
        showToggleInput,
        setShowToggleInput,
        minNumOfOccur,
        setMinNumOfOccur,
        maxNumOfOccur,
        setMaxNumOfOccur,
        timelineSelectedRows,
        setTimelineSelectedRows,
        timelineSelectedRowIndex,
        setTimelineSelectedRowIndex,
        dataSelected,
        setDataSelected,
        chronicSelectedRows,
        setChronicSelectedRows,
        chronicSelectedRowIndex,
        setChronicSelectedRowIndex,
        sortDirectionTop,
        setSortDirectionTop,
        sortColTop,
        setSortColTop,
        isNotesOpen,
        setIsNotesOpen,
      }}
    >
      {props.children}
    </TimelineFiltersContext.Provider>
  );
};
export default TimelineFiltersContextProvider;
