import React from 'react';
import LockPersonTwoToneIcon from '@mui/icons-material/LockPersonTwoTone';

export const DiagnosticsTabLocked = ({ open }) => {
    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      };

      const textBoxStyle = {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
        maxWidth: '400px',
        textAlign: 'center',
        zIndex: 1001,
      };

      return (
        <div style={overlayStyle}>
          <div style={textBoxStyle}>
            <LockPersonTwoToneIcon fontSize="large"/>
            <br></br>
            You do not have access to CRJ Diagnostics.
            Contact this@email.com for more information.
          </div>
        </div>
      );
    };

