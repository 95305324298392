/* eslint-disable max-len */
import React, { cloneElement } from 'react';
import MessageFiltersContextProvider from 'mdp/contexts/MessageFiltersContext';
import MessageDataContextProvider from 'mdp/contexts/MessageDataContext';
import EqIDDataContextProvider from 'mdp/contexts/EqIDDataContext';
import EqIDFiltersContextProvider from 'mdp/contexts/EqIDFiltersContext';
import RawDataFiltersContextProvider from 'mdp/contexts/RawDataFiltersContext';
import RawDataDataContextProvider from 'mdp/contexts/RawDataDataContext';
import FleetTabFiltersContextProvider from 'mdp/contexts/FleetTabFiltersContext';
import FlightDeckEffectsDataContextProvider from 'mdp/contexts/FlightDeckEffectsDataContext';
import FlightDeckEffectsFiltersContextProvider from 'mdp/contexts/FlightDeckEffectsFiltersContext';
import TimelineDataContextProvider from 'orca/contexts/TimelineDataContext';
import TimelineFiltersContextProvider from 'orca/contexts/TimelineFiltersContext';
import SpltDataContextProvider from 'orca/contexts/SpltDataContext';
import SpltFiltersContextProvider from 'orca/contexts/SpltFiltersContext';
import AlertsDataContextProvider from 'orca/contexts/AlertsDataContext';
import AlertsFiltersContextProvider from 'orca/contexts/AlertsFiltersContext';
import ReviewFiltersContextProvider from 'orca/contexts/ReviewFiltersContext';
import ReviewDataContextProvider from 'orca/contexts/ReviewDataContext';
import DiagnosticsDataContextProvider from 'mdp/contexts/DiagnosticsDataContext';
import DiagnosticsFilterContextProvider from 'mdp/contexts/DiagnosticsFiltersContext';
/* eslint-enable max-len */

const GlobalProviderComposer = ({ contexts, children }) => {
  return contexts.reduceRight(
      (kids, parent) =>
        cloneElement(parent, {
          children: kids,
        }),
      children,
  );
};

const GlobalContextProvider = ({ children }) => {
  return (
    <GlobalProviderComposer
      contexts={[
        <MessageFiltersContextProvider key={'msgFltrCtxPrvdr'} />,
        <MessageDataContextProvider key={'msgDataCtxPrvdr'} />,
        <EqIDDataContextProvider key={'eqidDataCtxPrvdr'} />,
        <EqIDFiltersContextProvider key={'eqidFltrCtxPrvdr'} />,
        <RawDataFiltersContextProvider key={'rawDataFltrCtxPrvdr'} />,
        <RawDataDataContextProvider key={'rawDataDataCtxPrvdr'} />,
        <FleetTabFiltersContextProvider key={'fltTabFltrCtxPrvdr'} />,
        <FlightDeckEffectsDataContextProvider key={'fdeDataCtxPrvdr'} />,
        <FlightDeckEffectsFiltersContextProvider key={'fdeFltrCtxPrvdr'} />,
        <TimelineDataContextProvider key={'tmlnDataCtxPrvdr'}/>,
        <TimelineFiltersContextProvider key={'tmlnFltrCtxPrvdr'}/>,
        <SpltDataContextProvider key={'spltDataCtxPrvdr'}/>,
        <SpltFiltersContextProvider key={'spltFltrCtxPrvdr'}/>,
        <AlertsDataContextProvider key={'lrtsDataCtxPrvdr'}/>,
        <AlertsFiltersContextProvider key={'lrtsFltrCtxPrvdr'}/>,
        <ReviewFiltersContextProvider key={'rvwFltrCtxPrvdr'}/>,
        <ReviewDataContextProvider key={'rvwDataCtxPrvdr'}/>,
        <DiagnosticsDataContextProvider key={'DiagDataCtxPrvdr'}/>,
        <DiagnosticsFilterContextProvider key={'DiagFltrCtxPrvdr'}/>
      ]}
    >
      {children}
    </GlobalProviderComposer>
  );
};

export default GlobalContextProvider;
