/* eslint-disable new-cap */
import React, { useState, createContext } from 'react';

export const ReviewFiltersContext = createContext();

export const ReviewFiltersContextProvider = (props) => {
  // number of rows to display per page in the data table
  const [topRowsPerPage, setTopRowsPerPage] = useState(100);
  // page number the user is on in the raw data table
  const [topCurTablePage, setTopCurTablePage] = useState(0);
  // number of rows to display per page in the data table
  const [btmRowsPerPage, setBtmRowsPerPage] = useState(100);
  // page number the user is on in the raw data table
  const [btmCurTablePage, setBtmCurTablePage] = useState(0);

  // state used to store row index selected in top table
  const [topSelectedRowId, setTopSelectedRowId] = useState(null);
  // state used to store data selected in bottom table
  const [topSelectedRows, setTopSelectedRows] = useState([]);
  // state used to store row index selected in top table
  const [btmSelectedRowId, setBtmSelectedRowId] = useState(null);
  // state used to store data selected in bottom table
  const [btmSelectedRows, setBtmSelectedRows] = useState([]);

  // state used to store sorting condition in top table
  const [sortDirectionTop, setSortDirectionTop] = useState("asc");
  const [sortColTop, setSortColTop] = useState(null);
  // state used to store sorting condition in top table
  const [sortDirectionBtm, setSortDirectionBtm] = useState("asc");
  const [sortColBtm, setSortColBtm] = useState(null);

  const getDateString = (offset) => {
    const newDate = new Date();
    newDate.setMonth(newDate.getMonth() + offset);
    const tzOffset = newDate.getTimezoneOffset();
    const newDateWithOffset = new Date(
        newDate.getTime() - tzOffset * 60 * 1000);
    return newDateWithOffset.toISOString().split('T')[0];
  };

  const defaultReviewFilterState = {
    name: 'reviewFilterState',
    reportedStartDate: getDateString(-1), // one month ago
    reportedEndDate: getDateString(0),
    reportedToggle: false,
    resolvedStartDate: getDateString(-1),
    resolvedEndDate: getDateString(0),
    resolvedToggle: false,
    removedClosedStartDate: getDateString(-1),
    removedClosedEndDate: getDateString(0),
    removedClosedToggle: false,
    acModel: '',
    fleets: [],
    ata: [],
    acsn: [],
    actn: [],
    excludeFleets: false,
    excludeAta: false,
    excludeAcsn: false,
    excludeActn: false,
  };

    // state of the filters panel in the raw data tab
    const [reviewFilterState, setReviewFilterState] = useState(defaultReviewFilterState);
    // copy of the filters that were applied in the current search
    const [appliedReviewFilters, setAppliedReviewFilters] = useState(null);
    // number of rows to display per page in the data table
    const [rowsPerPage, setRowsPerPage] = useState(100);
    // variable facade
    const [curTablePage, setCurTablePage] = useState(0);
    const [mimicApplyClick, setMimicApplyClick] = useState(false);

  return (
    <ReviewFiltersContext.Provider
      value={{
        topRowsPerPage,
        setTopRowsPerPage,
        topCurTablePage,
        setTopCurTablePage,
        btmRowsPerPage,
        setBtmRowsPerPage,
        btmCurTablePage,
        setBtmCurTablePage,
        topSelectedRowId,
        setTopSelectedRowId,
        topSelectedRows,
        setTopSelectedRows,
        btmSelectedRowId,
        setBtmSelectedRowId,
        btmSelectedRows,
        setBtmSelectedRows,
        sortDirectionTop,
        setSortDirectionTop,
        sortColTop,
        setSortColTop,
        sortDirectionBtm,
        setSortDirectionBtm,
        sortColBtm,
        setSortColBtm,
        reviewFilterState,
        setReviewFilterState,
        appliedReviewFilters,
        setAppliedReviewFilters,
        rowsPerPage,
        setRowsPerPage,
        curTablePage,
        setCurTablePage,
        mimicApplyClick,
        setMimicApplyClick
      }}
    >
      {props.children}
    </ReviewFiltersContext.Provider>
  );
};
export default ReviewFiltersContextProvider;
