import React, { Fragment } from "react";
import { Tooltip, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

export const NotesButton = (props) => {
  const {
      setIsNotesOpen,
      isNotesOpen
  } = props;
  const theme = useTheme();
  const styles = ({
    tableIconButton: {
      padding: "3 10 3 10",
      margin: "0 0 0 0",
      backgroundColor: isNotesOpen ? "rgba(152, 152, 152, 0.8)" : "rgba(202, 204, 206, 0.38)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgba(202, 204, 206)",
      },
    },
    buttonIcons: {
      width: "20px",
      height: "20px",
      marginRight: "8px",
      fill: theme.palette.primary.dark,
    },
  });

  const handleClick = () => {
    setIsNotesOpen(!isNotesOpen);
  };

  return (
    <Fragment>
      <Tooltip title={isNotesOpen ? "Close Notes" : "Open Notes"}>
        <Button
          sx={styles.tableIconButton}
          variant="contained"
          disableElevation
          size="small"
          onClick={handleClick}
        >
          <DescriptionOutlinedIcon sx={styles.buttonIcons}/>
          Notes
        </Button>
      </Tooltip>
    </Fragment>
  );
};
