/* Root component representing the Filter panel */
import React, { Fragment, useState, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
// import { CheckBoxSelect } from 'mdp/components/Miscellaneous/CheckBoxSelect';
import { SelectBoxSingle } from 'mdp/components/Miscellaneous/SelectBoxSingle'
import { FiltersPanel } from 'mdp/components/Miscellaneous/FiltersPanel';
import { DiagnosticsFiltersContext } from 'mdp/contexts/DiagnosticsFiltersContext';
import { DiagnosticsDataContext } from 'mdp/contexts/DiagnosticsDataContext';
import { useFetch } from 'utils/apiUtils';
import { config } from 'config/config';


export const formatFiltersState = (filterState) => {
  if (filterState.troubleshootOption === "ATA - 4 Digit") {
    return {
      troubleshootOption: "ATA/EICAS",
      searchKeyword: filterState.ata.Value,
    };
  } else if (filterState.troubleshootOption === "EICAS") {
    return {
      troubleshootOption: "ATA/EICAS",
      searchKeyword: filterState.eicas.label,
    };
  } else if (filterState.troubleshootOption === "Keywords") {
    return {
      troubleshootOption: filterState.troubleshootOption,
      searchKeyword: filterState.searchKeyword,
    };
  }
};

export const DiagnosticsFiltersPanel = (props) => {
  const { disableFunctionality } = props;
  const styles = ({
    accordianRoot: {
      boxShadow: 'none',
    },
    accordianLabel: {
      fontSize: 16,
    },
    searchBar: {
      marginTop: '30px',
      width: '100%',
    },
    searchBarText: {
      fontSize: 12,
    },
    toggleButton: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 16,
    },
    staticBox: {
      marginLeft: '20px',
      paddingBottom: '10px',
    }
  });

  // whether filter components should check if their value is empty
  const [shouldValidateFilters, setShouldValidateFilters] = useState(false);

  const {
    setMimicApplyClick,
    mimicApplyClick,
    diagnosticsFilterState,
    setDiagnosticsFilterState,
    defaultDiagnosticsFilterState,
    setCurTablePage,
    setAppliedDiagnosticsFilters,
    setDisplayKeywordsList,
    troubleshootByOption,
    setTroubleshootByOption
  } = useContext(DiagnosticsFiltersContext);

  const {
    setDiagnosticsTableState,
    isLoading,
    setIsLoading,
    setError,
  } = useContext(DiagnosticsDataContext);

  const handleTroubleshootByOption = (event, newOption) => {
    if (newOption !== null) {
      if (event !== "Keywords") {
        setDisplayKeywordsList(false);
      }
      setTroubleshootByOption(newOption);
      setDiagnosticsFilterState({
        ...diagnosticsFilterState,
        troubleshootOption: newOption,
      });
    }
  }
  // fetch filter dropdown options CHANGE THIS TO THE 3 SELECTBOXES REQUIRED, ATA, EICAS, KEYWORDS
  const { data: ataList, error: ataListError } = useFetch(
      `${config.apiURL}/mdp/filteroptions/atacrj200`,
      'GET',
  );

  const { data: eicasList, error: eicasListError } = useFetch(
      `${config.apiURL}/mdp/filteroptions/eicascrj200`,
      'GET',
  );

  const isFiltersLoading = () => {
    return Boolean(
        (!ataList.data ||
        !eicasList.data) &&
        !ataListError &&
        !eicasListError,
    );
  };
  const isFiltersFetchError = () => {
    return Boolean(
        ataListError ||
        eicasListError,
    );
  };
  const isFiltersValid = () => {
    // make sure ac model filters isnt empty before starting a query
    if (diagnosticsFilterState.troubleshootOption !== "Keywords") {
      return Boolean(diagnosticsFilterState.troubleshootOption.length);
    } else {
      return Boolean(diagnosticsFilterState.troubleshootOption.length &&
        diagnosticsFilterState.searchKeyword.length
      );
    }
  };

  const formatTSOption = (tsOption) => {
    return (tsOption === "ATA - 4 Digit") ? "ATA" : tsOption;
  }

  const formatATA = (list) => {
    return list.map(obj => {
      let description = obj.DESCRIPTION;

      const sixDigitFormat = /^\d{2}-\d{2}-\d{2}(-| )/;
      if (sixDigitFormat.test(description)) { // remove component (last 2 digits from 6 digit ATA) from the ATA
        description = description.replace(/^(\d{2}-\d{2})-\d{2}(-| )/, '$1$2 ');
      }

      const chapter = (obj.DESCRIPTION.split("-"))[0].trim();
      return {
        label: description,
        Value: obj.DESCRIPTION,
        chapter,
      };
    });
  };

  const formatEICAS = (list) => {
    return list.map(obj => {
      const shortlabel = (obj.DESCRIPTION.split("EICAS messages for "))[1].trim();
      return {
        label: obj.DESCRIPTION,
        Value: shortlabel,
      };
    });
  }

  return (
    <FiltersPanel
      applyURL={`${config.apiURL}/mdp/crjDiagnostics`}
      filterStateName="diagnosticsFiltersState"
      filterState={diagnosticsFilterState}
      setFilterState={setDiagnosticsFilterState}
      formatFilters={formatFiltersState}
      defaultFilterState={defaultDiagnosticsFilterState}
      isFiltersLoading={isFiltersLoading()}
      isFiltersFetchError={isFiltersFetchError()}
      isFiltersValid={isFiltersValid()}
      isTableDataLoading={isLoading}
      setIsTableDataLoading={setIsLoading}
      setTableDataState={setDiagnosticsTableState}
      setError={setError}
      shouldValidateFilters={shouldValidateFilters}
      setShouldValidateFilters={setShouldValidateFilters}
      setMimicApplyClick={setMimicApplyClick}
      mimicApplyClick={mimicApplyClick}
      setCurTablePage={setCurTablePage}
      setAppliedFilters={setAppliedDiagnosticsFilters}
      popupText="Select box cannot be empty!"
      disableFilterSaver = {true}
    >
      {!isFiltersLoading() && !isFiltersFetchError() && !disableFunctionality && (
        <Fragment>
          <Box sx={styles.staticBox}>Please select an option.</Box>
          <ToggleButtonGroup
            orientation = "vertical"
            value={troubleshootByOption}
            exclusive
            onChange={handleTroubleshootByOption}
            sx = {styles.toggleButton}
          >
            <ToggleButton value="ATA - 4 Digit" aria-label="ATA - 4 Digit">
              ATA - 4 Digit
            </ToggleButton>
            <ToggleButton value="EICAS" aria-label="EICAS">
              EICAS
            </ToggleButton>
            <ToggleButton value="Keywords" aria-label="Keywords">
              Keywords
            </ToggleButton>
          </ToggleButtonGroup>
          {troubleshootByOption !== 'Keywords' ? (
            <Accordion sx={styles.accordianRoot}>
              <AccordionSummary
                sx={styles.accordianLabel}
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                Enter {formatTSOption(troubleshootByOption)}
              </AccordionSummary>
              <AccordionDetails>
                {troubleshootByOption === "ATA - 4 Digit" && (
                  <SelectBoxSingle
                    groupName="chapter"
                    groupOptionLabel="label"
                    noOptionsText={'No options available at this time.'}
                    selectOptions={formatATA(ataList.data)}
                    displayLabel={'label'}
                    filterState={diagnosticsFilterState}
                    setFilterState={setDiagnosticsFilterState}
                    filterName={'ata'}
                  />
                )}
                {troubleshootByOption === "EICAS" && (
                  <SelectBoxSingle
                    noOptionsText={'No options available at this time.'}
                    selectOptions={formatEICAS(eicasList.data)}
                    displayLabel={'Value'}
                    filterState={diagnosticsFilterState}
                    setFilterState={setDiagnosticsFilterState}
                    filterName={'eicas'}
                    limit={100}
                  />
                )}

              </AccordionDetails>
            </Accordion>
          ) : (
            <TextField
            id="keywords_search"
            sx={{
              marginBottom: "10px",
              marginTop: "10px",
              width: '80%',
              marginLeft: "10%",
              marginRight: "10%",
              '& input::placeholder': {
              fontSize: '0.9rem',
            },
          }}
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            placeholder="Search keywords..."
            size="small"
            value={diagnosticsFilterState.searchKeyword}
            onChange={(e) => {
              setDiagnosticsFilterState({
                ...diagnosticsFilterState,
                searchKeyword: e.target.value,
              });
            }}
          />
          )}


        </Fragment>
      )}
      {disableFunctionality && (
        <Fragment>
          <Box sx={styles.staticBox}>Please select an option.</Box>
          <ToggleButtonGroup
            orientation = "vertical"
            value={troubleshootByOption}
            exclusive
            onChange={handleTroubleshootByOption}
            sx = {styles.toggleButton}
          >
            <ToggleButton value="ATA - 4 Digit" aria-label="ATA - 4 Digit">
              ATA - 4 Digit
            </ToggleButton>
            <ToggleButton value="EICAS" aria-label="EICAS">
              EICAS
            </ToggleButton>
            <ToggleButton value="Keywords" aria-label="Keywords">
              Keywords
            </ToggleButton>
          </ToggleButtonGroup>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Enter {formatTSOption(troubleshootByOption)}
            </AccordionSummary>
          </Accordion>
        </Fragment>
      )}
    </FiltersPanel>
  );
};
