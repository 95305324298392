import React, { useState, createContext } from 'react';

export const DiagnosticsDataContext = createContext();

export const DiagnosticsDataProvider = (props) => {
  // state used to store data from CRJ200Troubleshoot Table
  const [diagnosticsTableState, setDiagnosticsTableState] = useState(null);
  // if the CRJ200Troubleshoot table data is loading
  const [isLoading, setIsLoading] = useState(false);
  // if theres an error when querying CRJ200Troubleshoot table data
  const [error, setError] = useState(null);

  // variables below are for DiagnosticsResults
  const [diagnosticsResultsTableState, setDiagnosticsResultsTableState] = useState(null);
  const [isResultsLoading, setIsResultsLoading] = useState(false);
  const [errorResults, setErrorResults] = useState(null);
  const [solutionSelectedRow, setSolutionSelectedRow] = useState(null);

  return (
    <DiagnosticsDataContext.Provider
      value={{
        diagnosticsTableState,
        setDiagnosticsTableState,
        isLoading,
        setIsLoading,
        error,
        setError,
        diagnosticsResultsTableState,
        setDiagnosticsResultsTableState,
        isResultsLoading,
        setIsResultsLoading,
        errorResults,
        setErrorResults,
        solutionSelectedRow,
        setSolutionSelectedRow
      }}
    >
      {props.children}
    </DiagnosticsDataContext.Provider>
  );
};

export default DiagnosticsDataProvider;
