import React, { Fragment, useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import { useTheme } from "@mui/material/styles";
import { FilterSaver } from "mdp/components/Miscellaneous/FilterSaver";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";
import { apiFetcher } from "utils/apiUtils";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

export const FiltersPanel = (props) => {
  /*
  Component representing the filter panels used throughout application

  @props <applyURL:String>: backend API endpoint to request the table data
  @props <filterStateName:String>: unique name of the filter state
  filters to file
  @props filterState: state for storing the selected filters
  @props setFilterState: setter for filterState
  @props setAppliedFilters: setter for setting the currently applied filters
  @props <defaultFilterState:Object>: default filter state
  @props <isFiltersLoading:Bool>: are the filter dropdown options loading
  @props <isFiltersFetchError:Bool>: was there error loading filter options
  @props <isFiltersValid:Bool>: are the filter values valid e.g. valid date
  @props isTableDataLoading: state for storing if table data has loaded
  @props setTableDataState: setter for the table data fetched from backend
  @props setIsTableDataLoading: setter for isTableDataLoading
  @props setError: state for storing error when failed to fetch table data
  @props setCurTablePage: setter for setting the current page the user is on
  @props mimicApplyClick: state for whether we should mimic an apply button
  click to trigger a new search
  @props setMimicApplyClick: setter for mimicApplyClick
  @props shouldValidateFilters: state for whether filter values should be
  validated
  @props setShouldValidateFilters: setter for shouldValidateFilters
  @props <popupText:String>: text to show in the invalid filters popup
  @props <formatFilters:fucntion>: takes in filterState and mutates it. Used to
  make some sort of change to the filterState before it is sent to the backend
  */
  const {
    isFiltersLoading,
    filterStateName,
    setCurTablePage,
    setTableDataState,
    isFiltersValid,
    setAppliedFilters,
    applyURL,
    isTableDataLoading,
    formatFilters,
    setError,
    isFiltersFetchError,
    applyCallback,
    setMimicApplyClick,
    mimicApplyClick,
    defaultFilterState,
    filterState,
    setFilterState,
    popupText,
    setShouldValidateFilters,
    setIsTableDataLoading,
    children,
    specialistEditMode,
    disableFilterSaver,
  } = props;
  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: "1px solid " + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: "5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 0 0 0",
    },
    filtersPanel: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    filtersBody: {
      height: "85%",
      width: "100%",
      overflowY: "auto",
      scrollbarWidth: "thin",
    },
    filtersPanelButtons: {
      height: "7%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      borderTop: "1px solid #e0e0e0",
    },
    filtersPanelTitle: {
      width: "100%",
      textAlign: "center",
    },
    filtersPanelSaveButtons: {
      display: "flex",
      marginLeft: "auto",
      marginRight: "5px",
    },
    center: {
      justifyContent: "center",
      display: "flex",
      marginTop: "50%",
      width: "100%",
    },
  });

  const [isDialogueOpen, setIsDialogueOpen] = useState(false);

  // whether to show popup when certain filter values are empty
  const [showValidationPopup, setShowValidationPopup] = useState(false);

  const handleClosePopup = (event, reason) => {
    // function for closing invalid filter popup
    if (reason === "clickaway") {
      return;
    }
    setShowValidationPopup(false);
  };
  const handleFilterApply = () => {
    /*
    When the user clicks the Apply button send the request to the
    backend with the current filter settings and update the
    table state with the new data.
    */
    if (specialistEditMode) {
      setIsDialogueOpen(true);
    } else {
      setShouldValidateFilters(true);
      setShowValidationPopup(!isFiltersValid);
      if (isFiltersValid) {
        setIsTableDataLoading(true);
        setError(null);
        setCurTablePage(0);
        setAppliedFilters(filterState);
        let payload;
        if (formatFilters) {
          payload = formatFilters(filterState);
        } else {
          payload = filterState;
        }
        apiFetcher(applyURL, "POST", payload, {
          "Content-Type": "application/json",
        })
          .then((response) => {
            if (applyCallback) {
              applyCallback();
            }
            setTableDataState(response);
            setIsTableDataLoading(false);
            setShouldValidateFilters(false);
          })
          .catch((e) => {
            setError(e);
            setIsTableDataLoading(false);
            setShouldValidateFilters(false);
          });
      }
    }
  };
  useEffect(() => {
    // if the user clicks on a tile in the fleet tab then simulate the
    // clicking of the Apply button
    if (mimicApplyClick) {
      handleFilterApply();
      setMimicApplyClick(false);
    }
  }, [mimicApplyClick]);

  return (
    <Fragment>
      <div style={styles.filtersPanel}>
        <CardContent sx={styles.title}>
          <div style={{ width: disableFilterSaver ? "0px" : "70px" }}></div>
          <div style={styles.filtersPanelTitle}>Filters</div>
          {!disableFilterSaver && (
            <div style={styles.filtersPanelSaveButtons}>
                <FilterSaver
                filterState={filterState}
                setFilterState={setFilterState}
                filterStateName={filterStateName}
              />
            </div>
          )}
        </CardContent>
        <div style={styles.filtersBody}>
          {isFiltersLoading && (
            <div style={styles.center}>
              <CircularProgress
                sx={styles.loadingSpinner}
                color="primary"
              />
            </div>
          )}
          {isFiltersFetchError && <DefaultText text="Failed to fetch data" />}
          {children}
        </div>
        <div style={styles.filtersPanelButtons}>
          <Button
            color="primary"
            onClick={() => setFilterState(defaultFilterState)}
          >
            Clear
          </Button>
          <Button
            disabled={isTableDataLoading || isFiltersLoading}
            color="primary"
            onClick={() => handleFilterApply()}
          >
            Apply
          </Button>
        </div>
      </div>
      <Snackbar
        open={showValidationPopup}
        autoHideDuration={5000}
        onClose={(event, reason) => handleClosePopup(event, reason)}
      >
        <MuiAlert
          onClose={(event, reason) => handleClosePopup(event, reason)}
          severity="error"
          elevation={6}
          variant="filled"
        >
          {popupText}
        </MuiAlert>
      </Snackbar>
      <Dialog maxWidth="xs" open={isDialogueOpen}>
        <DialogTitle>
          Discard or Save Your Specialist Input Edits Before Switching Pages
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setIsDialogueOpen(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
