import React, { Fragment, useState, useContext, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Typography } from '@mui/material';
import { config } from "config/config";
import { TimelineFiltersContext } from "orca/contexts/TimelineFiltersContext";
import { TimelineDataContext } from "orca/contexts/TimelineDataContext";
import { FiltersPanel } from "orca/components/Miscellaneous/FiltersPanel";
import { DateTimeSelect } from "orca/components/Miscellaneous/DateTimeSelect";
import { SelectBox } from "orca/components/Miscellaneous/SelectBox";
import { SelectBoxATA } from "orca/components/Miscellaneous/SelectBoxATA";
import { CheckBoxSelect } from "orca/components/Miscellaneous/CheckBoxSelect";

export const formatFiltersState = (filterState) => {
  // apply these formatting before the search is sent to backend
  if (filterState.acModel.length) {
    return {
      ...filterState,
      acModel: filterState.acModel === config.CRJ ? "CRJ" : "CRJ700",
      ata: filterState.ata.map((i) => i.Value),
      acsn: filterState.acsn.map((i) => i.Value),
      actn: filterState.actn.map((i) => {
        // for SkyWest, add 'N' as prefix to tail numm
        if (i.OPERATOR_CODE === "SKW" && /^[0-9]{3}[A-Z]{2}$/.test(i.Value)) {
          return "N" + i.Value;
        }
        return i.Value;
      }),
      messageType: filterState.messageType.map((i) => i.Value),
      category: filterState.category.map((i) => i.Value),
      severity: filterState.severity.map((i) => i.Value),
      fleets: filterState.fleets.map(item => item.Value),
      minNumOfOccur: filterState.minNumOfOccur !== null && filterState.minNumOfOccur !== "" && !isNaN(Number(filterState.minNumOfOccur)) ? Number(filterState.minNumOfOccur) : 0,
      maxNumOfOccur: filterState.maxNumOfOccur !== null && filterState.maxNumOfOccur !== "" && !isNaN(Number(filterState.maxNumOfOccur)) ? Number(filterState.maxNumOfOccur) : 99999,
    };
  } else {
    return filterState;
  }
};

export const TimelineFiltersPanel = (props) => {
  const {
    fleetList,
    isFiltersLoading,
    ataList,
    acsnList,
    actnList,
    messageTypeList,
    isFiltersFetchError
  } = props;

  const styles = ({
    accordianRoot: {
      boxShadow: "none",
    },
    accordianLabel: {
      fontSize: 16,
    },
    intensityDescription: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: 12,
      textAlign: 'left',
    },
    intensityBox: {
      display: 'flex',
      flexBasis: '45%',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      margin: '5px',
      width: '100%'
    },
    intensityRangeBox: {
      display: 'flex',
      flexDirection: 'row',
      margin: '5px',
      marginRight: '20px',
      width: '50%'
    },
    intensityTextBox: {
      width: '30%',
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginBottom: 0,
      marginTop: 20
    }
  });

  const {
    timelineFilterState,
    setTimelineFilterState,
    setAppliedTimelineFilters,
    defaultTimelineFilterState,
    setCurTablePage,
    mimicApplyClick,
    setMimicApplyClick,
    showToggleInput,
    setShowToggleInput,
    setMinNumOfOccur,
    setMaxNumOfOccur,
    setTimelineSelectedRows,
    setTimelineSelectedRowIndex,
    setDataSelected,
    setChronicSelectedRows,
    setChronicSelectedRowIndex,
    setSortDirectionTop,
    setSortColTop,
  } = useContext(TimelineFiltersContext);

  const {
    setTimelineTableState,
    isLoading,
    setIsLoading,
    setError
  } = useContext(TimelineDataContext);

  const categoryList = [{ Value: 'WatchList' }, { Value: 'Non WatchList' }];
  const severityList = [{ Value: 'Red' }, { Value: 'Yellow' }, { Value: 'Green' }];

  const [shouldValidateFilters, setShouldValidateFilters] = useState(false);

  const isFiltersValid = () => {
    let minMaxOccurence = true;
    if (!timelineFilterState.isAllOccurence) { // check if range is valid.
      if (timelineFilterState.minNumOfOccur === "" || timelineFilterState.maxNumOfOccur === "") { // exception when thresholds aren't specified.
        minMaxOccurence = true;
      } else {
        minMaxOccurence = timelineFilterState.minNumOfOccur <= timelineFilterState.maxNumOfOccur;
      }
    }
    // make sure the date/time and ac model filters arent empty
    // before starting a query
    return Boolean(timelineFilterState.startDate &&
      timelineFilterState.endDate &&
      timelineFilterState.acModel.length &&
      minMaxOccurence);
  };

  // hide fleet filter for external users
  const [showFleetTab, setShowFleetTab] = useState(false);
  useEffect(() => {
    if (fleetList.oprData && Object.keys(fleetList.oprData).length === 1) {
      setShowFleetTab(false); // change to false later
      setTimelineFilterState({
        ...timelineFilterState,
        fleets: [{ Value: Object.keys(fleetList.oprData)[0] }]
      })
    } else {
      setShowFleetTab(true);
    }
  }, [fleetList.oprData]);

  // handle num of occurrence filter
  const handleToggleChange = (e) => {
    setShowToggleInput(!showToggleInput);
    // set number of occurrence to 0 when enable/disable the toggle
    setTimelineFilterState({
      ...timelineFilterState,
      isAllOccurence: e.target.checked
    });
  };

  const handleMinNumOfOccur = (e) => {
    // do not allow letters or symbols
    if (/^\d*$/.test(e.target.value)) {
      setMinNumOfOccur(e.target.value);
      setTimelineFilterState({
        ...timelineFilterState,
        minNumOfOccur: e.target.value
      });
    }
  }

  const handleMaxNumOfOccur = (e) => {
    // do not allow letters or symbols
    if (/^\d*$/.test(e.target.value)) {
      console.log("affqf");
      console.log(e.target.value);
      setMaxNumOfOccur(e.target.value);
      setTimelineFilterState({
        ...timelineFilterState,
        maxNumOfOccur: e.target.value
      });
    }
  }

  const filterOptionsByACModel = (options) => {
    /*
    @props <options:list>: list of dropdown options
    [{value:"",AC_MODEL:""},{value:"",AC_MODEL:""},...]

    @return <list>: returns the options list filtered on the
    acmodel that the user currently has selected. Returns empty list
    if no ac model is selected
    */
    if (timelineFilterState.acModel.length && options.data) {
      // our database uses CRJ and CRJ700 to identify CRJ200 and
      // CRJ700/900/1000 respectively, when displying models to the
      // user, make sure to use CRJ200 and CRJ700/900/1000
      return options.data.reduce(
        (i, j) => {
          if (j.AC_MODEL ===
            (timelineFilterState.acModel === config.CRJ ? "CRJ" : "CRJ700")) {
              i.push({ ...j, AC_MODEL: timelineFilterState.acModel })
            }
            return i
          }, []);
    } else {
      return [];
    }
  };

  const filterOptionsByFleet = (options, exclude) => {
    /*
    @props <options:list>: list of dropdown options
    [{ACSN/ACTN:"",AC_MODEL:"", OPERATOR_CODE:""},
    {ACSN/ACTN:"",AC_MODEL:"", OPERATOR_CODE:""},...]
    @props <exclude:boolean>: dictates if were excluding options by fleet

    @return <list>: returns the options list filtered on the
    fleets that the user currently has selected. Returns original list
    if no fleets is selected
    */
    if (timelineFilterState.fleets.length && options) {
      const transformedFleetArray = timelineFilterState.fleets.map(item => item.Value);
      const validOprcodes = transformedFleetArray.map((oprname) => fleetList.oprData[oprname]);
      if (exclude) {
        options = options.filter((option) => !validOprcodes.includes(option.OPERATOR_CODE));
      } else {
        options = options.filter((option) => validOprcodes.includes(option.OPERATOR_CODE));
      }
    }
    return options;
  };

  const formatATA = (list) => {
    const comp = (a, b) => {
      if (a.Value < b.Value) {
        return -1;
      }
      if (a.Value > b.Value) {
        return 1;
      }
      return 0;
    };
    const compChapter = (a, b) => {
      if (a.chapter < b.chapter) {
        return -1;
      }
      if (a.chapter > b.chapter) {
        return 1;
      }
      return 0;
    };
    const ATAParentList = [];
    const newList = list.sort(comp).map((item) => {
      const chapter = item.Value.split('-')[0];
      if (!ATAParentList.includes(chapter)) {
        ATAParentList.push(chapter);
      }
      const newItem = item;
      if (item.DESCRIPTION && item.DESCRIPTION !== 'UNKNOWN') {
        newItem.label = `${item.Value}: ${item.DESCRIPTION || ''}`;
      } else {
        newItem.label = item.Value;
      }
      return { ...item, chapter };
    });

    const newATAParentList = ATAParentList.map((item) => {
      return {
        chapter: item,
        AC_MODEL: newList[0].AC_MODEL,
        wholeChapter: true,
        label: `Select all ${item}`,
      };
    });
    return [...newATAParentList, ...newList].sort(compChapter);
  };

  const transformFleetObject = (obj) => {
    return Object.keys(obj).map(key => ({
      Value: key
    }));
  };

  return (
    <FiltersPanel
      applyURL={`${config.apiURL}/orca/orca`}
      applyCallback={() => {
        setTimelineSelectedRows([]);
        setTimelineSelectedRowIndex(null);
        setDataSelected(null);
        setChronicSelectedRows([]);
        setChronicSelectedRowIndex(null);
        setSortDirectionTop("asc");
        setSortColTop(null);
      }}
      filterStateName="timelineFilterState"
      filterState={timelineFilterState}
      setFilterState={setTimelineFilterState}
      formatFilters={formatFiltersState}
      setAppliedFilters={setAppliedTimelineFilters}
      defaultFilterState={defaultTimelineFilterState}
      isFiltersLoading={isFiltersLoading}
      isFiltersFetchError={isFiltersFetchError}
      isFiltersValid={isFiltersValid()}
      isTableDataLoading={isLoading}
      setIsTableDataLoading={setIsLoading}
      setTableDataState={setTimelineTableState}
      setError={setError}
      setCurTablePage={setCurTablePage}
      shouldValidateFilters={shouldValidateFilters}
      setShouldValidateFilters={setShouldValidateFilters}
      setMimicApplyClick={setMimicApplyClick}
      mimicApplyClick={mimicApplyClick}
      popupText="Date/Time and Aircraft Model filters cannot be empty! If specifying a range for the number of events, ensure the range is valid."
    >
      {!isFiltersLoading && !isFiltersFetchError && (
        <Fragment>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Date (UTC)
            </AccordionSummary>
            <AccordionDetails>
              <DateTimeSelect
                includeTime={false}
                filterState={timelineFilterState}
                shouldValidate={false}
              ></DateTimeSelect>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Model
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxSelect
                filterState={timelineFilterState}
                options={[config.CRJ, config.CRJ700]}
                filterName="acModel"
                shouldValidate={shouldValidateFilters}
              />
            </AccordionDetails>
          </Accordion>

          {showFleetTab && (
            <Accordion sx={styles.accordianRoot}>
              <AccordionSummary
                sx={styles.accordianLabel}
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                Fleet
              </AccordionSummary>
              <AccordionDetails>
                <SelectBox
                  multiple
                  selectOptions={transformFleetObject(fleetList.oprData)}
                  filterState={timelineFilterState}
                  setFilterState={setTimelineFilterState}
                  filterName="fleets"
                  excludeName="excludeFleets"
                  setAllFilters={true}
                />
              </AccordionDetails>
            </Accordion>
          )}

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              ATA
            </AccordionSummary>
            <AccordionDetails>
              {ataList.data && (
                <SelectBoxATA
                  multiple
                  groupName="chapter"
                  groupOptionLabel="label"
                  selectAll="wholeChapter"
                  noOptionsText={timelineFilterState.acModel.length
                    ? "No options" : "Select an AC Model first"}
                  selectOptions={formatATA(filterOptionsByACModel(ataList))}
                  filterState={timelineFilterState}
                  setFilterState={setTimelineFilterState}
                  filterName="ata"
                  excludeName="excludeAta"
                  setAllFilters={true}
                />
                )}
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Serial Number
            </AccordionSummary>
            <AccordionDetails>
              {acsnList.data && (
                <SelectBox
                  multiple
                  groupName="AC_MODEL"
                  groupOptionLabel="Value"
                  noOptionsText={timelineFilterState.acModel.length
                    ? "No options" : "Select an AC Model first"}
                  selectOptions={filterOptionsByFleet(
                    filterOptionsByACModel(acsnList),
                    timelineFilterState.excludeFleets,
                  )}
                  filterState={timelineFilterState}
                  setFilterState={setTimelineFilterState}
                  filterName="acsn"
                  excludeName="excludeAcsn"
                  setAllFilters={true}
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Tail Number
            </AccordionSummary>
            <AccordionDetails>
              {actnList.data && (
                <SelectBox
                  multiple
                  groupName="AC_MODEL"
                  groupOptionLabel="Value"
                  noOptionsText={timelineFilterState.acModel.length
                    ? "No options" : "Select an AC Model first"}
                  selectOptions={filterOptionsByFleet(
                    filterOptionsByACModel(actnList),
                    timelineFilterState.excludeFleets,
                  )}
                  filterState={timelineFilterState}
                  setFilterState={setTimelineFilterState}
                  filterName="actn"
                  excludeName="excludeActn"
                  setAllFilters={true}
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Report Type
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={timelineFilterState.acModel.length
                  ? "No options" : "Select an AC Model first"}
                selectOptions={filterOptionsByACModel(messageTypeList)}
                filterState={timelineFilterState}
                setFilterState={setTimelineFilterState}
                filterName="messageType"
                excludeName="excludeMessageTypes"
                setAllFilters={true}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Number of Occurences
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      size="small"
                      color="primary"
                      onChange={handleToggleChange}
                    />
                  }
                  label="All"
                />
              </FormGroup>
              {showToggleInput && (
                <Box display="flex" alignItems="center" justifyContent="center">
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    Min:
                  </Typography>
                  <TextField
                    variant="standard"
                    type="text"
                    placeholder="###"
                    size="small"
                    value={timelineFilterState.minNumOfOccur}
                    onChange={handleMinNumOfOccur}
                    sx={{ width: 50 }}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    Max:
                  </Typography>
                  <TextField
                    variant="standard"
                    type="text"
                    placeholder="###"
                    size="small"
                    value={timelineFilterState.maxNumOfOccur}
                    onChange={handleMaxNumOfOccur}
                    sx={{ width: 50 }}
                  />
                </Box>
              </Box>
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Category
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                selectOptions={categoryList}
                filterState={timelineFilterState}
                setFilterState={setTimelineFilterState}
                filterName="category"
                excludeName="excludeCategory"
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Severity
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                selectOptions={severityList}
                filterState={timelineFilterState}
                setFilterState={setTimelineFilterState}
                filterName="severity"
                excludeName="excludeSeverity"
              />
            </AccordionDetails>
          </Accordion>
        </Fragment>
      )}
    </FiltersPanel>
  );
};
